import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, VERSION, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, RowClassArgs, SelectAllCheckboxState, SelectableMode, SelectableSettings } from '@progress/kendo-angular-grid';
import { CheckBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { QueryClient } from '@tanstack/angular-query-experimental';
import { Subscription, debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';
import { ActivitiesUpdaterComponent } from '../../../shared/components/activities-updater/activities-updater.component';
import { ActivitiesInfo } from '../../../shared/models/common.models';
import { ActivityInsertResult, ActivityUpdateResult, SaveResult } from '../../../shared/models/result.model';
import { AuthorisationService } from '../../../shared/services/authorisation.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { ActivitiesComponent } from '../../calendars/activities/activities.component';
import { Activity, ActivityInfo, ActivityStatus, Client } from '../../calendars/calendars.models';
import { CalendarsService } from '../../calendars/calendars.service';
import { ProgressActivitiesInfo } from '../../tools/tools.models';
import { CampaignAssignerComponent } from '../campaign-assigner/campaign-assigner.component';
import { ActivityAssignInfo, Campaign } from '../campaigns.models';
import { CampaignEditorService } from './campaign-editor.service';

@Component({
  selector: 'campaign-editor',
  templateUrl: './campaign-editor.component.html',
  styleUrls: ['./campaign-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CampaignEditorComponent),
      multi: true
    },
    CampaignEditorService
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FloatingLabelModule, TextBoxModule, ComboBoxModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, GridModule, ButtonModule, CheckBoxModule, MatIconModule, DatePipe]
})
export class CampaignEditorComponent implements OnInit, ControlValueAccessor
{
  public selectAllState: SelectAllCheckboxState = "unchecked";
  public mode: SelectableMode = "multiple";
  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: this.mode,
    drag: false,
  };


  public _form: FormGroup = new FormGroup({
    'Id': new FormControl(0),
    'Name': new FormControl(null, Validators.maxLength(100)),
    'Client': new FormControl(null, Validators.required),
    'Product': new FormControl(null, Validators.maxLength(100)),
    'BookingId': new FormControl(null, Validators.maxLength(50)),
    'ActivityName': new FormControl(null, Validators.maxLength(100)),
    'ClientExecUserId': new FormControl(null),
    'ClientSupportUserId': new FormControl(null),
    'BriefManagerUserId': new FormControl(null),
    'NatProjectManagerUserId': new FormControl(null),
    'ProjectManagerId': new FormControl(null),
    'UpdateActivities': new FormControl(false),
    'Disabled': new FormControl(false),
    'SortOrder': new FormControl(0, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])),
    'Activities': new FormControl(null)
  });

  public isNew = false;

  private _isDisabled = false;

  public dialog: MatDialog;
  public dialogConfig = new MatDialogConfig();
  private dialogIsOpen = false;
  private editCloseSubscription: Subscription;

  public CanAssign: boolean = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanAssignActivitiesToCampaign);
  public CanUnassign: boolean = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanUnassignActivitiesFromCampaign);

  public CanAdd: boolean = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddBroadcastActivity) || this.authorisationService.hasPermission(this.authorisationService.Permissions.AddNonBroadcastActivity);
  public CanEdit: boolean = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityImplementation)
    || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivitySales)
    || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityStationSpecific);


  public CanProgressCampaignActivities: boolean = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanProgressCampaignActivities);


  public clients: Client[] = [];

  public virtual: any = {
    itemHeight: 28,
  };

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  version = VERSION;

  @Input() get form(): any
  {
    return this._form;
  }
  set form(value: any)
  {
    this._form = value
  }


  @Input() get value(): Campaign
  {
    return this.campaignEditorService.campaign;
  }
  set value(val: Campaign)
  {
    this.campaignEditorService.campaign = val;

    if (this.propagateChange)
    {
      this.propagateChange(val);
    }
  }


  @Input() get isDisabled(): boolean
  {
    return this._isDisabled;
  }
  set isDisabled(value: boolean)
  {
    this._isDisabled = value;
  }

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private propagateChange = (value: Campaign) => { };

  private queryClient = inject(QueryClient);

  constructor(
    public campaignEditorService: CampaignEditorService,
    public commonService: CommonService,
    public configurationService: ConfigurationService,
    public commonDataService: CommonDataService,
    private calendarsService: CalendarsService,
    private authorisationService: AuthorisationService,
    protected inj: Injector,
    private cdr: ChangeDetectorRef)
  {
    this.dialog = inj.get(MatDialog);

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "90%";
    this.dialogConfig.height = "97vh";
  }

  ngOnInit(): void
  {
    if (!this.value)
    {
      this.campaignEditorService.campaign = null;
    }

    this.clients = this.commonDataService.clients();

    this.value = this.form.value;

    this.isNew = this.form?.get('Id')?.value < 1;

    if (this.isNew)
    {
      this.form.get('UpdateActivities').disable({ emitEvent: false });
    }
    else
    {
      this.form.get('UpdateActivities').enable({ emitEvent: false });
    }

    //Show required fields in red
    this.form.markAllAsTouched();
  }


  ngOnDestroy()
  {
    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }
  }

  public handleClientsFilter(query: string): void
  {
    this.clients = this.commonDataService.clients();

    const predicate = (item: any) => item.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    this.clients = this.clients.filter(predicate);
  }

  clientChange(value: any): void
  {
    this.form.markAsDirty();
  }

  ngOnChanges(inputs: any): void
  {
    if (this.value)
    {
      if (this.propagateChange)
      {
        this.propagateChange(this.value);
      }

      this.cdr.detectChanges();
    }

  }

  public rowCallback = (context: RowClassArgs) =>
  {
    if (this.isRowSelected(context.dataItem.Id))
    {
      return { selected: true };
    }
    else
    {
      return { unselected: true };
    }
  };

  isRowSelected(id: number)
  {
    return this.campaignEditorService.rowsSelected && this.campaignEditorService.rowsSelected.length > 0 && this.campaignEditorService.rowsSelected.some((row: ActivityInfo) => row.Id == id);
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState): void
  {
    if (checkedState === "checked")
    {
      this.campaignEditorService.rowsSelected = [];
      this.campaignEditorService.rowsSelected.push(...this.form?.get('Activities')?.value);
      this.selectAllState = "checked";
    }
    else
    {
      this.campaignEditorService.rowsSelected = [];
      this.selectAllState = "unchecked";
    }

    this.setSelectAllState();
  }

  public selectionChange(e: any)
  {
    if (e.target)
    {
      if (e.target.checked)
      {
        const row = this.form?.get('Activities')?.value.filter((row: ActivityInfo) => row.Id == e.target.id)[0];

        if (row && !this.campaignEditorService.rowsSelected.some((dataItem: ActivityInfo) => row.Id == dataItem.Id))
        {
          this.campaignEditorService.rowsSelected.push(row);
        }
      }
      else
      {
        this.campaignEditorService.rowsSelected = this.campaignEditorService.rowsSelected.filter((dataItem: ActivityInfo) => dataItem.Id != e.target.id);
      }

      this.setSelectAllState();
    }
  }

  setSelectAllState()
  {
    const len = this.campaignEditorService.rowsSelected.length;

    if (len === 0)
    {
      this.selectAllState = "unchecked";
    }
    else if (len > 0 && len < this.form?.get('Activities')?.value.length)
    {
      this.selectAllState = "indeterminate";
    }
    else
    {
      this.selectAllState = "checked";
    }
  }



  inputValueChanged(event: any)
  {
    this.valueChange.emit(this.value);

    if (this.propagateChange)
    {
      this.propagateChange(this.value);
    }

    if (this.onTouchedCallback)
    {
      this.onTouchedCallback();
    }
  }

  public addHandler({ sender, dataItem, formGroup }: any)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.CanAdd)
    {
      if (!this.dialogIsOpen)
      {
        this.dialogIsOpen = true;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "95%";
        dialogConfig.maxWidth = "1280px";
        dialogConfig.hasBackdrop = true;

        let dialogRef: any = null;

        const ai: ActivitiesInfo = new ActivitiesInfo();
        ai.campaign = this.form.getRawValue();
        ai.IsForCampaign = true;

        dialogConfig.data = ai;

        dialogRef = this.dialog.open(ActivitiesComponent, dialogConfig);

        this.editCloseSubscription = dialogRef.afterClosed().subscribe((result: ActivityInsertResult) =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            const activity: Activity = JSON.parse(result.ActivityJson);

            if (activity)
            {
              const element: ActivityInfo = new ActivityInfo();

              if (activity.ActivityStationInfos && activity.ActivityStationInfos.length > 0)
              {
                element.ActivityName = activity.ActivityStationInfos[0].ActivityName;
              }

              if (activity.ActivityStationInfos && activity.ActivityStationInfos.length > 0)
              {
                element.ProjectManagerId = activity.ActivityStationInfos[0].ProjectManagerId;
              }

              if (!activity.Recurrence || activity.Recurrence.RecurrenceType == 0)
              {
                if (activity.EndDate)
                {
                  element.EndDate = this.commonService.parseJsonDate(activity.EndDate, true);
                }

                if (activity.StartDate)
                {
                  element.StartDate = this.commonService.parseJsonDate(activity.StartDate, true);
                }
              }
              else
              {
                if (activity.Recurrence.PatternEndDate)
                {
                  element.EndDate = this.commonService.parseJsonDate(activity.Recurrence.PatternEndDate, true);
                }
                else if (activity.EndDate)
                {
                  element.EndDate = this.commonService.parseJsonDate(activity.EndDate, true);
                }

                if (activity.Recurrence.PatternStartDate)
                {
                  element.StartDate = this.commonService.parseJsonDate(activity.Recurrence.PatternStartDate, true);
                }
                else if (activity.StartDate)
                {
                  element.StartDate = this.commonService.parseJsonDate(activity.StartDate, true);
                }
              }

              if (activity.ClientName)
              {
                element.ClientName = activity.ClientName;
              }

              if (activity.Id)
              {
                element.Id = activity.Id;
              }

              if (activity.Product)
              {
                element.Product = activity.Product;
              }

              if (activity.Property)
              {
                element.PropertyName = activity.Property.PropertyName;
              }

              element.Status = activity.IsSold ? "Confirmed" : activity.IsUnConfirmedSold ? "Submitted For Delivery" : activity.IsGuaranteed ? "First Right Of Refusal" : activity.OnHold ? "On Hold" : "Pending";

              if (!this.form?.get('Activities')?.value)
              {
                this.form.controls["Activities"].patchValue([]);
              }

              this.form?.get('Activities')?.value.push(element);
            }

            //this.form.controls["Activities"].patchValue(this.kendoGridService.view);

            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();
            this.calendarsService.dataResetFromActivitySave = true;
          }
        });
      }
    }

  }

  activityHover(event: any, id: number)
  {
    fromEvent(event.target, event.type)
      .pipe(
        debounceTime(this.configurationService.cbSettings().cacheDebounceTime),
        distinctUntilChanged()
      ).subscribe(() =>
      {
        if (event)
        {
          if (event.stopPropagation)
          {
            // stop event bubbling up
            event.stopPropagation();
          }

          //prevents browser from performing the default action for this element
          event.preventDefault();
        }

        this.queryClient.prefetchQuery({
          queryKey: ["Activity", id],
          queryFn: () => this.calendarsService.getActivity(id),
          //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
        });
      });
  }

  public editHandler({ sender, rowIndex, dataItem, formGroup }: any)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityImplementation)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivitySales)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityStationSpecific)
      || (dataItem.Id > 0 && (this.CanEdit)))
    {
      if (!this.dialogIsOpen)
      {
        this.dialogIsOpen = true;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "95%";
        dialogConfig.maxWidth = "1280px";
        dialogConfig.hasBackdrop = true;

        let dialogRef: any = null;

        const ai: ActivitiesInfo = new ActivitiesInfo();
        ai.id = dataItem.Id;
        ai.IsForCampaign = true;

        dialogConfig.data = ai;

        dialogRef = this.dialog.open(ActivitiesComponent, dialogConfig);

        this.editCloseSubscription = dialogRef.afterClosed().subscribe((result: ActivityUpdateResult) =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            const isDeleteResult = !result.ActivityJson || result.Message.includes("delete");

            if (isDeleteResult)
            {
              this.form.controls["Activities"].patchValue(this.form?.get('Activities')?.value.filter((a: any) => a.Id != dataItem.Id))
            }
            else
            {
              const actInfo: ActivityInfo = JSON.parse(result.ActivityJson);

              if (actInfo)
              {
                const element: ActivityInfo = this.form?.get('Activities')?.value.find((a: any) => a.Id == dataItem.Id);

                if (actInfo.ActivityName)
                {
                  element.ActivityName = actInfo.ActivityName;
                }

                if (actInfo.ProjectManagerId)
                {
                  element.ProjectManagerId = actInfo.ProjectManagerId;
                }

                if (actInfo.EndDate)
                {
                  element.EndDate = this.commonService.parseJsonDate(actInfo.EndDate, true);
                }

                if (actInfo.StartDate)
                {
                  element.StartDate = this.commonService.parseJsonDate(actInfo.StartDate, true);
                }

                if (actInfo.ClientName)
                {
                  element.ClientName = actInfo.ClientName;
                }

                if (actInfo.Id)
                {
                  element.Id = actInfo.Id;
                }

                if (actInfo.Product)
                {
                  element.Product = actInfo.Product;
                }

                if (actInfo.PropertyName)
                {
                  element.PropertyName = actInfo.PropertyName;
                }

                if (actInfo.Status)
                {
                  element.Status = actInfo.Status;
                }
              }
            }

            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();
            this.calendarsService.dataResetFromActivitySave = true;
          }
        });
      }
    }
  }

  assignActivities(event: any)
  {
    if (!this.dialogIsOpen)
    {
      this.dialogIsOpen = true;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "85%";
      dialogConfig.maxWidth = "1420px";
      dialogConfig.height = "85vh";
      dialogConfig.hasBackdrop = true;

      let dialogRef: any = null;

      const ai: ActivitiesInfo = new ActivitiesInfo();
      ai.campaign = this.form.getRawValue();
      ai.IsForCampaign = true;

      dialogConfig.data = ai;

      dialogRef = this.dialog.open(CampaignAssignerComponent, dialogConfig);

      this.editCloseSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
      {
        this.dialogIsOpen = false;

        if (result && result.IsSuccessful)
        {
          this.campaignEditorService.getCampaignActivities(ai.campaign.Id).then(
            data =>
            {
              if (data)
              {
                this.form.controls["Activities"].patchValue(data);
              }
            }).catch((e) =>
            {
            });
        }
      });
    }
  }

  unassignItem(ai: ActivityInfo)
  {
    const aai: ActivityAssignInfo = new ActivityAssignInfo();
    aai.ActivityId = ai.Id;

    const campaign = this.form.getRawValue();
    aai.CampaignId = campaign.Id;

    this.campaignEditorService.unassignActivityFromCampaign(aai).then(
      data =>
      {
        if (data)
        {
          if (data.IsSuccessful)
          {
            if (data.Message)
            {
              this.form.controls["Activities"].patchValue(this.form?.get('Activities')?.value.filter((a: any) => a.Id != ai.Id));

              this.commonService.notifySuccess("Saved", data.Message);
            }
          }
          else
          {
            let errMsg = "Unable to save record.";

            if (data.ErrorMessage)
            {
              errMsg = data.ErrorMessage;
            }

            this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
          }
        }
      }).catch((e) =>
      {
      });
  }

  progressActivities(event: any)
  {
    if (!this.dialogIsOpen)
    {
      this.dialogIsOpen = true;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "70%";
      dialogConfig.maxWidth = "1250px";
      dialogConfig.height = "55vh";
      dialogConfig.hasBackdrop = true;

      let dialogRef: any = null;

      const progressActivitiesInfo: ProgressActivitiesInfo = new ProgressActivitiesInfo();
      progressActivitiesInfo.CampaignId = this.form?.get('Id')?.value;
      progressActivitiesInfo.SelectedActivities = this.campaignEditorService.rowsSelected;

      dialogConfig.data = progressActivitiesInfo;

      dialogRef = this.dialog.open(ActivitiesUpdaterComponent, dialogConfig);

      this.editCloseSubscription = dialogRef.afterClosed().subscribe((result: ProgressActivitiesInfo) =>
      {
        this.dialogIsOpen = false;

        if (result && result.SaveResult && result.SaveResult.IsSuccessful)
        {
          this.campaignEditorService.rowsSelected.map((row: ActivityInfo) =>
          {
            const activityStatus: ActivityStatus = this.calendarsService.statuses.filter(s => s.Value == result.Status)[0];

            if (activityStatus)
            {
              row.Status = activityStatus.Name;
            }
          })

          const activityIds = progressActivitiesInfo.SelectedActivities.map<number>(x => x.Id);

          this.queryClient.invalidateQueries({
            type: "all", exact: false, predicate: (query: any) =>
            {
              if (query.queryKey[0] == "Activity" && (query.queryKey[1] == null || activityIds.some(id => id == query.queryKey[1]) || query.queryKey[1] == 0))
              {
                return true;
              }

              if (query.queryKey[0] == "Calendar")
              {
                return true;
              }

              return false;
            }
          });

          this.campaignEditorService.rowsSelected = [];
          this.selectAllState = "unchecked";

          this.calendarsService.resetHttpCache();
          this.calendarsService.resetCalendarData();
          this.calendarsService.dataResetFromActivitySave = true;
          this.cdr.detectChanges();
        }
      });
    }
  }

  writeValue(value: any): void
  {
    if (value)
    {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void
  {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(disabled: boolean): void
  {
    this._isDisabled = disabled
  }



}
