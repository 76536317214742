import { Injectable, WritableSignal, signal } from '@angular/core';


@Injectable({ providedIn: 'root' })

export class ConfigurationService 
{
  constructor()
  {

  }

  public cbSettings: WritableSignal<CbSettings> = signal(new CbSettings());


}

export class CbSettings
{
  public appServiceApiUrlRoot = "";
  public uploadsDirectory = "";
  public calendarServiceUrl = "";
  public campaignServiceUrl = "";
  public adminServiceUrl = "";
  public authorisationServiceUrl = "";
  public toolsServiceUrl = "";
  public controlPanelServiceUrl = "";
  public dashboardServiceUrl = "";
  public reportsApiUrl = "";
  public fileUploadApiUrl = "";
  public loggingApiUrl = "";
  public eventsApiUrl = "";
  public userPropertyForRetrievingSecurityContext = "";
  public useStationAsGeoTargetAndStationGroupAsVendor = false;
  public enableApplicationMonitor = false;
  public showDigitalCalendar = false;
  public showExceptionMessages = true;
  public showValidationExceptionMessages = true;
  public maxSelectedCalendarStations = 0;
  public messageDurationInMilliseconds = 5000;
  public messageVerticalPosition = "bottom";
  public messageHorizontalPosition = "left";
  public messageShowIcon = false;
  public onHoldDuration = 1;
  public podcastOnHoldDuration = 2;
  public onUnConfirmedSoldDuration = 1;
  public defaultDaysForPodcastActivity = 7;
  public defaultDaysFromStartForDueToAdOps = -3;
  public defaultPodcastDaysUntilDropDead = 10;
  public daysUntilDropDead = 21;
  public daysBeforeStartDateFirstRefusalExpires = 28;
  public minDaysForFirstRefusal = 56;
  public passwordMinimumLength = 0;
  public cacheDebounceTime = 200;
  public creativeBriefSizeLimit = 0;
  public rateCardSizeLimit = 0;
  public logoSizeLimit = 0;
  public imageSizeLimit = 0;
  public additionalInfoSizeLimit = 0;
  public approvedScriptSizeLimit = 0;
  public queryStaleTime = 10000;
  public prefetchQueryStaleTime = 10000;
  public hoursUntilHubConnectionExpires = 24;
  public startOfWeek: 1;
  public systemLocale = "en-US";
  public systemTimezone = "";
  public maxRecurrenceMonths = 24;
  public maxExportRecords = 20000;
  public appHubUrl = "";
  public subscribeToCalendarHubEvents: false;
  public subscribeToDashboardHubEvents: false;
  public reportPaperSize = "A4";
  public reportLeftMargin = "1cm";
  public reportRightMargin = "1cm";
  public reportTopMargin = "1cm";
  public reportBottomMargin = "1cm";
  public reportIsLandscape = false;
  public reportNewTab = true;
  public reportScale: number[] = null;
  public signalRLogLevel: string = "Information";
  public isInterfacingActivities: false;
  public isInterfacingRates: false;
  public isInterfacingClients: false;
  public isInterfacingClientCategories: false;
  public isInterfacingUsers: false;
  public KendoGridDefaultPageSize = 20;
  public pagerPageSizes = [5, 10, 20, 50];
  public authProvider: AuthProvider = new AuthProvider();
}

export class AuthProvider
{
  public authProviderName = "";
  public clientRoot = "";
  public stsAuthority = "";
  public clientId = "";
  public tenantId = "";
  public scope = "";
  public automaticSilentRenew = true;
  public validateSubOnSilentRenew = false;
  public includeIdTokenInSilentRenew = true;
  public monitorSession = true;
  public response_type = "";
}
