import { CommonModule, NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, Signal, ViewChild, ViewEncapsulation, WritableSignal, computed, inject, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from "@angular/material/icon";
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from "@angular/material/sidenav";
import { RouterLink } from "@angular/router";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { IconDefinition, faChevronLeft, faChevronRight, faPrint } from '@fortawesome/free-solid-svg-icons';
import { DropDownFilterSettings, DropDownListModule, MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { BadgeAlign, BadgeModule, BadgePosition, BadgeSize, BadgeThemeColor, SkeletonModule } from '@progress/kendo-angular-indicators';
import { InputsModule, SwitchModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { ContextMenuComponent, ContextMenuSelectEvent, MenusModule } from "@progress/kendo-angular-menu";
import { QueryClient } from "@tanstack/angular-query-experimental";
import { Subscription, debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';
import { DomChangeDirective } from "../../../shared/directives/dom-change.directive";
import { ActivitiesInfo, PropertiesInfo } from '../../../shared/models/common.models';
import { SaveResult } from "../../../shared/models/result.model";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { ExportPrintService } from '../../../shared/services/export-print.service';
import { ActivitiesComponent } from "../activities/activities.component";
import { ActivityBlockerComponent } from "../activity-blocker/activity-blocker.component";
import { CalendarActivity, CalendarProperty, Category, PropertyType, Station, StationType, WeekListItem } from '../calendars.models';
import { CalendarsService } from '../calendars.service';
import { CategoryComponent } from '../category/category.component';
import { PropertyComponent } from "../property/property.component";



@Component({
  selector: "cb-calendar",
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [ExportPrintService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DomChangeDirective, MatDialogModule, CommonModule, SwitchModule, SkeletonModule, MatButtonModule, MenusModule, MatIconModule, FaIconComponent, InputsModule, LabelModule, DropDownListModule, MatSidenavModule, MultiSelectModule, FormsModule, MatMenuModule, NgStyle, BadgeModule, RouterLink, NgClass ]
})
export class CalendarComponent
{
  private queryClient = inject(QueryClient);

  @ViewChild("contextMenu", { static: true }) public contextMenu: ContextMenuComponent;


  public appMenu: MatMenu;
  public badgeThemeColor: BadgeThemeColor = "secondary";
  public badgeAlign: BadgeAlign = { vertical: "top", horizontal: "start" };
  public badgePosition: BadgePosition = 'outside';
  public badgeSize: BadgeSize = 'small';
  public defaultStationGroup: StationType = { Id: 0, StationTypeName: "All", ChangedByUserId: null, Disabled: false, LastUpdated: null, SortOrder: 0 };

  private categoryNewClosedSubscription: Subscription;
  private propertyNewClosedSubscription: Subscription;
  private categoryEditClosedSubscription: Subscription;
  private propertyEditClosedSubscription: Subscription;
  private activityEditClosedSubscription: Subscription;
  private calendarInformationUpdatedSubscription: Subscription;


  private dialogIsOpen = false;

  private _hideActivityDetails: boolean = false;

  get hideActivityDetails(): boolean
  {
    return this._hideActivityDetails;
  }
  set hideActivityDetails(value: boolean)
  {
    this._hideActivityDetails = value;
  }

  public dropdownSettings = {};
  public faChevronLeft: IconDefinition = faChevronLeft;
  public faChevronRight: IconDefinition = faChevronRight;
  public faPrint: IconDefinition = faPrint;

  public configurationService = inject(ConfigurationService);
  public commonService = inject(CommonService);
  public cdr = inject(ChangeDetectorRef);

  constructor(
    public authorisationService: AuthorisationService,
    private exportPrintService: ExportPrintService,
    public calendarsService: CalendarsService,
    private dialog: MatDialog,
    private zone: NgZone)
  {


  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  setEventDayMonthYear(day: string, month: number, year: number, stationId: number, stationTypeId: number)
  {
    this.calendarsService.currentEventDay = parseInt(day);
    this.calendarsService.currentEventMonth = month;
    this.calendarsService.currentEventYear = year;
    this.calendarsService.currentEventStationId = stationId;
    this.calendarsService.currentEventStationTypeId = stationTypeId;
  }

  public isPrinting: WritableSignal<boolean> = signal(false);


  public categories: Signal<Category[]> = computed(() =>
  {
    let _categories: Category[] = [];

    if (!this.calendarsService.selectedPropertyTypeIds() || this.calendarsService.selectedPropertyTypeIds().length == 0 || (this.calendarsService.selectedPropertyTypeIds().length == 1 && this.calendarsService.selectedPropertyTypeIds()[0] == 0))
    {
      _categories = this.calendarsService.categories();
    }
    else
    {
      _categories = this.calendarsService.categories().filter(f => this.calendarsService.properties().filter(p => this.calendarsService.selectedPropertyTypeIds().includes(p.PropertyType?.Id)).map(x => x.CategoryId).includes(f.Id));
    }

    //Ensure that there are always categories returned so if the above results in nothing, return all
    if (!_categories || _categories.length == 0)
    {
      _categories = this.calendarsService.categories();
    }

    return _categories;
  });

  public stationGroups: Signal<StationType[]> = computed(() =>
  {
    let _stationGroups: StationType[] = [];

    if (!this.calendarsService.selectedPropertyTypeIds()
      || this.calendarsService.selectedPropertyTypeIds().length == 0
      || (this.calendarsService.selectedPropertyTypeIds().length == 1 && this.calendarsService.selectedPropertyTypeIds()[0] == 0))
    {
      _stationGroups = this.calendarsService.stationGroups();
    }
    else
    {
      const propertyTypes = this.calendarsService.propertyTypes().filter((f: PropertyType) => this.calendarsService.selectedPropertyTypeIds().includes(f.Id));
      const availStations = this.calendarsService.stations().filter((f: Station) => (f.PlatformType?.Name == "Internet" && propertyTypes.some(s => !s.IsBroadcast)) || (f.PlatformType?.Name == "Radio Station" && propertyTypes.some(s => s.IsBroadcast)));

      _stationGroups = this.calendarsService.stationGroups().filter((f: StationType) => availStations.map((s: Station) => s.StationTypeId).includes(f.Id));
    }

    //Ensure that there are always stationGroups returned so if the above results in nothing, return all
    if (!_stationGroups || _stationGroups.length == 0)
    {
      _stationGroups = this.calendarsService.stationGroups();
    }

    return _stationGroups;
  });

  public hasSelectedStations: Signal<boolean> = computed(() =>
  {
    return this.calendarsService.selectedStations != null && this.calendarsService.selectedStations.length > 0;
  });



  ngOnInit(): void
  {
    window.my = window.my || {};
    window.my.cc = this.pCategoryClick.bind(this);
    window.my.pc = this.pPropertyClick.bind(this);
    window.my.ph = this.pPropertyHover.bind(this);
    window.my.ac = this.pActivityClick.bind(this);
    window.my.ah = this.pActivityHover.bind(this);
    window.my.arc = this.pRightActivityClick.bind(this);

    if (this.authorisationService.currentuser != null && this.authorisationService.currentuser.Id > 0 && !this.calendarsService.homeStation && this.calendarsService.stations())
    {
      // this.calendarsService.homeStation = this.calendarsService.stations().filter((s:Station) => s.Id == this.authorisationService.currentuser.HomeStationId)[0];
      const homestation: Station = this.calendarsService.stations().filter((s: Station) => s.Id == this.authorisationService.currentuser.HomeStationId)[0];
      this.calendarsService.homeStation = homestation;

      if (!this.calendarsService.setStationsFromStorage())
      {
        this.calendarsService.noOfWeeks = 5;

        this.calendarsService.resetSelectedStations();

        const newSelectedStations: Station[] = [];

        if (homestation)
        {
          //Select user's home station.
          this.calendarsService.selectedStationGroupId = homestation.StationType.Id;
          newSelectedStations.push(homestation);

          this.calendarsService.homeStation = homestation;
        }
        else
        {
          //Select 1st station in list if home station not found.
          this.calendarsService.selectedStationGroupId = this.calendarsService.stations()[0].StationType.Id;
          newSelectedStations.push(this.calendarsService.stations()[0]);
        }

        //selected Stations set NOT added to with push so selectedStations localStorage saved
        this.calendarsService.selectedStations = newSelectedStations;
      }

    }

    this.calendarInformationUpdatedSubscription = this.calendarsService.calendarInformationUpdated.subscribe((result: boolean) =>
    {
      if (result)
      {
        this.cdr.markForCheck();
      }
    });
  }


  ngAfterViewInit()
  {
    this.calendarsService.resetCalendarData();

    this.cdr.detectChanges();
  }



  calendarScrolled(event: any)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();

      if (event.target && event.target.scrollTop)
      {
        localStorage.setItem("calendarScroll", event.target.scrollTop.toString());
      }
    }

  }

  onDomChange(event: any): void
  {
    if (event.removedNodes.length != event.addedNodes.length || JSON.stringify(event.removedNodes) != JSON.stringify(event.addedNodes))
    {
      this.cdr.detectChanges();
    }
  }

  print(event: any)
  {
    try
    {
      if (event)
      {
        if (event.stopPropagation)
        {
          // stop event bubbling up
          event.stopPropagation();
        }

        //prevents browser from performing the default action for this element
        event.preventDefault();
      }

      this.isPrinting.set(true);
      this.commonService.isLoading.set(true);
      //this.cdr.detectChanges();

      const reportTitle = "Audio Calendar";

      const calendarElement: any = document.getElementById("core-calendar");

      this.exportPrintService.exportElement(calendarElement, reportTitle.replace(/\s/g, '') + ".pdf", this.commonService.reportNewTab).then((data: any) =>
      {
        this.isPrinting.set(false);
        this.commonService.isLoading.set(false);
        //this.cdr.detectChanges();
      })
        .catch((err: any) =>
        {
          this.isPrinting.set(false);
          this.commonService.isLoading.set(false);
          //this.cdr.detectChanges();
        });
    }
    catch (e)
    {
      this.isPrinting.set(false);
      this.commonService.isLoading.set(false);
      // this.cdr.detectChanges();
    }
  }



  next(event: any)
  {
    this.nextWeek();
  }

  nextWeek()
  {
    const nextWeek: WeekListItem = this.calendarsService.weeksForSelectedYear.filter(w => w.WeekNo == this.calendarsService.selectedWeek.WeekNo + 1)[0];

    if (nextWeek)
    {
      this.calendarsService.selectedWeek = nextWeek;

      this.calendarsService.resetCalendarData();
    }
    else
    {
      const lastSelectableYear: number = this.calendarsService.years[this.calendarsService.years.length - 1];
      const nextYear: number = this.calendarsService.selectedYear + 1;

      if (nextYear <= lastSelectableYear)
      {
        this.calendarsService.selectedYear = nextYear;

        const firstWeek: WeekListItem = this.calendarsService.weeksForSelectedYear.filter(w => w.WeekNo == 1)[0];

        if (firstWeek)
        {
          this.calendarsService.selectedWeek = firstWeek;

          setTimeout(() =>
          {
            this.calendarsService.resetCalendarData();
          }, 0);
        }
      }
    }
  }


  previous(event: any)
  {
    this.previousWeek();
  }


  previousWeek()
  {
    const previousWeek: WeekListItem = this.calendarsService.weeksForSelectedYear.filter(w => w.WeekNo == this.calendarsService.selectedWeek.WeekNo - 1)[0];

    if (previousWeek)
    {
      this.calendarsService.selectedWeek = previousWeek;

      this.calendarsService.resetCalendarData(); 
    }
    else
    {
      const firstSelectableYear: number = this.calendarsService.years[0];
      const previousYear: number = this.calendarsService.selectedYear - 1;

      if (previousYear >= firstSelectableYear)
      {
        this.calendarsService.selectedYear = previousYear;

        const lastWeekInPreviousYear: number = this.calendarsService.weeksForSelectedYear[this.calendarsService.weeksForSelectedYear.length - 1].WeekNo;

        const lastWeek: WeekListItem = this.calendarsService.weeksForSelectedYear.filter(w => w.WeekNo == lastWeekInPreviousYear)[0];

        if (lastWeek)
        {
          this.calendarsService.selectedWeek = lastWeek;

          setTimeout(() =>
          {
            this.calendarsService.resetCalendarData(); 
          }, 0);
        }
      }
    }
  }

  propertyHover(event: any, id: number)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up 
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (id > 0)
    {
      this.queryClient.prefetchQuery({
        queryKey: ["Property", id],
        queryFn: () => this.calendarsService.getProperty(id),
        //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
      });
    }
  }

  activityHover(event: any, id: string)
  {
    const hidden: any = document.getElementById(id);
    const activity: CalendarActivity = this.getCalendarActivityFromJson(hidden.value);

    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up 
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }


    const stationIds: number[] = [];
    this.calendarsService.selectedStations.map((s) => stationIds.push(s.Id));

    const activitiesParameters = {
      startDate: this.commonService.dateToWebApiString(activity.FirstDateInCalendarRange),
      endDate: this.commonService.dateToWebApiString(activity.LastDateInCalendarRange),
      stationIds: stationIds,
      propertyId: activity.PropertyId,
      level: activity.Level
    };

    if (this.configurationService.cbSettings().calendarServiceUrl.length > 0 && activitiesParameters != null && !!activitiesParameters?.stationIds && activitiesParameters?.stationIds?.length > 0)
    {
      this.queryClient.prefetchQuery({
        queryKey: ["Activities", activitiesParameters],
        queryFn: () => this.calendarsService.getActivitiesForPropertyAndDateRange(activitiesParameters),
        //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
      });
    }

    if (activity?.Id > 0)
    {
      this.queryClient.prefetchQuery({
        queryKey: ["Activity", activity.Id],
        queryFn: () => this.calendarsService.getActivity(activity.Id),
        //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
      });
    }

    return true;
  }


  pActivityHover(event: any, id: string)
  {
    fromEvent(event.target, event.type)
      .pipe(
        debounceTime(this.configurationService.cbSettings().cacheDebounceTime),
        distinctUntilChanged()
      ).subscribe(() =>
      {
        this.zone.run(() => this.activityHover(event, id));
      });
  }

  pPropertyHover(event: any, id: number)
  {
    fromEvent(event.target, event.type)
      .pipe(
        debounceTime(this.configurationService.cbSettings().cacheDebounceTime),
        distinctUntilChanged()
      ).subscribe(() =>
      {
        this.zone.run(() => this.propertyHover(event, id));
      });
  }

  pCategoryClick(event: any, id: number)
  {
    this.zone.run(() => this.categoryClick(event, id));
  }

  pPropertyClick(event: any, id: number)
  {
    this.zone.run(() => this.propertyClick(event, id));
  }

  pActivityClick(event: any, id: string)
  {
    this.zone.run(() => this.activityClick(event, id));
  }

  pRightActivityClick(event: any)
  {
    this.zone.run(() => this.activityRightClick(event));
  }

  categoryClick(event: any, id: number)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.EditCategory) || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCategory))
    {
      if (!this.dialogIsOpen)
      {
        try
        {
          this.dialogIsOpen = true;

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "24rem";
          dialogConfig.hasBackdrop = true;

          dialogConfig.data = id;

          try
          {
            const dialogRef = this.dialog.open(CategoryComponent, dialogConfig);

            this.categoryEditClosedSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
            {
              this.dialogIsOpen = false;

              if (result && result.IsSuccessful)
              {
                this.calendarsService.resetHttpCache();
                this.calendarsService.resetCalendarData();
                this.calendarsService.dataResetFromCategorySave = true;
              }
            });
          }
          catch (e)
          {
            this.dialogIsOpen = false;
          }
        }
        catch (e)
        {
          this.dialogIsOpen = false;
        }
      }
    }

  }

  propertyClick(event: any, id: number)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProperty) || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewProperty))
    {
      if (!this.dialogIsOpen)
      {
        try
        {
          this.dialogIsOpen = true;

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "52rem";
          dialogConfig.height = "98vh";
          dialogConfig.hasBackdrop = true;

          const propertiesInfo: PropertiesInfo = new PropertiesInfo();
          propertiesInfo.id = id;
          propertiesInfo.isOnline = false;

          dialogConfig.data = propertiesInfo;

          const dialogRef = this.dialog.open(PropertyComponent, dialogConfig);

          this.propertyEditClosedSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
          {
            this.dialogIsOpen = false;

            if (result && result.IsSuccessful)
            {
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();
              this.calendarsService.dataResetFromPropertySave = true;
            }
          });
        }
        catch (e)
        {
          this.dialogIsOpen = false;
        }
      }
    }
  }

  activityRightClick(event: any)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }
  }

  openNewActivity(activity: CalendarActivity, currentActivityOnSpot: boolean)
  {
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddBroadcastActivity)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.AddNonBroadcastActivity))
    {
      if (!this.dialogIsOpen)
      {
        this.dialogIsOpen = true;

        if (activity.IsAvailable)
        {
          const stationIds: number[] = [];

          //Only want activities for station of current activity clicked
          stationIds.push(activity.StationId);

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "95%";
          dialogConfig.maxWidth = "1280px";
          dialogConfig.hasBackdrop = true;

          let dialogRef: any = null;

          activity.Id = 0;

          const actInfo: ActivitiesInfo = new ActivitiesInfo();
          actInfo.activity = activity;
          actInfo.stationIds = stationIds;
          actInfo.newActivityOnOccupiedSpot = currentActivityOnSpot;

          dialogConfig.data = actInfo;

          dialogRef = this.dialog.open(ActivitiesComponent, dialogConfig);

          this.activityEditClosedSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
          {
            this.dialogIsOpen = false;

            if (result && result.IsSuccessful)
            {
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();
              this.calendarsService.dataResetFromActivitySave = true;
            }
          });
        }
      }
    }
  }

  public onSelect(e: ContextMenuSelectEvent): void
  {
    const hidden: any = e.target.querySelector("input[type=hidden]");
    const activity: CalendarActivity = this.getCalendarActivityFromJson(hidden.value);

    this.calendarsService.stationIdOfClickedActivity = activity.StationId;

    const currentActivityOnSpot: boolean = activity && activity.Id > 0;

    //Change values to make it a new activity.
    activity.StartDate = activity.FirstDateInCalendarRange;
    activity.EndDate = activity.LastDateInCalendarRange;
    activity.Id = 0;

    //Don't allow the creation of new activities for past dates
    if (activity.Id < 1 && new Date(activity.EndDate) < this.commonService.today)
    {
      this.dialogIsOpen = false;
      return;
    }

    switch (e.item.text)
    {
      case "New Activity":
        this.openNewActivity(activity, currentActivityOnSpot);
        break;
      case "New Blocker":
        this.openNewActivityBlocker(activity);
        break;
    }

  }

  openNewActivityBlocker(activity: CalendarActivity)
  {
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddActivityBlocker))
    {
      if (!this.dialogIsOpen)
      {
        this.dialogIsOpen = true;

        if (activity.IsAvailable)
        {
          const stationIds: number[] = [];

          //Only want activities for station of current activity clicked
          stationIds.push(activity.StationId);

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "95%";
          dialogConfig.maxWidth = "55rem";
          dialogConfig.hasBackdrop = true;

          let dialogRef: any = null;

          activity.IsGuaranteed = false;
          activity.IsPending = false;
          activity.OnHold = false;
          activity.IsUnConfirmedSold = false;
          activity.IsSold = true;

          dialogConfig.data = activity;

          dialogRef = this.dialog.open(ActivityBlockerComponent, dialogConfig);

          this.activityEditClosedSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
          {
            this.dialogIsOpen = false;

            if (result && result.IsSuccessful)
            {
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();
              this.calendarsService.dataResetFromActivitySave = true;
            }
          });
        }
      }
    }
  }

  getCalendarActivityFromJson(json: string)
  {
    const activityWithShortNames: any = JSON.parse(json);

    const ca: CalendarActivity = new CalendarActivity();

    ca.Id = activityWithShortNames.I;
    ca.IsAvailable = activityWithShortNames.A == 1;
    ca.IsBlocker = activityWithShortNames.B == 1;
    ca.IsSold = activityWithShortNames.D == undefined || activityWithShortNames.D == null || activityWithShortNames.D == 1;
    ca.ClientName = activityWithShortNames.C;
    ca.PropertyId = activityWithShortNames.P;
    ca.StationId = activityWithShortNames.S;
    ca.Level = activityWithShortNames.V;
    ca.FirstDateInCalendarRange = this.commonService.adjustToUtcDateOnly(activityWithShortNames.F);

    if (activityWithShortNames.I > 0)
    {
      ca.LastDateInCalendarRange = this.commonService.adjustToUtcDateOnly(activityWithShortNames.L);
      ca.EndDate = this.commonService.adjustToUtcDateOnly(activityWithShortNames.E);
      ca.StartDate = this.commonService.adjustToUtcDateOnly(activityWithShortNames.T);
    }
    else //if new then all these dates will be the same and on F brought over to client for performance
    {
      ca.LastDateInCalendarRange = ca.FirstDateInCalendarRange;
      ca.EndDate = ca.FirstDateInCalendarRange;
      ca.StartDate = ca.FirstDateInCalendarRange;
    }

    return ca;
  }


  activityClick(event: any, id: string)
  {
    const hidden: any = document.getElementById(id);
    const activity: CalendarActivity = this.getCalendarActivityFromJson(hidden.value);

    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      if (event.cancelBubble)
      {
        // stop event bubbling up
        event.cancelBubble = true;
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (!activity)
    {
      this.dialogIsOpen = false;
      return;
    }

    this.calendarsService.stationIdOfClickedActivity = activity.StationId;

    const startDate = new Date(activity.StartDate);

    //Don't allow the creation of new activities for past dates
    if (activity.Id < 1 && startDate < this.commonService.today)
    {
      this.dialogIsOpen = false;
      return;
    }

    if (activity && event)
    {
      //new activity
      if (activity.Id < 1)
      {
        //right click pressed
        if (event.button == 2)
        {
          const hidden: any = document.getElementById(id);

          this.contextMenu.show(hidden.parentElement);
          return;
        }

        if (event.ctrlKey)
        {
          this.openNewActivityBlocker(activity);
          return;
        }

        if (event.altKey)
        {
          this.contextMenu.show(hidden.parentElement);
          return;
        }
      }
      else
      {
        //right click pressed
        if (event.button == 2)
        {
          if (!activity.IsSold && !activity.IsBlocker)
          {
            //Change values to make it a new activity.
            activity.StartDate = activity.FirstDateInCalendarRange;
            activity.EndDate = activity.LastDateInCalendarRange;
            activity.Id = 0;

            this.openNewActivity(activity, true);
          }

          return;
        }
      }
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityImplementation)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivitySales)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityStationSpecific)
      || (activity.Id < 1 && this.authorisationService.hasPermission(this.authorisationService.Permissions.AddBroadcastActivity))
      || (activity.Id > 0 && (this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivitySales)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityImplementation)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityStationSpecific))))
    {
      if (!this.dialogIsOpen)
      {
        this.dialogIsOpen = true;

        if (activity.IsAvailable)
        {
          const stationIds: number[] = [];

          //Only want activities for station of current activity clicked
          stationIds.push(activity.StationId);

          const dialogConfig = new MatDialogConfig();

          var compmonent: any;
          var width: any = "95%";
          var maxWidth: any = "1280px";
          var data: any;

          if (activity.IsBlocker)
          {
            compmonent = ActivityBlockerComponent;
            width = "75%";
            maxWidth = "900px";
            data = activity;
          }
          else
          {
            compmonent = ActivitiesComponent;
            data = new ActivitiesInfo()
            data.activity = activity;
            data.stationIds = stationIds;
            data.newActivityOnOccupiedSpot = false;
          }

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = width;
          dialogConfig.maxWidth = maxWidth;
          dialogConfig.hasBackdrop = true;

          let dialogRef: any = null;

          dialogConfig.data = data;

          dialogRef = this.dialog.open(compmonent, dialogConfig);

          this.activityEditClosedSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
          {
            this.dialogIsOpen = false;

            if (result && result.IsSuccessful)
            {
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();
              this.calendarsService.dataResetFromActivitySave = true;
            }
          });
        }
      }
    }
  }

  newCategory()
  {
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddCategory))
    {
      if (!this.dialogIsOpen)
      {
        try
        {
          this.dialogIsOpen = true;

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "24rem";
          dialogConfig.hasBackdrop = true;
          dialogConfig.data = 0;

          const dialogRef = this.dialog.open(CategoryComponent, dialogConfig);

          this.categoryNewClosedSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
          {
            this.dialogIsOpen = false;

            if (result && result.IsSuccessful)
            {
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();

              this.calendarsService.dataResetFromCategorySave = true;
            }
          });
        }
        catch (e)
        {
          this.dialogIsOpen = false;
        }
      }
    }
  }

  newProperty()
  {
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProperty))
    {
      if (!this.dialogIsOpen)
      {
        try
        {
          this.dialogIsOpen = true;

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "52rem";
          dialogConfig.height = "98vh";
          dialogConfig.hasBackdrop = true;

          const propertiesInfo: PropertiesInfo = new PropertiesInfo();
          propertiesInfo.id = 0;
          propertiesInfo.isOnline = false;

          dialogConfig.data = propertiesInfo;

          const dialogRef = this.dialog.open(PropertyComponent, dialogConfig);

          this.propertyNewClosedSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
          {
            this.dialogIsOpen = false;

            if (result && result.IsSuccessful)
            {
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();

              this.calendarsService.dataResetFromPropertySave = true;
            }
          });
        }
        catch (e)
        {
          this.dialogIsOpen = false;
        }
      }
    }
  }


  swipeLeft(event: any)
  {
    if (event)
    {
      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    this.nextWeek();
  }

  swipeRight(event: any)
  {
    if (event)
    {
      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    this.previousWeek();
  }

  pinchIn(event: any)
  {
    if (event)
    {
      //prevents browser from performing the default action for this element
      event.preventDefault();

      const element: any = event.target;

      let scale: any = element.css('transform');

      scale = (scale == null ? element.css('-webkit-transform') : scale);
      scale = (scale == null ? element.css('-ms-transform') : scale);
      scale = scale.split(" ");
      scale = parseFloat(scale[0].substring(7, scale[0].length - 1));

      if (scale > 1)
      {
        scale = ('scale(' + (scale - .1).toString() + ')');
        element.css({ 'transform': scale, '-ms-transform': scale, '-webkit-transform': scale });
      }
    }
  }

  pinchOut(event: any)
  {
    if (event)
    {
      //prevents browser from performing the default action for this element
      event.preventDefault();

      const element: any = event.target;

      let scale: any = element.css('transform');

      scale = (scale == null ? element.css('-webkit-transform') : scale);
      scale = (scale == null ? element.css('-ms-transform') : scale);
      scale = scale.split(" ");
      scale = parseFloat(scale[0].substring(7, scale[0].length - 1));

      if (scale < 5)
      {
        scale = ('scale(' + (scale + .1).toString() + ')');

        element.css({ 'transform': scale, '-ms-transform': scale, '-webkit-transform': scale });
      }
    }
  }

  handleWeeksChange(week: WeekListItem)
  {
    if (week)
    {
      this.calendarsService.resetCalendarData();
    }
  }

  handleNoOfWeeksChange(event: any)
  {
    this.calendarsService.noOfWeeks = parseInt(event.target.value);

    this.calendarsService.resetCalendarData();
  }

  handleYearChange(event: any)
  {
    this.calendarsService.selectedYear = event;

    let weekInNewYear: WeekListItem = this.calendarsService.weeksForSelectedYear.filter(w => w.WeekNo == this.calendarsService.selectedWeek.WeekNo)[0];

    if (!weekInNewYear)
    {
      weekInNewYear = this.calendarsService.weeksForSelectedYear.filter(w => w.WeekNo == 1)[0];
    }

    this.calendarsService.selectedWeek = weekInNewYear;

    this.calendarsService.resetCalendarData();
  }

  handleCategoryChange(categoryIds: any)
  {
    this.calendarsService.selectedCategoryIds = categoryIds;
  }

  handleAvailOnlyChange(availOnly: any)
  {

  }

  handlePropertyTypeChange(propertyType: any)
  {
    this.calendarsService.selectedPropertyTypeIds.set(propertyType);

    this.calendarsService.selectedCategoryIds = this.categories().map((m: Category) => m.Id).filter((i: any) => this.calendarsService.selectedCategoryIds.includes(i));

    this.resetStationGroup(0);
  }

  resetStationGroup(stationGroupId: number)
  {
    this.calendarsService.selectedStationGroupId = stationGroupId;

    if (!this.calendarsService.selectedStations.map(s => s.Id).some(s => this.calendarsService.availableStations.map(s => s.Id).includes(s)))
    {
      this.calendarsService.selectedStations = [];
    }

    this.calendarsService.resetSelectedStations();

    this.calendarsService.setNoOfWeeks();
  }

  handleStationGroupChange(stationGroupId: any)
  {
    this.resetStationGroup(stationGroupId);
  }

  stationsSelectedChange(stations: any)
  {
    this.calendarsService.setNoOfWeeks();
  }

  async filter()
  {
    this.calendarsService.resetCalendarData();
  }

  drawerClosed()
  {
    this.filter();
  }





  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.categoryEditClosedSubscription)
    {
      this.categoryEditClosedSubscription.unsubscribe();
    }
    if (this.categoryNewClosedSubscription)
    {
      this.categoryNewClosedSubscription.unsubscribe();
    }
    if (this.propertyEditClosedSubscription)
    {
      this.propertyEditClosedSubscription.unsubscribe();
    }
    if (this.propertyNewClosedSubscription)
    {
      this.propertyNewClosedSubscription.unsubscribe();
    }
    if (this.activityEditClosedSubscription)
    {
      this.activityEditClosedSubscription.unsubscribe();
    }
    if (this.calendarInformationUpdatedSubscription)
    {
      this.calendarInformationUpdatedSubscription.unsubscribe();
    }




  }

  trackByCalendarProperties(index: number, calendarProperty: CalendarProperty)
  {
    return calendarProperty["Id"];
  }

  trackByCalendarActivities(index: number, calendarActivity: CalendarActivity)
  {
    return calendarActivity["Id"];
  }
  trackByPropertyTypes(index: number, propertyType: PropertyType)
  {
    return propertyType["Id"];
  }

  trackByStations(index: number, station: Station)
  {
    return station["Id"];
  }

  trackByStationType(index: number, stationType: StationType)
  {
    return stationType["Id"];
  }
}


declare global
{
  interface Window { my: any; }
}
