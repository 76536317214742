import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { NgClass } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, Output, Signal, WritableSignal, computed, inject, signal } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { IconDefinition, faBroadcastTower, faClone, faEnvelope, faPhone, faPrint, faUser } from '@fortawesome/free-solid-svg-icons';
import { DatePickerModule, PopupSettings, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { ComboBoxModule, DropDownFilterSettings, DropDownListModule, MultiSelectModule, PopupSettings as ddlPopupSettings } from '@progress/kendo-angular-dropdowns';
import { SVGIconModule } from "@progress/kendo-angular-icons";
import { FormFieldModule, TextAreaModule, TextBoxModule } from "@progress/kendo-angular-inputs";
import { FloatingLabelModule, LabelModule } from "@progress/kendo-angular-label";
import { TooltipModule } from "@progress/kendo-angular-tooltip";
import { arrowRotateCwIcon, nonRecurrenceIcon } from "@progress/kendo-svg-icons";
import { injectMutation, QueryClient } from "@tanstack/angular-query-experimental";
import { addBusinessDays, addDays, differenceInCalendarDays, differenceInCalendarWeeks, endOfWeek, startOfWeek } from "date-fns";
import { Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AirtimeCreditsComponent } from "../../../shared/components/airtime-credits/airtime-credits.component";
import { BaseComponent } from '../../../shared/components/base/base.component';
import { ConfirmationDialog } from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { ContactsSelectorComponent } from "../../../shared/components/contacts-selector/contacts-selector.component";
import { DocumentUploaderComponent } from "../../../shared/components/document-uploader/document-uploader.component";
import { RecurrenceComponent } from "../../../shared/components/recurrence/recurrence.component";
import { ActivitiesInfo, ContactTypeUser, ReportInfo, User } from "../../../shared/models/common.models";
import { ActivityDisplayFieldPipe } from "../../../shared/pipes/activity-display-field.pipe";
import { GenericFilterPipe } from "../../../shared/pipes/generic-filter.pipe";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonDataService } from '../../../shared/services/common-data.service';
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { UploadService } from "../../../shared/services/upload.service";
import { ReportComponent } from '../../reports/report.component';
import { ActivityExtensionsComponent } from "../activity-extension/activity-extensions.component";
import { CalendarsBaseService } from "../calendars-base.service";
import { Activity, ActivityCopy, ActivityCreditInfo, ActivityHeaderTextChangeArgs, ActivityInfo, ActivityStationInfo, ActivityStatus, ActivityStatusChangeArgs, Category, Client, MechanicType, ProductCategory, Property, PropertyStationInfo, PropertyType, Recurrence, Station, ValidatedControl } from '../calendars.models';
import { CalendarsService } from '../calendars.service';
import { ActivityService } from "./activity.service";
import { ActivitiesService } from "../activities/activities.service";


@Component({
  selector: "activity",
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  providers: [ActivityService],
  standalone: true,
  imports: [TooltipModule, CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatFormFieldModule, MatInputModule, FloatingLabelModule, ComboBoxModule, MatDatepickerModule, MatIconModule, MatCheckboxModule, FaIconComponent, MatTooltipModule, MatTabsModule, NgClass, MatCardModule, MatButtonModule, SVGIconModule, MultiSelectModule, ContactsSelectorComponent, DropDownListModule, DatePickerModule, MatSelectModule, MatOptionModule, DocumentUploaderComponent, FormFieldModule, TextAreaModule, TextBoxModule, LabelModule, ActivityExtensionsComponent, TimePickerModule, AirtimeCreditsComponent, MatProgressSpinnerModule, MatDialogActions, GenericFilterPipe, ActivityDisplayFieldPipe]
})
export class ActivityComponent extends BaseComponent
{
  private dialogIsOpen = false;

  public RecurrenceTypeNone = 0;
  public RecurrenceTypeDaily = 1;
  public RecurrenceTypeWeekly = 2;
  public RecurrenceTypeMonthly = 3;
  public RecurrenceTypeYearly = 4;


  private queryClient = inject(QueryClient);

  public configurationService = inject(ConfigurationService);
  public activitiesService = inject(ActivitiesService);

  public clients: Client[] = [];
  public properties: Property[] = [];



  public virtual: any = {
    itemHeight: 28,
  };

  public icons = {
    nonRecurrence: nonRecurrenceIcon,
    recurrence: arrowRotateCwIcon
  };

  private _recurrenceSummary = "";
  private _recurrenceTypeName = "";

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public updateActivity = injectMutation(() => ({
    mutationFn: (activity: any) =>
    {
      return this.calendarsService.updateActivity(activity);
    },
    onSuccess: async (data: any, variables: any, context: any) =>
    {
      await this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          if (query.queryKey[0] == "Activity" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0))
          {
            return true;
          }

          if (query.queryKey[0] == "Activities")
          {
            if (this.activityData.ActivitiesParameters && query.queryKey[1])
            {
              let ap = this.activityData.ActivitiesParameters;
              let apQ = query.queryKey[1];

              if (ap.level == apQ.level && ap.propertyId == apQ.propertyId)
              {
                return true;
              }
            }
            else if (!query.queryKey[1])
            {
              return true;
            }
          }

          if (query.queryKey[0] == "Calendar")
          {
            return true;
          }

          return false;
        }
      });

      await this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "DashboardData";
        }
      });

      this.queryClient.prefetchQuery({
        queryKey: ["Activity", variables.Id],
        queryFn: () => this.calendarsService.getActivity(variables.Id),
        //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
      });
    }
  }))

  public createActivity = injectMutation(() => ({
    mutationFn: (activity: any) =>
    {
      return this.calendarsService.createActivity(activity);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "DashboardData";
        }
      });

      this.queryClient.prefetchQuery({
        queryKey: ["Activity", data.Id],
        queryFn: () => this.calendarsService.getActivity(data.Id),
        //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
      });
    }
  }))

  public deleteActivity = injectMutation(() => ({
    mutationFn: (activity: any) =>
    {
      return this.calendarsService.deleteActivity(activity);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Activity" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0);
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Activities";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "DashboardData";
        }
      });
    }
  }))


  public formSSI: FormGroup = new FormGroup({
    Id: new FormControl(),
    ActivityId: new FormControl(),
    ProjectManagerId: new FormControl(),
    AdOpsId: new FormControl(),
    StationId: new FormControl(),
    ActivityName: new FormControl(),
    CreditLineKeyNo: new FormControl(),
    AirtimeBreakdown: new FormControl(),
    Mechanic: new FormControl(),
    AdditionalInfo: new FormControl(),
    AdditionalInfoUrl: new FormControl(),
    ApprovedScriptUrl: new FormControl(),
    Rate: new FormControl(),
    IsRateCpm: new FormControl(),
    MultimediaRecovery: new FormControl(),
    Revenue: new FormControl(),
    Production: new FormControl(),
    StartTime: new FormControl(),
    EndTime: new FormControl(),
    OnAirTime: new FormControl(),
    SignageRequired: new FormControl(),
    LocationAddress: new FormControl(),
    WhereisUrl: new FormControl(),
    OnsiteContactName: new FormControl(),
    OnsiteContactTel: new FormControl(),
    ParkingInstructions: new FormControl(),
    BroadcastingLive: new FormControl(),
    TicketingOutlet: new FormControl(),
    TicketingAllocation: new FormControl(),
    HasOTP: new FormControl(),
    IsFreeOfCharge: new FormControl(),
    HasPageBackground: new FormControl(),
    NoOfPageBackgrounds: new FormControl(),
    ActivityCreditInfos: new FormControl()
  });

  public form: FormGroup = new FormGroup({
    Id: new FormControl(),
    Level: new FormControl(),
    ClientId: new FormControl(),
    Product: new FormControl(),
    Property: new FormControl(),
    ActivityStatus: new FormControl(),
    //Level:  new FormControl(),
    Recurrence: new FormControl(),
    ProductCategories: new FormControl(),
    ActivityStationInfos: new FormControl(),
    Stations: new FormControl(),
    Scripts: new FormControl(),
    CreativeSets: new FormControl(),
    CreditLineRotationTypeId: new FormControl(),
    CreditLineTypeId: new FormControl(),
    CreditLineNotes: new FormControl(),
    CreditLine: new FormControl(),
    CreditLineDueDate: new FormControl({ value: null, disabled: false }),
    MinorPrize: new FormControl(),
    MinorPrizeHowFulFilled: new FormControl(),
    MinorPrizeValue: new FormControl(),
    MinorPrizeQuantity: new FormControl(),
    MajorPrize: new FormControl(),
    MajorPrizeHowFulFilled: new FormControl(),
    MajorPrizeValue: new FormControl(),
    MajorPrizeQuantity: new FormControl(),
    StartDate: new FormControl({ value: null, disabled: false }),
    EndDate: new FormControl({ value: null, disabled: false }),
    CreationDate: new FormControl({ value: null, disabled: true }),
    DropDead: new FormControl({ value: null, disabled: false }),
    ClientExecUserId: new FormControl(),
    ClientSupportUserId: new FormControl(),
    BriefManagerUserId: new FormControl(),
    NatProjectManagerUserId: new FormControl(),
    ProgrammerUserId: new FormControl(),
    WebsiteUrl: new FormControl(),
    IsGuaranteed: new FormControl(),
    IsUnConfirmedSold: new FormControl(),
    IsSold: new FormControl(),
    SoldDate: new FormControl(),
    OnHold: new FormControl(),
    OnHoldDate: new FormControl({ value: null, disabled: false }),
    MechanicType: new FormControl(),
    MechanicDescription: new FormControl(),
    Digital: new FormControl(),
    HasVideo: new FormControl(),
    HasAudio: new FormControl(),
    HasPhotos: new FormControl(),
    HasEntryForm: new FormControl(),
    LogoUrl: new FormControl(),
    ImageUrl: new FormControl(),
    CreativeBriefUrl: new FormControl(),
    Newsletter: new FormControl(),
    DailyUpdate: new FormControl(),
    BookingId: new FormControl(),
    SoldFormUrl: new FormControl(),
    FirstDayAirCheckDate: new FormControl({ value: null, disabled: false }),
    FirstDayAirCheckNo: new FormControl(),
    ScreenShotsDate: new FormControl({ value: null, disabled: false }),
    ScreenShotsNo: new FormControl(),
    FinalPCRDate: new FormControl({ value: null, disabled: false }),
    FinalPCRAirCheck: new FormControl(),
    FinalPCRAirPhotos: new FormControl(),
    FinalPCRAirVideos: new FormControl(),
    CrossBookedWithActivity: new FormControl(),
    PreRecorded: new FormControl(),
    DateDueToAdOps: new FormControl({ value: null, disabled: false }),
    HostSocialPostedDate: new FormControl({ value: null, disabled: false }),
    Exclusive: new FormControl(),
    PCRSpecialRequest: new FormControl(),
    PhotoBrief: new FormControl(),
    VideoBrief: new FormControl(),
    Pre: new FormControl(),
    Mid: new FormControl(),
    Post: new FormControl(),
    ActivityExtensions: new FormControl(),
    FurtherDetails: new FormControl(),
    SocialBriefDetails: new FormControl(),
    BrandToneKeyMessaging: new FormControl(),
    IsProductSupplied: new FormControl(),
    IsExtensionApproved: new FormControl(),
    IsConfidential: new FormControl(),
    IsBlocker: new FormControl(),
    CanConflictProductCategory: new FormControl(),
    ShowInWhatsOnCalendar: new FormControl(),
    CreatedAsFRR: new FormControl(),
    Disabled: new FormControl(),
    SortOrder: new FormControl()
  });

  public saveInProgress = false;

  public formSalesControlsDisabled = false;
  public formImplemenationControlsDisabled = false;
  public formSSIControlsDisabled = false;

  private _requireClientExec = false;
  private _requireClientSupport = false;
  private _requireBriefManager = false;
  private _requireProgrammer = false;

  private _currentStationId: number;
  private _isFrrEligible = false;
  private _activityStatus = "";
  private _activityHeader = "";
  private _heldUntilText = "";
  private _activityStatusClass: any = { 'pending-activity-text': true };
  private _airtimeCreditsText = "";
  private _statuses: ActivityStatus[] = [];
  private _projectManagersForStation: ContactTypeUser[] = [];
  private _adOpsForStation: ContactTypeUser[] = [];
  private _propertyStations: Station[] = [];
  private _activityStations: Station[] = [];
  private _currentActivityId: number = 0;
  private _currentActivityStatus: ActivityStatus;


  private _hasVideoLabelText = "Video";
  private _hasAudioLabelText = "Audio";
  private _hasPhotosLabelText = "Photos";
  private _hasEntryFormLabelText = "Entry form";
  private _exclusiveLabelText = "Exclusive";

  private _finalPcrAirCheckLabelText = "Air Checks";
  private _firstDayAirCheckBoxLabelText = "First Day Air Check";
  private _creditLineBoxLabelText = "Credit Line";
  private _creditLineLabelText = "Context/Extra Info";
  private _creditLineNotesLabelText = "Credit Line Notes";
  private _additionInfoLabelText = "Context/Extra Info";
  private _majorPrizeLabelText = "Major Prizing";
  private _majorPrizeValueLabelText = "Major Prizing Value (ea)";
  private _majorPrizeQtyLabelText = "Major Prizing Qty (ttl)";
  private _majorPrizeFulfillmentLabelText = "Major Prizing Fulfilment";
  private _minorPrizeLabelText = "Minor Prizing";
  private _minorPrizeValueLabelText = "Minor Prizing Value (ea)";
  private _minorPrizeQtyLabelText = "Minor Prizing Qty (ttl)";
  private _minorPrizeFulfillmentLabelText = "Minor Prizing Fulfilment";
  private _activityNameLabelText = "Promotion Name";
  private _rateLabelText = "Rate";
  private _revenueLabelText = "NT Revenue";
  private _multiMediaRecoveryLabelText = "Digital Revenue";
  private _mechanicLabelText = "Mechanic";
  private _airtimeBreakdownLabelText = "Airtime Breakdown";
  private _signageLabelText = "Signage";
  private _productionLabelText = "Production Budget";
  private _mechanicTypeLabelText = "Digital";
  private _mechanicTypeDescLabelText = "Digital Description";
  private _preRecordedLabelText = "Pre-Recorded";
  private _parkingInstructionsLabelText = "Parking Instructions";
  private _broadcastingLiveLabelText = "Live Broadcast";
  private _nationalProjectManagerLabelText = "Campaign Delivery Manager";

  public daysUntilDropDead: number;

  public contactNameLabel: string = "";
  public contactPhoneLabel: string = "";
  public contactEmailLabel: string = "";

  public currentClientContact: string = "";
  public currentClientContactEmail: string = "";
  public currentClientContactPhoneNo: string = "";

  public currentStationContact: string = "";
  public currentStationContactEmail: string = "";
  public currentStationContactPhoneNo: string = "";

  public currentClientExecutive: ContactTypeUser;
  public currentClientSupport: ContactTypeUser;
  public currentBriefManager: ContactTypeUser;
  public currentNationalProjectManager: ContactTypeUser;
  public currentProjectManager: ContactTypeUser;
  public currentAdOps: ContactTypeUser;
  public currentProgrammer: ContactTypeUser;
  private dialogCloseSubscription: Subscription;
  private recurrenceClosedSubscription: Subscription;
  private _spotSoldInRange = false;

  public faBroadcastTower: IconDefinition = faBroadcastTower;
  public faEnvelope: IconDefinition = faEnvelope;
  public faPhone: IconDefinition = faPhone;
  public faUser: IconDefinition = faUser;
  public faClone: IconDefinition = faClone;
  public faPrint: IconDefinition = faPrint;

  public popupSettings: PopupSettings = {
    appendTo: 'root',
    animate: false
  };

  public comboBoxPopupSettings: ddlPopupSettings = {
    popupClass: 'popup-class-activity'
  };

  @Input() public showHeader = true;
  @Input() public showFooter = true;
  @Input() public showCancelButton = true;
  @Input() public delegateCancelButtonToParent = false;
  @Input() public closeAfterSave = true;
  @Input() public activityData: Activity = null;


  @Input() get spotSoldInRange(): boolean
  {
    return this._spotSoldInRange;

  }
  set spotSoldInRange(value: boolean)
  {
    this._spotSoldInRange = value;

    const shouldDisable: boolean = (this._spotSoldInRange && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditConfirmedActivity))
      || ((this.currentActivity()?.Disabled == true) && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDisabledActivity));

    this.disableFormImplemenationSalesControls(shouldDisable);
    this.disableFormSalesControls(shouldDisable);
    this.disableFormSSIControls(shouldDisable);
  }

  @Output() public activityHeaderTextChange: EventEmitter<ActivityHeaderTextChangeArgs> = new EventEmitter<ActivityHeaderTextChangeArgs>();
  @Output() public statusChange: EventEmitter<ActivityStatusChangeArgs> = new EventEmitter<ActivityStatusChangeArgs>();
  @Output() public copyActivity: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cancelButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public activitySaved: EventEmitter<any> = new EventEmitter<any>();


  private formValueChangesSubscription: Subscription;
  private formSSIValueChangesSubscription: Subscription;
  private isDisabledValueChangesSubscription: Subscription;
  private reportClosedSubscription: Subscription;


  public currentProperty: WritableSignal<Property> = signal(null);
  public currentCategory: WritableSignal<Category> = signal(null);
  public currentPropertyType: WritableSignal<PropertyType> = signal(null);

  public currentActivity = signal(null);

  public isNew: WritableSignal<boolean> = signal(false);
  public isRadioBroadcastPropertyType: WritableSignal<boolean> = signal(true);


  public isAuthorised: Signal<boolean> = computed(() =>
  {
    let may = this.isNew();

    if (this.currentActivity() != null && !may)
    {
      may = !this.currentActivity()?.IsConfidential
        || (this.currentActivity()?.IsConfidential && this.currentUserIsOnActivity())
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewConfidentialActivity)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditConfidentialActivity);
    }

    return may;
  });

  public currentUserIsOnActivity: Signal<boolean> = computed(() =>
  {
    let isOnActivity = false;

    if (!this.isNew())
    {
      isOnActivity = (this.authorisationService.currentuser != null && this.authorisationService.currentuser.Id > 0)
        && (
          ((this.currentClientExecutive != null && this.currentClientExecutive.Id > 0) && this.authorisationService.currentuser.Id == this.currentClientExecutive.Id)
          || ((this.currentClientSupport != null && this.currentClientSupport.Id > 0) && this.authorisationService.currentuser.Id == this.currentClientSupport.Id)
          || ((this.currentProgrammer != null && this.currentProgrammer.Id > 0) && this.authorisationService.currentuser.Id == this.currentProgrammer.Id)
          || ((this.currentBriefManager != null && this.currentBriefManager.Id > 0) && this.authorisationService.currentuser.Id == this.currentBriefManager.Id)
          || ((this.currentNationalProjectManager != null && this.currentNationalProjectManager.Id > 0) && this.authorisationService.currentuser.Id == this.currentNationalProjectManager.Id)
        );

      if (!isOnActivity && this.currentActivity() && this.currentActivity()?.ActivityStationInfos != null && this.currentActivity()?.ActivityStationInfos.length > 0)
      {
        this.currentActivity().ActivityStationInfos.map((a: ActivityStationInfo) =>
        {
          if (this.authorisationService.currentuser.Id == a.ProjectManagerId)
          {
            isOnActivity = true;
          }

          if (this.authorisationService.currentuser.Id == a.AdOpsId)
          {
            isOnActivity = true;
          }
        });
      }
    }
    else
    {
      isOnActivity = true;
    }

    return isOnActivity;
  });


  public activityMinDate: Signal<Date> = computed(() =>
  {
    if (this.isNew())
    {
      return this.commonService.today
    }
    else
    {
      if (this.currentActivity().StartDate < this.commonService.today)
      {
        return this.currentActivity().StartDate;
      }
      else
      {
        return this.commonService.today;
      }
    }
  });



  public calendarsService: CalendarsBaseService;



  constructor(
    protected injector: Injector,
    private authorisationService: AuthorisationService,
    private dialogRef: MatDialogRef<ActivityComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ActivitiesInfo,
    private confirmDialog: MatDialog,
    public commonService: CommonService,
    public commonDataService: CommonDataService,
    private loggerService: LoggerService,
    private uploadService: UploadService,
    private dialog: MatDialog,
    public activityService: ActivityService,
    private cdr: ChangeDetectorRef)
  {
    super();

    //if (data.isOnline)
    //{
    //  this.calendarsService = this.injector.get(OnlineCalendarsService);
    //}
    //else
    //{
    this.calendarsService = this.injector.get(CalendarsService);
    //}

    this.setInitialData();

    this.formValueChangesSubscription = this.form.valueChanges
      .pipe(map((_) => this.form.getRawValue()))
      .subscribe((value) =>
      {
        this.currentActivity.set(value);
      });
  }

  async ngOnInit(): Promise<void>
  {
    this.subscribeToFormSsiValueChanges();

    this.isDisabledValueChangesSubscription = this.form.get('Disabled').valueChanges.subscribe(val =>
    {
      if (!val && this.currentActivity() && this.currentActivity()?.DropDead < this.commonService.today)
      {
        this.form.controls["DropDead"].patchValue(this.commonService.ensureDateIsNotOnWeekend(this.commonService.today), { emitEvent: false });
      }

      this.disableFormSalesControls(val && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDisabledActivity));
      this.disableFormImplemenationSalesControls(val && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDisabledActivity));
      this.disableFormSSIControls(val && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDisabledActivity));
    });

    this.setupActivity(this.activityData);

    this.setDisableActivityStateForPermissions(true);

    this.setActivityLabelText();
    this.setActivityValidators();
    this.setActivityVisibility();

    this.clients = this.commonDataService.clients();

    if (this.isNew())
    {
      this.properties = this.calendarsService.currentProperties;
    }
    else
    {
      this.properties = this.calendarsService.properties();
    }

    //Show required fields in red
    this.form.markAllAsTouched();
    this.formSSI.markAllAsTouched();
  }

  async setInitialData()
  {
    if (!this.activityData && this.data?.activity)
    {
      this.activityData = await this.activitiesService.CreateNewActivity(this.data.activity);
    }

    this.currentActivity.set(this.form.getRawValue());

    if (!this.currentActivity() || this.currentActivity().Id == null)
    {
      this.currentActivity.set(this.activityData);
    }

    let propertyId = 0;

    if (this.data?.activity?.PropertyId)
    {
      propertyId = this.data.activity.PropertyId;
    }

    if (propertyId == 0 && this.currentActivity()?.Property?.Id)
    {
      propertyId = this.currentActivity().Property.Id;
    }

    if (propertyId > 0)
    {
      this.setCurrentProperty(propertyId);
    }
  }

  setupActivity(data: Activity)
  {
    try
    {
      if (data)
      {
        /// MUST BE FIRST!!

        this.isNew.set(data.Id < 1);
        this._currentActivityId = this.activityData.Id;

        if (data.IsCopy)
        {
          data.IsGuaranteed = false;
          data.IsUnConfirmedSold = false;
          data.IsSold = false;
          data.OnHold = false;
          data.SoldDate = null;
          data.OnHoldDate = null;
          data.Status = "Pending";
        }

        if (data.Property)
        {
          this.setCurrentProperty(data.Property.Id);

          this.currentActivity().Property = this.currentProperty();

          if (data.IsCopy)
          {
            this.form.markAsDirty();
          }
        }

        this.daysUntilDropDead = this.configurationService.cbSettings().daysUntilDropDead;

        if (!this.isRadioBroadcastPropertyType())
        {
          const station: Station = this.calendarsService.stations().filter((station: Station) => station.Id == this.currentProperty().StationLevels[0].StationId)[0];

          if (station)
          {
            this.daysUntilDropDead = station.DaysUntilDropDead;
          }

          if (this.isNew())
          {
            this.activityData.IsConfidential = true;
          }
        }

        this.currentActivityStatus = { Name: 'Pending', Value: 'Pending' };

        if (this.calendarsService.statuses && this.calendarsService.statuses.length > 0)
        {
          if (data.OnHold)
          {
            this.currentActivityStatus = this.calendarsService.statuses.filter((s: ActivityStatus) => s.Value == "OnHold")[0];
          }

          if (data.IsGuaranteed)
          {
            this.currentActivityStatus = this.calendarsService.statuses.filter((s: ActivityStatus) => s.Value == "IsGuaranteed")[0];
          }

          if (data.IsUnConfirmedSold)
          {
            this.currentActivityStatus = this.calendarsService.statuses.filter((s: ActivityStatus) => s.Value == "IsUnConfirmedSold")[0];
          }

          if (data.IsSold)
          {
            this.currentActivityStatus = this.calendarsService.statuses.filter((s: ActivityStatus) => s.Value == "IsSold")[0];
          }
        }

        this.setUpActivityStatus(this.currentActivityStatus.Value);

        if (data.StartDate)
        {
          data.StartDate = this.commonService.parseJsonDate(data.StartDate);
        }
        else
        {
          data.StartDate = this.commonService.today;
        }

        if (data.EndDate)
        {
          data.EndDate = this.commonService.parseJsonDate(data.EndDate);
        }
        else
        {
          data.EndDate = this.commonService.today;
        }

        if (!this.isRadioBroadcastPropertyType() && this.isNew())
        {
          data.DateDueToAdOps = addBusinessDays(data.StartDate, this.configurationService.cbSettings().defaultDaysFromStartForDueToAdOps);

          //default to full week for new activities
          data.StartDate = startOfWeek(data.StartDate, { weekStartsOn: this.configurationService.cbSettings().startOfWeek });
          data.EndDate = endOfWeek(data.StartDate, { weekStartsOn: this.configurationService.cbSettings().startOfWeek });
        }

        if (data.CreationDate)
        {
          data.CreationDate = this.commonService.parseJsonDate(data.CreationDate);
        }
        else
        {
          data.CreationDate = this.commonService.today;
        }

        if (data.CreditLineDueDate)
        {
          data.CreditLineDueDate = this.commonService.parseJsonDate(data.CreditLineDueDate);
        }

        if (data.SoldDate)
        {
          data.SoldDate = this.commonService.parseJsonDate(data.SoldDate);
        }

        if (data.DropDead)
        {
          data.DropDead = this.commonService.parseJsonDate(data.DropDead);
        }
        else
        {
          data.DropDead = this.commonService.today;
          data.DropDead.setDate(data.CreationDate.getDate() + this.daysUntilDropDead);
          data.DropDead = this.commonService.ensureDateIsNotOnWeekend(data.DropDead);
        }

        if (data.FinalPCRDate)
        {
          data.FinalPCRDate = this.commonService.parseJsonDate(data.FinalPCRDate);
        }

        if (this.isNew())
        {
          data.FirstDayAirCheckDate = data.StartDate;
        }
        else
        {
          if (data.FirstDayAirCheckDate)
          {
            data.FirstDayAirCheckDate = this.commonService.parseJsonDate(data.FirstDayAirCheckDate);
          }
        }

        if (data.OnHoldDate)
        {
          data.OnHoldDate = this.commonService.parseJsonDate(data.OnHoldDate);
        }

        if (data.ScreenShotsDate)
        {
          data.ScreenShotsDate = this.commonService.parseJsonDate(data.ScreenShotsDate);
        }

        if (data.DateDueToAdOps)
        {
          data.DateDueToAdOps = this.commonService.parseJsonDate(data.DateDueToAdOps);
        }

        if (data.HostSocialPostedDate)
        {
          data.HostSocialPostedDate = this.commonService.parseJsonDate(data.HostSocialPostedDate);
        }

        if (!data.Stations || data.Stations.length == 0)
        {
          data.Stations = [];

          if (this.calendarsService.selectedStations && this.calendarsService.selectedStations.length > 0)
          {
            this.calendarsService.selectedStations.map((station: Station) =>
            {
              ////only add station if in property
              if (this.currentProperty()?.StationLevels.some(st => st.StationId == station.Id))
              {
                data.Stations.push(station.Id);
              }
            });
          }
        }

        if (!data.ProductCategories || data.ProductCategories.length == 0)
        {
          data.ProductCategories = [];
        }

        if (!data.ActivityExtensions || data.ActivityExtensions.length == 0)
        {
          data.ActivityExtensions = [];
        }

        if (data.Recurrence)
        {
          if (data.Recurrence.FirstOccurrenceDate)
          {
            data.Recurrence.FirstOccurrenceDate = this.commonService.parseJsonDate(data.Recurrence.FirstOccurrenceDate);
          }
          if (data.Recurrence.LastOccurrenceDate)
          {
            data.Recurrence.LastOccurrenceDate = this.commonService.parseJsonDate(data.Recurrence.LastOccurrenceDate);
          }
          if (data.Recurrence.MaximumDate)
          {
            data.Recurrence.MaximumDate = this.commonService.parseJsonDate(data.Recurrence.MaximumDate);
          }
          if (data.Recurrence.MinimumDate)
          {
            data.Recurrence.MinimumDate = this.commonService.parseJsonDate(data.Recurrence.MinimumDate);
          }
          if (data.Recurrence.PatternEndDate)
          {
            data.Recurrence.PatternEndDate = this.commonService.parseJsonDate(data.Recurrence.PatternEndDate);
          }
          if (data.Recurrence.PatternStartDate)
          {
            data.Recurrence.PatternStartDate = this.commonService.parseJsonDate(data.Recurrence.PatternStartDate);
          }

          if (data.Recurrence.RecurrenceType == this.RecurrenceTypeNone)
          {
            this.form.get('StartDate').enable({ emitEvent: false });
            this.form.get('EndDate').enable({ emitEvent: false });
          }
          else
          {
            this.form.get('StartDate').disable({ emitEvent: false });
            this.form.get('EndDate').disable({ emitEvent: false });
          }
        }

        if (data.Stations && data.Stations.length > 0)
        {
          data.Stations.map((stationId: number) =>
          {
            //add new blank station specific info object for station if one doesn't exist.
            if (!data.ActivityStationInfos || !data.ActivityStationInfos.some((ssi: ActivityStationInfo) => stationId == ssi.StationId))
            {
              const newSSI: ActivityStationInfo = new ActivityStationInfo();
              newSSI.StationId = stationId;
              newSSI.ActivityId = data.Id;

              if (!data.ActivityStationInfos)
              {
                data.ActivityStationInfos = [];
              }

              data.ActivityStationInfos.push(newSSI);
            }

          });
        }

        if (data.ActivityStationInfos && data.ActivityStationInfos.length > 0)
        {
          data.ActivityStationInfos.map((asi: ActivityStationInfo) =>
          {
            if (asi.EndTime)
            {
              asi.EndTime = this.commonService.parseJsonDate(asi.EndTime);
            }
            if (asi.OnAirTime)
            {
              asi.OnAirTime = this.commonService.parseJsonDate(asi.OnAirTime);
            }
            if (asi.StartTime)
            {
              asi.StartTime = this.commonService.parseJsonDate(asi.StartTime);
            }
          });
        }

        if (this.currentProperty())
        {
          if (this.currentProperty()?.StationLevels && this.currentProperty()?.StationLevels.length > 0)
          {
            const propStations: Station[] = this.calendarsService.stations().filter((station: Station) => this.currentProperty().StationLevels?.some(s => station.Id == s.StationId && s.MaxLevel > this.activityData.Level));

            if (propStations && propStations.length > 0)
            {
              //remove station info records for stations that are not selected in the activity
              data.ActivityStationInfos = data.ActivityStationInfos.filter(asi => propStations.some(ps => ps.Id == asi.StationId));
              data.Stations = data.Stations.filter(s => propStations.some(ps => ps.Id == s));
            }
          }
        }

        if (this.commonDataService.clientExecutives() && this.commonDataService.clientExecutives().length > 0)
        {
          if (data.ClientExecUserId > 1)
          {
            this.currentClientExecutive = this.commonDataService.clientExecutives().filter((u: ContactTypeUser) => u.Id == data.ClientExecUserId)[0];
          }
        }

        if (this.commonDataService.clientSupport() && this.commonDataService.clientSupport().length > 0)
        {
          if (data.ClientSupportUserId > 1)
          {
            this.currentClientSupport = this.commonDataService.clientSupport().filter((u: ContactTypeUser) => u.Id == data.ClientSupportUserId)[0];
          }
        }

        if (this.commonDataService.briefManagers() && this.commonDataService.briefManagers().length > 0)
        {
          if (data.BriefManagerUserId > 1)
          {
            this.currentBriefManager = this.commonDataService.briefManagers().filter((u: ContactTypeUser) => u.Id == data.BriefManagerUserId)[0];
          }
        }

        if (this.commonDataService.programmers() && this.commonDataService.programmers().length > 0)
        {
          if (data.ProgrammerUserId > 1)
          {
            this.currentProgrammer = this.commonDataService.programmers().filter((u: ContactTypeUser) => u.Id == data.ProgrammerUserId)[0];
          }
        }

        if (this.commonDataService.nationalProjectManagers() && this.commonDataService.nationalProjectManagers().length > 0)
        {
          if (data.NatProjectManagerUserId > 1)
          {
            this.currentNationalProjectManager = this.commonDataService.nationalProjectManagers().filter((u: ContactTypeUser) => u.Id == data.NatProjectManagerUserId)[0];
          }
        }

        this.form.controls["Id"].patchValue(data.Id, { emitEvent: false });
        this.form.controls["Level"].patchValue(data.Level, { emitEvent: false });
        this.form.controls["ClientId"].patchValue(data.ClientId);
        this.form.controls["Product"].patchValue(data.Product, { emitEvent: false });
        this.form.controls["Property"].patchValue(this.currentProperty(), { emitEvent: false });
        this.form.controls["Property"].disable({ emitEvent: false });
        this.form.controls["ActivityStatus"].patchValue(this.currentActivityStatus, { emitEvent: false });
        //form.controls["Level").patchValue(data.Level, { emitEvent: false });
        this.form.controls["Recurrence"].patchValue(data.Recurrence, { emitEvent: false });
        this.form.controls["ProductCategories"].patchValue(data.ProductCategories, { emitEvent: false });
        this.form.controls["ActivityStationInfos"].patchValue(data.ActivityStationInfos, { emitEvent: false });
        this.form.controls["Stations"].patchValue(data.Stations, { emitEvent: false });
        this.form.controls["Scripts"].patchValue(data.Scripts, { emitEvent: false });
        this.form.controls["CreativeSets"].patchValue(data.CreativeSets, { emitEvent: false });
        this.form.controls["CreditLineRotationTypeId"].patchValue(data.CreditLineRotationTypeId, { emitEvent: false });
        this.form.controls["CreditLineTypeId"].patchValue(data.CreditLineTypeId, { emitEvent: false });
        this.form.controls["CreditLineNotes"].patchValue(data.CreditLineNotes, { emitEvent: false });
        this.form.controls["CreditLine"].patchValue(data.CreditLine, { emitEvent: false });
        this.form.controls["CreditLineDueDate"].patchValue(data.CreditLineDueDate, { emitEvent: false });
        this.form.controls["MinorPrize"].patchValue(data.MinorPrize, { emitEvent: false });
        this.form.controls["MinorPrizeHowFulFilled"].patchValue(data.MinorPrizeHowFulFilled, { emitEvent: false });
        this.form.controls["MinorPrizeValue"].patchValue(data.MinorPrizeValue, { emitEvent: false });
        this.form.controls["MinorPrizeQuantity"].patchValue(data.MinorPrizeQuantity, { emitEvent: false });
        this.form.controls["MajorPrize"].patchValue(data.MajorPrize, { emitEvent: false });
        this.form.controls["MajorPrizeHowFulFilled"].patchValue(data.MajorPrizeHowFulFilled, { emitEvent: false });
        this.form.controls["MajorPrizeValue"].patchValue(data.MajorPrizeValue, { emitEvent: false });
        this.form.controls["MajorPrizeQuantity"].patchValue(data.MajorPrizeQuantity, { emitEvent: false });
        this.form.controls["StartDate"].patchValue(data.StartDate, { emitEvent: false });
        this.form.controls["EndDate"].patchValue(data.EndDate, { emitEvent: false });
        this.form.controls["CreationDate"].patchValue(data.CreationDate, { emitEvent: false });
        this.form.controls["DropDead"].patchValue(data.DropDead, { emitEvent: false });
        this.form.controls["ClientExecUserId"].patchValue(data.ClientExecUserId, { emitEvent: false });
        this.form.controls["ClientSupportUserId"].patchValue(data.ClientSupportUserId, { emitEvent: false });
        this.form.controls["BriefManagerUserId"].patchValue(data.BriefManagerUserId, { emitEvent: false });
        this.form.controls["NatProjectManagerUserId"].patchValue(data.NatProjectManagerUserId, { emitEvent: false });
        this.form.controls["ProgrammerUserId"].patchValue(data.ProgrammerUserId, { emitEvent: false });
        this.form.controls["WebsiteUrl"].patchValue(data.WebsiteUrl, { emitEvent: false });
        this.form.controls["IsGuaranteed"].patchValue(data.IsGuaranteed, { emitEvent: false });
        this.form.controls["IsUnConfirmedSold"].patchValue(data.IsUnConfirmedSold, { emitEvent: false });
        this.form.controls["IsSold"].patchValue(data.IsSold, { emitEvent: false });
        this.form.controls["SoldDate"].patchValue(data.SoldDate, { emitEvent: false });
        this.form.controls["OnHold"].patchValue(data.OnHold, { emitEvent: false });
        this.form.controls["OnHoldDate"].patchValue(data.OnHoldDate, { emitEvent: false });
        this.form.controls["MechanicType"].patchValue(data.MechanicType, { emitEvent: false });
        this.form.controls["MechanicDescription"].patchValue(data.MechanicDescription, { emitEvent: false });
        this.form.controls["Digital"].patchValue(data.Digital, { emitEvent: false });
        this.form.controls["HasVideo"].patchValue(data.HasVideo, { emitEvent: false });
        this.form.controls["HasAudio"].patchValue(data.HasAudio, { emitEvent: false });
        this.form.controls["HasPhotos"].patchValue(data.HasPhotos, { emitEvent: false });
        this.form.controls["HasEntryForm"].patchValue(data.HasEntryForm, { emitEvent: false });
        this.form.controls["LogoUrl"].patchValue(data.LogoUrl, { emitEvent: false });
        this.form.controls["ImageUrl"].patchValue(data.ImageUrl, { emitEvent: false });
        this.form.controls["CreativeBriefUrl"].patchValue(data.CreativeBriefUrl, { emitEvent: false });
        this.form.controls["Newsletter"].patchValue(data.Newsletter, { emitEvent: false });
        this.form.controls["DailyUpdate"].patchValue(data.DailyUpdate, { emitEvent: false });
        this.form.controls["BookingId"].patchValue(data.BookingId, { emitEvent: false });
        this.form.controls["SoldFormUrl"].patchValue(data.SoldFormUrl, { emitEvent: false });
        this.form.controls["FirstDayAirCheckDate"].patchValue(data.FirstDayAirCheckDate, { emitEvent: false });
        this.form.controls["FirstDayAirCheckNo"].patchValue(data.FirstDayAirCheckNo, { emitEvent: false });
        this.form.controls["ScreenShotsDate"].patchValue(data.ScreenShotsDate, { emitEvent: false });
        this.form.controls["ScreenShotsNo"].patchValue(data.ScreenShotsNo, { emitEvent: false });
        this.form.controls["FinalPCRDate"].patchValue(data.FinalPCRDate, { emitEvent: false });
        this.form.controls["FinalPCRAirCheck"].patchValue(data.FinalPCRAirCheck, { emitEvent: false });
        this.form.controls["FinalPCRAirPhotos"].patchValue(data.FinalPCRAirPhotos, { emitEvent: false });
        this.form.controls["FinalPCRAirVideos"].patchValue(data.FinalPCRAirVideos, { emitEvent: false });
        this.form.controls["CrossBookedWithActivity"].patchValue(data.CrossBookedWithActivity, { emitEvent: false });
        this.form.controls["PreRecorded"].patchValue(data.PreRecorded, { emitEvent: false });
        this.form.controls["DateDueToAdOps"].patchValue(data.DateDueToAdOps, { emitEvent: false });
        this.form.controls["HostSocialPostedDate"].patchValue(data.HostSocialPostedDate, { emitEvent: false });
        this.form.controls["Exclusive"].patchValue(data.Exclusive, { emitEvent: false });
        this.form.controls["PCRSpecialRequest"].patchValue(data.PCRSpecialRequest, { emitEvent: false });
        this.form.controls["PhotoBrief"].patchValue(data.PhotoBrief, { emitEvent: false });
        this.form.controls["VideoBrief"].patchValue(data.VideoBrief, { emitEvent: false });
        this.form.controls["Pre"].patchValue(data.Pre, { emitEvent: false });
        this.form.controls["Mid"].patchValue(data.Mid, { emitEvent: false });
        this.form.controls["Post"].patchValue(data.Post, { emitEvent: false });
        this.form.controls["ActivityExtensions"].patchValue(data.ActivityExtensions, { emitEvent: false });
        this.form.controls["FurtherDetails"].patchValue(data.FurtherDetails, { emitEvent: false });
        this.form.controls["SocialBriefDetails"].patchValue(data.SocialBriefDetails, { emitEvent: false });
        this.form.controls["BrandToneKeyMessaging"].patchValue(data.BrandToneKeyMessaging, { emitEvent: false });
        this.form.controls["IsProductSupplied"].patchValue(data.IsProductSupplied, { emitEvent: false });
        this.form.controls["IsExtensionApproved"].patchValue(data.IsExtensionApproved, { emitEvent: false });
        this.form.controls["IsConfidential"].patchValue(data.IsConfidential, { emitEvent: false });
        this.form.controls["IsBlocker"].patchValue(data.IsBlocker, { emitEvent: false });
        this.form.controls["CanConflictProductCategory"].patchValue(data.CanConflictProductCategory || (data.CreatedExternally && (!data.ProductCategories || data.ProductCategories.length < 1)), { emitEvent: false });
        this.form.controls["ShowInWhatsOnCalendar"].patchValue(data.ShowInWhatsOnCalendar, { emitEvent: false });
        this.form.controls["CreatedAsFRR"].patchValue(data.CreatedAsFRR, { emitEvent: false });
        this.form.controls["Disabled"].patchValue(data.Disabled, { emitEvent: false });
        this.form.controls["SortOrder"].patchValue(data.SortOrder, { emitEvent: false });

        //if (data.IsSold)
        //{
        //  this.form.disable({ onlySelf: false, emitEvent: true });
        //}
        //else
        //{
        //  this.form.enable({ onlySelf: false, emitEvent: true });
        //}

        if (this.currentPropertyType()?.PropertyTypeName != "Client Promotion")
        {
          //remove mechanictype created with default activity if property type doesn't use mechanic type.
          data.MechanicType = null;
          this.form.controls["MechanicType"].patchValue(data.MechanicType, { emitEvent: false });
        }

        this.setUpStatuses();

        const asis: ActivityStationInfo[] = this.form.get("ActivityStationInfos").value;

        if (!asis || asis.length == 0)
        {
          //Add stations from property if none on activity
          this.setStationsSelection(this.propertyStations.map(m => m.Id));
        }
        else
        {
          this.setStationsSelection(data.Stations);

          //Select the station from the station they clicked under in the calendar
          let asi: ActivityStationInfo = asis.filter(a => a.StationId == this.calendarsService.stationIdOfClickedActivity)[0];

          if (!asi)
          {
            //if unable to get the station clicked get the first in the list (SortOrder)
            asi = asis[0];
          }

          this.formSSI.patchValue(asi, { emitEvent: false });

          this.currentStationId = this.formSSI.get("StationId").value;

          const station: Station = this.activityStations.filter((station: Station) => station.Id == this.currentStationId)[0];

          if (station)
          {
            this.currentStationContact = station.Contact;
            this.currentStationContactEmail = station.ContactEmail;
            this.currentStationContactPhoneNo = station.ContactPhoneNo;
          }

          if (this.projectManagersForStation && this.projectManagersForStation.length > 0)
          {
            this.currentProjectManager = this.projectManagersForStation.filter((pm: ContactTypeUser) => pm.Id == this.formSSI.get("ProjectManagerId").value)[0];
          }

          if (this.adOpsForStation && this.adOpsForStation.length > 0)
          {
            this.currentAdOps = this.adOpsForStation.filter((ao: ContactTypeUser) => ao.Id == this.formSSI.get("AdOpsId").value)[0];
          }
        }

        this.setIsFrrEligible();

        this.setActivityHeader();

        if (!this.activityData.ClientContact)
        {
          if (this.activityData.ClientId && this.activityData.ClientId > 0)
          {
            const client = this.getClientFromClientId(this.activityData.ClientId);

            if (client)
            {
              this.setClientContactInformation(client);
            }
          }
        }
        else
        {
          this.currentClientContact = this.activityData.ClientContact;
          this.currentClientContactEmail = this.activityData.ClientContactEmail;
          this.currentClientContactPhoneNo = this.activityData.ClientContactPhoneNo;
        }

        this.cdr.detectChanges();
      }

    }
    catch (ex)
    {
    }
  }

  subscribeToFormSsiValueChanges()
  {
    setTimeout(() =>
    {
      this.formSSIValueChangesSubscription = this.formSSI.valueChanges.subscribe(val =>
      {
        this.form.markAsDirty();
      });
    }, 0);
  }

  unSubscribeToFormSsiValueChanges()
  {
    if (this.formSSIValueChangesSubscription)
    {
      this.formSSIValueChangesSubscription.unsubscribe();

      this.formSSIValueChangesSubscription = null;
    }
  }


  get tabLabel(): string
  {
    let name = "Station";

    if (!this.isRadioBroadcastPropertyType())
    {
      name = "Implementation";
    }

    return name;
  }

  get platformNameLabel(): string
  {
    let name = "Platform Provider"; // "Station";

    if (!this.isRadioBroadcastPropertyType())
    {
      name = "Platform Provider"; // "Internet";
    }

    return name;
  }

  print(event: any)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (!this.dialogIsOpen)
    {
      try
      {
        this.dialogIsOpen = true;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "900px";
        dialogConfig.height = "98vh";
        dialogConfig.hasBackdrop = true;

        const reportInfo: ReportInfo = new ReportInfo();
        reportInfo.id = this.currentActivityId;
        reportInfo.reportName = "ConfirmForm.trdp";
        reportInfo.reportParameters = { ActivityId: this.currentActivityId, ReportCulture: this.commonService.getLocaleName() };
        reportInfo.viewingContainerHeight = "53rem";
        reportInfo.viewingContainerWidth = "850px";
        reportInfo.showCloseButton = true;
        reportInfo.dragDisabled = false;
        reportInfo.showParameters = false;

        dialogConfig.data = reportInfo;

        const dialogRef = this.dialog.open(ReportComponent, dialogConfig);

        this.reportClosedSubscription = dialogRef.afterClosed().subscribe(() =>
        {
          this.dialogIsOpen = false;
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
  }

  selectedTabChange(event: any)
  {
    if (event.index == 2)
    {
      this.setActivityValidators(null, false);
    }
  }



  setDisableActivityStateForPermissions(includeTabDisable: boolean)
  {
    this.form.get('ActivityStatus').enable({ emitEvent: false });

    this.form.get('Disabled').disable({ emitEvent: false });
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.CanEnableDisableActivity) && (this.currentActivityStatus.Value != "IsSold" || (this.currentActivityStatus.Value == "IsSold" && this.currentActivity()?.Disabled)))
    {
      this.form.get('Disabled').enable({ emitEvent: false });
    }

    if (!this.activityData.IsPlusClick && (this.activityData.IsCopy || ((!this.activityData.Id || this.activityData.Id < 1) && !this.activityData.Property)))
    {
      this.form.get('Property').enable({ emitEvent: false });
    }
    else
    {
      this.form.get('Property').disable({ emitEvent: false });
    }

    //always disabled
    this.form.get('CreationDate').disable({ emitEvent: false });

    //always enabled
    this.form.get('CreditLine').enable({ emitEvent: false });

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeDropdeadDate))
    {
      this.form.get('DropDead').enable({ emitEvent: false });
    }
    else
    {
      this.form.get('DropDead').disable({ emitEvent: false });
    }

    this.form.get('OnHoldDate').disable({ emitEvent: false });
    if ((this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeOnHoldHeldDate) && this.currentActivityStatus && this.currentActivityStatus.Value == "OnHold")
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeSFDHeldDate) && this.currentActivityStatus && this.currentActivityStatus.Value == "IsUnConfirmedSold")
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeIsGuaranteedHeldDate) && this.currentActivityStatus && this.currentActivityStatus.Value == "IsGuaranteed"))
    {
      this.form.get('OnHoldDate').enable({ emitEvent: false });
    }

    this.form.get('CanConflictProductCategory').disable({ emitEvent: false });
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.AllowActivityProductCategoryConflict))
    {
      this.form.get('CanConflictProductCategory').enable({ emitEvent: false });
    }

    if (includeTabDisable)
    {
      const shouldDisable: boolean = (this.isNew() && !this.canAddActivity)
        || ((this.activityData.IsConfidential && !this.currentUserIsOnActivity()) && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditConfidentialActivity))
        || (this.currentActivityStatus && this.currentActivityStatus.Value == "OnHold" && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditOnHoldActivity))
        || (this.currentActivityStatus && this.currentActivityStatus.Value == "IsSold" && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditConfirmedActivity))
        || (this.currentActivityStatus && this.currentActivityStatus.Value != "IsSold" && this.spotSoldInRange)
        || ((this.currentActivity() && this.currentActivity()?.Disabled == true) && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDisabledActivity));

      const isFormSalesInitiallyDisabled: boolean = shouldDisable
        || (!this.isNew() && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivitySales));

      const isFormImplementationInitiallyDisabled: boolean = shouldDisable
        || (!this.isNew() && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityImplementation));

      const isFormSSIInitiallyDisabled: boolean = shouldDisable
        || (!this.isNew() && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityStationSpecific));

      this.disableFormSalesControls(isFormSalesInitiallyDisabled);
      this.disableFormImplemenationSalesControls(isFormImplementationInitiallyDisabled);
      this.disableFormSSIControls(isFormSSIInitiallyDisabled);
    }
    //if (this.currentActivityStatus && this.currentActivityStatus.Value != "IsSold" && this.spotSoldInRange)
    //{
    //  //If not the sold item in the activities where one is sold allow to change the date range.
    //  this.form.get('Recurrence').enable({emitEvent:false});
    //}

    if (this.activityData.CreatedExternally)
    {
      this.form.get('ClientId').disable();
      this.form.get('Product').disable({ emitEvent: false });
      this.form.get('Property').disable({ emitEvent: false });
      this.form.get('ProductCategories').disable();
      this.form.get('CanConflictProductCategory').disable({ emitEvent: false });
      this.form.get('Stations').disable();
      this.form.get('StartDate').disable({ emitEvent: false });
      this.form.get('EndDate').disable({ emitEvent: false });
      this.form.get('BookingId').disable({ emitEvent: false });

      if (this.activityData.ClientExecUserId && this.activityData.ClientExecUserId > 0)
      {
        this.form.get('ClientExecUserId').disable({ emitEvent: false });
      }

      this.formSSI.get('ActivityName').disable({ emitEvent: false });
      //this.formSSI.get('AirtimeBreakdown').disable({ emitEvent: false });
      this.formSSI.get('IsRateCpm').disable({ emitEvent: false });
      this.formSSI.get('Rate').disable({ emitEvent: false });
      this.formSSI.get('Revenue').disable({ emitEvent: false });
      this.formSSI.get('FOC').disable({ emitEvent: false });
    }

    if (this.activityData.IsForCampaign || (this.activityData.CampaignId && this.activityData.CampaignId > 0))
    {
      this.form.get('ClientId').disable({ emitEvent: false });

      if ((!this.activityData.Id || this.activityData.Id < 1) && !this.activityData.Property)
      {
        //enable property if is from adding a new activity to a campaign
        this.form.get('Property').enable({ emitEvent: false });
      }
    }
  }


  disableFormSalesControls(disable: boolean)
  {
    this.formSalesControlsDisabled = disable;
    this.form.get('Id').disable({ emitEvent: false });
    this.form.get('ClientId').disable({ emitEvent: false });
    this.form.get('Product').disable({ emitEvent: false });
    //this.form.get('StartDate').disable({ emitEvent: false });
    //this.form.get('EndDate').disable({ emitEvent: false });
    //this.form.get('Recurrence').disable({emitEvent:false});
    this.form.get('ProductCategories').disable();
    this.form.get('ActivityStationInfos').disable({ emitEvent: false });
    this.form.get('Stations').disable();
    this.form.get('CreditLineRotationTypeId').disable({ emitEvent: false });
    this.form.get('CreditLineTypeId').disable({ emitEvent: false });
    this.form.get('CreditLineNotes').disable({ emitEvent: false });
    //this.form.get('CreditLine').disable({emitEvent:false});
    this.form.get('CreditLineDueDate').disable({ emitEvent: false });
    this.form.get('ClientExecUserId').disable({ emitEvent: false });
    this.form.get('ClientSupportUserId').disable({ emitEvent: false });
    this.form.get('BriefManagerUserId').disable({ emitEvent: false });
    this.form.get('WebsiteUrl').disable({ emitEvent: false });
    this.form.get('IsGuaranteed').disable({ emitEvent: false });
    this.form.get('IsUnConfirmedSold').disable({ emitEvent: false });
    this.form.get('IsSold').disable({ emitEvent: false });
    this.form.get('OnHold').disable({ emitEvent: false });
    this.form.get('MechanicType').disable({ emitEvent: false });
    this.form.get('MechanicDescription').disable({ emitEvent: false });
    this.form.get('BookingId').disable({ emitEvent: false });
    // this.form.get('IsConfidential').disable({emitEvent:false});

    if (!disable && !this.saveInProgress)
    {
      this.form.get('Id').enable({ emitEvent: false });
      this.form.get('ClientId').enable({ emitEvent: false });
      this.form.get('Product').enable({ emitEvent: false });
      //this.form.get('StartDate').enable({ emitEvent: false });

      //if (!this.isOneDayOnlyRecurrenceType)
      //{
      //  this.form.get('EndDate').enable({ emitEvent: false });
      //}

      //this.form.get('Recurrence').enable({emitEvent:false});
      this.form.get('ProductCategories').enable();
      this.form.get('ActivityStationInfos').enable({ emitEvent: false });
      this.form.get('Stations').enable();
      this.form.get('CreditLineRotationTypeId').enable({ emitEvent: false });
      this.form.get('CreditLineTypeId').enable({ emitEvent: false });
      this.form.get('CreditLineNotes').enable({ emitEvent: false });
      this.form.get('CreditLine').enable({ emitEvent: false });
      this.form.get('CreditLineDueDate').enable({ emitEvent: false });
      this.form.get('ClientExecUserId').enable({ emitEvent: false });
      this.form.get('ClientSupportUserId').enable({ emitEvent: false });
      this.form.get('BriefManagerUserId').enable({ emitEvent: false });
      this.form.get('WebsiteUrl').enable({ emitEvent: false });
      this.form.get('IsGuaranteed').enable({ emitEvent: false });
      this.form.get('IsUnConfirmedSold').enable({ emitEvent: false });
      this.form.get('IsSold').enable({ emitEvent: false });
      this.form.get('OnHold').enable({ emitEvent: false });
      this.form.get('MechanicType').enable({ emitEvent: false });

      if (this.isRadioBroadcastPropertyType())
      {
        this.form.get('MechanicDescription').enable({ emitEvent: false });
      }

      this.form.get('BookingId').enable({ emitEvent: false });
      this.form.get('IsConfidential').enable({ emitEvent: false });
    }
  }

  disableFormImplemenationSalesControls(disable: boolean)
  {
    this.formImplemenationControlsDisabled = disable;

    this.form.get('MinorPrize').disable({ emitEvent: false });
    this.form.get('MinorPrizeHowFulFilled').disable({ emitEvent: false });
    this.form.get('MinorPrizeValue').disable({ emitEvent: false });
    this.form.get('MinorPrizeQuantity').disable({ emitEvent: false });
    this.form.get('MajorPrize').disable({ emitEvent: false });
    this.form.get('MajorPrizeHowFulFilled').disable({ emitEvent: false });
    this.form.get('MajorPrizeValue').disable({ emitEvent: false });
    this.form.get('MajorPrizeQuantity').disable({ emitEvent: false });
    this.form.get('MechanicType').disable({ emitEvent: false });
    this.form.get('MechanicDescription').disable({ emitEvent: false });
    this.form.get('NatProjectManagerUserId').disable({ emitEvent: false });
    this.form.get('ProgrammerUserId').disable({ emitEvent: false });
    this.form.get('WebsiteUrl').disable({ emitEvent: false });
    this.form.get('Digital').disable({ emitEvent: false });
    this.form.get('HasVideo').disable({ emitEvent: false });
    this.form.get('HasAudio').disable({ emitEvent: false });
    this.form.get('HasPhotos').disable({ emitEvent: false });
    this.form.get('HasEntryForm').disable({ emitEvent: false });
    this.form.get('LogoUrl').disable({ emitEvent: false });
    this.form.get('ImageUrl').disable({ emitEvent: false });
    this.form.get('CreativeBriefUrl').disable({ emitEvent: false });
    this.form.get('Newsletter').disable({ emitEvent: false });
    this.form.get('DailyUpdate').disable({ emitEvent: false });
    this.form.get('SoldFormUrl').disable({ emitEvent: false });
    this.form.get('FirstDayAirCheckDate').disable({ emitEvent: false });
    this.form.get('FirstDayAirCheckNo').disable({ emitEvent: false });
    this.form.get('ScreenShotsDate').disable({ emitEvent: false });
    this.form.get('ScreenShotsNo').disable({ emitEvent: false });
    this.form.get('FinalPCRDate').disable({ emitEvent: false });
    this.form.get('FinalPCRAirCheck').disable({ emitEvent: false });
    this.form.get('FinalPCRAirPhotos').disable({ emitEvent: false });
    this.form.get('FinalPCRAirVideos').disable({ emitEvent: false });
    this.form.get('CrossBookedWithActivity').disable({ emitEvent: false });
    this.form.get('PreRecorded').disable({ emitEvent: false });
    this.form.get('Exclusive').disable({ emitEvent: false });
    this.form.get('PCRSpecialRequest').disable({ emitEvent: false });
    this.form.get('PhotoBrief').disable({ emitEvent: false });
    this.form.get('VideoBrief').disable({ emitEvent: false });
    this.form.get('Disabled').disable({ emitEvent: false });
    this.form.get('Pre').disable({ emitEvent: false });
    this.form.get('Mid').disable({ emitEvent: false });
    this.form.get('Post').disable({ emitEvent: false });
    this.form.get('ActivityExtensions').disable({ emitEvent: false });
    this.form.get('FurtherDetails').disable({ emitEvent: false });
    this.form.get('SocialBriefDetails').disable({ emitEvent: false });
    this.form.get('BrandToneKeyMessaging').disable({ emitEvent: false });
    this.form.get('IsProductSupplied').disable({ emitEvent: false });
    this.form.get('IsExtensionApproved').disable({ emitEvent: false });

    if (!disable && !this.saveInProgress)
    {
      this.form.get('MinorPrize').enable({ emitEvent: false });
      this.form.get('MinorPrizeHowFulFilled').enable({ emitEvent: false });
      this.form.get('MinorPrizeValue').enable({ emitEvent: false });
      this.form.get('MinorPrizeQuantity').enable({ emitEvent: false });
      this.form.get('MajorPrize').enable({ emitEvent: false });
      this.form.get('MajorPrizeHowFulFilled').enable({ emitEvent: false });
      this.form.get('MajorPrizeValue').enable({ emitEvent: false });
      this.form.get('MajorPrizeQuantity').enable({ emitEvent: false });
      this.form.get('MechanicType').enable({ emitEvent: false });

      if (this.isRadioBroadcastPropertyType())
      {
        this.form.get('MechanicDescription').enable({ emitEvent: false });
      }

      this.form.get('NatProjectManagerUserId').enable({ emitEvent: false });
      this.form.get('ProgrammerUserId').enable({ emitEvent: false });
      this.form.get('WebsiteUrl').enable({ emitEvent: false });
      this.form.get('Digital').enable({ emitEvent: false });
      this.form.get('HasVideo').enable({ emitEvent: false });
      this.form.get('HasAudio').enable({ emitEvent: false });
      this.form.get('HasPhotos').enable({ emitEvent: false });
      this.form.get('HasEntryForm').enable({ emitEvent: false });
      this.form.get('LogoUrl').enable({ emitEvent: false });
      this.form.get('ImageUrl').enable({ emitEvent: false });
      this.form.get('CreativeBriefUrl').enable({ emitEvent: false });
      this.form.get('Newsletter').enable({ emitEvent: false });
      this.form.get('DailyUpdate').enable({ emitEvent: false });
      this.form.get('SoldFormUrl').enable({ emitEvent: false });
      this.form.get('FirstDayAirCheckDate').enable({ emitEvent: false });
      this.form.get('FirstDayAirCheckNo').enable({ emitEvent: false });
      this.form.get('ScreenShotsDate').enable({ emitEvent: false });
      this.form.get('ScreenShotsNo').enable({ emitEvent: false });
      this.form.get('FinalPCRDate').enable({ emitEvent: false });
      this.form.get('FinalPCRAirCheck').enable({ emitEvent: false });
      this.form.get('FinalPCRAirPhotos').enable({ emitEvent: false });
      this.form.get('FinalPCRAirVideos').enable({ emitEvent: false });
      this.form.get('CrossBookedWithActivity').enable({ emitEvent: false });
      this.form.get('PreRecorded').enable({ emitEvent: false });
      this.form.get('Exclusive').enable({ emitEvent: false });
      this.form.get('PCRSpecialRequest').enable({ emitEvent: false });
      this.form.get('PhotoBrief').enable({ emitEvent: false });
      this.form.get('VideoBrief').enable({ emitEvent: false });
      this.form.get('Disabled').enable({ emitEvent: false });
      this.form.get('Pre').enable({ emitEvent: false });
      this.form.get('Mid').enable({ emitEvent: false });
      this.form.get('Post').enable({ emitEvent: false });
      this.form.get('ActivityExtensions').enable({ emitEvent: false });
      this.form.get('FurtherDetails').enable({ emitEvent: false });
      this.form.get('SocialBriefDetails').enable({ emitEvent: false });
      this.form.get('BrandToneKeyMessaging').enable({ emitEvent: false });
      this.form.get('IsProductSupplied').enable({ emitEvent: false });
      this.form.get('IsExtensionApproved').enable({ emitEvent: false });
    }
  }

  disableFormSSIControls(disable: boolean)
  {
    this.formSSIControlsDisabled = disable;

    this.formSSI.get('Id').disable({ emitEvent: false });
    this.formSSI.get('ActivityId').disable({ emitEvent: false });
    this.formSSI.get('ProjectManagerId').disable({ emitEvent: false });
    this.formSSI.get('AdOpsId').disable({ emitEvent: false });
    this.formSSI.get('StationId').disable({ emitEvent: false });
    this.formSSI.get('ActivityName').disable({ emitEvent: false });
    this.formSSI.get('CreditLineKeyNo').disable({ emitEvent: false });
    this.formSSI.get('AirtimeBreakdown').disable({ emitEvent: false });
    this.formSSI.get('Mechanic').disable({ emitEvent: false });
    this.formSSI.get('AdditionalInfo').disable({ emitEvent: false });
    this.formSSI.get('AdditionalInfoUrl').disable({ emitEvent: false });
    this.formSSI.get('ApprovedScriptUrl').disable({ emitEvent: false });
    this.formSSI.get('IsRateCpm').disable({ emitEvent: false });
    this.formSSI.get('Rate').disable({ emitEvent: false });
    this.formSSI.get('MultimediaRecovery').disable({ emitEvent: false });
    this.formSSI.get('Revenue').disable({ emitEvent: false });
    this.formSSI.get('Production').disable({ emitEvent: false });
    this.formSSI.get('StartTime').disable({ emitEvent: false });
    this.formSSI.get('EndTime').disable({ emitEvent: false });
    this.formSSI.get('OnAirTime').disable({ emitEvent: false });
    this.formSSI.get('SignageRequired').disable({ emitEvent: false });
    this.formSSI.get('LocationAddress').disable({ emitEvent: false });
    this.formSSI.get('WhereisUrl').disable({ emitEvent: false });
    this.formSSI.get('OnsiteContactName').disable({ emitEvent: false });
    this.formSSI.get('OnsiteContactTel').disable({ emitEvent: false });
    this.formSSI.get('ParkingInstructions').disable({ emitEvent: false });
    this.formSSI.get('BroadcastingLive').disable({ emitEvent: false });
    this.formSSI.get('TicketingOutlet').disable({ emitEvent: false });
    this.formSSI.get('TicketingAllocation').disable({ emitEvent: false });
    this.formSSI.get('HasOTP').disable({ emitEvent: false });
    this.formSSI.get('IsFreeOfCharge').disable({ emitEvent: false });
    this.formSSI.get('HasPageBackground').disable({ emitEvent: false });
    this.formSSI.get('NoOfPageBackgrounds').disable({ emitEvent: false });
    this.formSSI.get('ActivityCreditInfos').disable({ emitEvent: false });

    if (!disable && !this.saveInProgress)
    {
      this.formSSI.get('Id').enable({ emitEvent: false });
      this.formSSI.get('ActivityId').enable({ emitEvent: false });
      this.formSSI.get('ProjectManagerId').enable({ emitEvent: false });
      this.formSSI.get('AdOpsId').enable({ emitEvent: false });
      this.formSSI.get('StationId').enable({ emitEvent: false });
      this.formSSI.get('ActivityName').enable({ emitEvent: false });
      this.formSSI.get('CreditLineKeyNo').enable({ emitEvent: false });
      this.formSSI.get('AirtimeBreakdown').enable({ emitEvent: false });
      this.formSSI.get('Mechanic').enable({ emitEvent: false });
      this.formSSI.get('AdditionalInfo').enable({ emitEvent: false });
      this.formSSI.get('AdditionalInfoUrl').enable({ emitEvent: false });
      this.formSSI.get('ApprovedScriptUrl').enable({ emitEvent: false });
      this.formSSI.get('IsRateCpm').enable({ emitEvent: false });
      this.formSSI.get('Rate').enable({ emitEvent: false });
      this.formSSI.get('MultimediaRecovery').enable({ emitEvent: false });
      this.formSSI.get('Revenue').enable({ emitEvent: false });
      this.formSSI.get('Production').enable({ emitEvent: false });
      this.formSSI.get('StartTime').enable({ emitEvent: false });
      this.formSSI.get('EndTime').enable({ emitEvent: false });
      this.formSSI.get('OnAirTime').enable({ emitEvent: false });
      this.formSSI.get('SignageRequired').enable({ emitEvent: false });
      this.formSSI.get('LocationAddress').enable({ emitEvent: false });
      this.formSSI.get('WhereisUrl').enable({ emitEvent: false });
      this.formSSI.get('OnsiteContactName').enable({ emitEvent: false });
      this.formSSI.get('OnsiteContactTel').enable({ emitEvent: false });
      this.formSSI.get('ParkingInstructions').enable({ emitEvent: false });
      this.formSSI.get('BroadcastingLive').enable({ emitEvent: false });
      this.formSSI.get('TicketingOutlet').enable({ emitEvent: false });
      this.formSSI.get('TicketingAllocation').enable({ emitEvent: false });
      this.formSSI.get('HasOTP').enable({ emitEvent: false });
      this.formSSI.get('IsFreeOfCharge').enable({ emitEvent: false });
      this.formSSI.get('HasPageBackground').enable({ emitEvent: false });
      this.formSSI.get('NoOfPageBackgrounds').enable({ emitEvent: false });
      this.formSSI.get('ActivityCreditInfos').enable({ emitEvent: false });
    }
  }

  setActivityHeader()
  {
    if (this.currentActivityId < 1 && this.currentProperty())
    {
      this._activityHeader = "New " + this.currentProperty().PropertyName + " Activity";
    }
    else
    {
      const clientId = this.form.get('ClientId').value;
      const client = this.getClientFromClientId(clientId);
      const product: string = this.form.get('Product').value;

      let clientName: string = this.currentActivity().ClientName;

      if (client)
      {
        clientName = client.Name;
      }

      if (clientName && clientName.trim().length > 0)
      {
        this._activityHeader = `${clientName.trim()} ${product ? product.trim() : ''}`;

        if (this._activityHeader && this.activityStatus)
        {
          this._activityHeader = `${this._activityHeader.trim()} - ${this.activityStatus.trim()}`;
        }
      }
    }

    const headertext: ActivityHeaderTextChangeArgs = new ActivityHeaderTextChangeArgs();
    headertext.Id = this.currentActivityId;
    headertext.HeaderText = this._activityHeader;

    this.activityHeaderTextChange.emit(headertext);
  }

  getClientFromClientId(clientId: number): Client
  {
    let client: Client = null;

    if (clientId && clientId > 0)
    {
      client = this.commonDataService.clients().filter(c => c.Id == clientId)[0];
    }

    return client;
  }

  setClientContactInformation(client: Client)
  {
    if (client)
    {
      this.currentClientContact = client.Contact;
      this.currentClientContactEmail = client.ContactEmail;
      this.currentClientContactPhoneNo = client.ContactPhoneNo;
    }
  }

  setUpActivityStatus(status: string)
  {
    this._activityStatusClass = { 'pending-activity-text': true };
    this._heldUntilText = "";

    switch (status)
    {
      case "Pending":
        this._activityStatusClass = { 'pending-activity-text': true };
        this._heldUntilText = "";
        break;
      case "IsSold":
        this._activityStatusClass = { 'sold-activity-text': true };
        this._heldUntilText = "";
        break;
      case "IsGuaranteed":
        this._activityStatusClass = { 'guaranteed-activity-text': true };
        this._heldUntilText = "Held Until Date";
        break;
      case "OnHold":
        this._activityStatusClass = { 'onhold-activity-text': true };
        this._heldUntilText = "Held Until Date";
        break;
      case "IsUnConfirmedSold":
        this._activityStatusClass = { 'unconfirmed-sold-activity-text': true };
        this._heldUntilText = "Held Until Date";
        break;
      default:
        this._activityStatusClass = { 'pending-activity-text': true };
        this._heldUntilText = "";
        break;
    }
  }

  get TotalImpressions(): number
  {
    const startDate: Date = startOfWeek(this.currentActivity().StartDate, { weekStartsOn: this.configurationService.cbSettings().startOfWeek });
    const endDate: Date = endOfWeek(this.currentActivity().EndDate, { weekStartsOn: this.configurationService.cbSettings().startOfWeek });

    let noOfWeeks = differenceInCalendarWeeks(endDate, startDate, { weekStartsOn: this.configurationService.cbSettings().startOfWeek }) + 1;

    if (!noOfWeeks || noOfWeeks < 1)
    {
      noOfWeeks = 1;
    }

    let noOfImpressions = this.currentActivity().Scripts;

    if (!noOfImpressions || noOfImpressions < 1)
    {
      noOfImpressions = 0;
    }

    return noOfWeeks * noOfImpressions;
  }

  get heldUntilText(): any
  {
    return this._heldUntilText;
  }

  get activityStatusClass(): any
  {
    return this._activityStatusClass;
  }

  get activityHeader(): string
  {
    return this._activityHeader;
  }
  set activityHeader(value: string)
  {
    this._activityHeader = value;
  }

  get currentActivityStatus(): ActivityStatus
  {
    return this._currentActivityStatus;
  }
  set currentActivityStatus(value: ActivityStatus)
  {
    this._currentActivityStatus = value;

    this.setStatusValues(this.currentActivity(), this._currentActivityStatus.Value);

    this.setActivityValidators();
  }

  get activityStatus(): string
  {
    this._activityStatus = "Pending";

    if (this.currentActivityStatus)
    {
      this._activityStatus = this.currentActivityStatus.Name;
    }

    return this._activityStatus;
  }
  set activityStatus(value: string)
  {
    this._activityStatus = value;

    this.setStatusValues(this.currentActivity(), this._activityStatus);
  }

  get airtimeCreditsText(): string
  {
    return this._airtimeCreditsText;
  }
  set airtimeCreditsText(value: string)
  {
    this._airtimeCreditsText = value;
  }

  setPropertyStations()
  {
    this.calendarsService.stations().map((station: Station) =>
    {
      station.Disabled = station.Id == this.calendarsService.stationIdOfClickedActivity && !this.activityData.IsCopy && this.isNew();
    });

    this._propertyStations = this.calendarsService.stations().filter((station: Station) => this.currentProperty()?.StationLevels?.some(s => station.Id == s.StationId && this.currentActivity() && s.MaxLevel > this.currentActivity().Level));

    this.setActivityStations();
  }

  setActivityStations()
  {
    this._activityStations = this.propertyStations.filter((station: Station) => this.currentActivity() && this.currentActivity()?.Stations?.some((s: number) => s == station.Id));
  }

  get propertyStations(): Station[]
  {
    return this._propertyStations;
  }

  get activityStations(): Station[]
  {
    return this._activityStations;
  }

  get currentActivityId(): number
  {
    return this._currentActivityId;
  }

  get currentStationId(): number
  {
    return this._currentStationId;
  }
  set currentStationId(value: number)
  {
    if (value && value > 0 && this._currentStationId != value)
    {
      this._projectManagersForStation = [];
      this._adOpsForStation = [];

      this._currentStationId = value;

      this._projectManagersForStation = this.commonDataService.projectManagers().filter((u: ContactTypeUser) => u.StationIds.some(s => s == this._currentStationId));

      this._adOpsForStation = this.commonDataService.adOps().filter((u: ContactTypeUser) => u.StationIds.some(s => s == this._currentStationId));
    }
  }

  get projectManagersForStation(): ContactTypeUser[]
  {
    return this._projectManagersForStation;
  }

  get adOpsForStation(): ContactTypeUser[]
  {
    return this._adOpsForStation;
  }

  isConfidentialChanged(isConfidential: any)
  {
  }

  isBlockerChanged(isConfidential: any)
  {
  }

  isFreeOfChargeChanged(isFOC: any)
  {
    if (isFOC)
    {
      this.formSSI.get('Revenue').disable({ emitEvent: false });

      this.formSSI.controls["Revenue"].patchValue(0, { emitEvent: false });
    }
    else if (!this.activityData.CreatedExternally)
    {
      this.formSSI.get('Revenue').enable({ emitEvent: false });
    }
  }

  activityExtensionChange(event: any)
  {
    this.form.markAsDirty();
  }


  clientExecutivesChange(event: any)
  {
    this.form.controls["ClientExecUserId"].patchValue(this.currentClientExecutive.Id, { emitEvent: false });
    this.setActivityValidators();
    this.form.markAsDirty();
  }

  clientSupportChange(event: any)
  {
    this.form.controls["ClientSupportUserId"].patchValue(this.currentClientSupport.Id, { emitEvent: false });
    this.setActivityValidators();
    this.form.markAsDirty();
  }

  briefManagerChange(event: any)
  {
    this.form.controls["BriefManagerUserId"].patchValue(this.currentBriefManager.Id, { emitEvent: false });
    this.form.markAsDirty();
  }

  projectManagerChange(event: any)
  {
    //Get current ActivityStationInfo filled with any changed values
    const currentAsi: ActivityStationInfo = this.formSSI.value;

    if (currentAsi && this.currentProjectManager)
    {
      // Update current ActivityStationInfo that is bound to the form with project manager from variable which is bound to the control
      currentAsi.ProjectManagerId = this.currentProjectManager.Id;
    }

    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  adOpsChange(event: any)
  {
    //Get current ActivityStationInfo filled with any changed values
    const currentAsi: ActivityStationInfo = this.formSSI.value;

    if (currentAsi)
    {
      // Update current ActivityStationInfo that is bound to the form with ad ops from variable which is bound to the control
      currentAsi.AdOpsId = this.currentAdOps?.Id;
    }

    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  nationalProjectManagerChange(event: any)
  {
    this.form.controls["NatProjectManagerUserId"].patchValue(this.currentNationalProjectManager.Id, { emitEvent: false });
    this.form.markAsDirty();
  }

  programmerChange(event: any)
  {
    this.form.controls["ProgrammerUserId"].patchValue(this.currentProgrammer.Id, { emitEvent: false });
    this.form.markAsDirty();
  }

  airtimeCreditChange(event: any)
  {
    this.form.markAsDirty();
  }


  equalsP(o1: Property, o2: Property)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsMT(o1: MechanicType, o2: MechanicType)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsUser(o1: User, o2: User)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  get isActivityDisabled(): boolean
  {
    return this.form.get('Disabled').value;
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();

    // prevent memory leak when component destroyed
    if (this.isDisabledValueChangesSubscription)
    {
      this.isDisabledValueChangesSubscription.unsubscribe();
    }

    this.unSubscribeToFormSsiValueChanges();

    if (this.formValueChangesSubscription)
    {
      this.formValueChangesSubscription.unsubscribe();
    }

    if (this.dialogCloseSubscription)
    {
      this.dialogCloseSubscription.unsubscribe();
    }

    if (this.recurrenceClosedSubscription)
    {
      this.recurrenceClosedSubscription.unsubscribe();
    }

    if (this.reportClosedSubscription)
    {
      this.reportClosedSubscription.unsubscribe();
    }
  }

  creditLineDueDateChange(event: MatDatepickerInputEvent<Date>)
  {
    this.setActivityValidators();

    this.cdr.detectChanges();
  }

  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.commonService.today > this.currentActivity().StartDate)
    {
      if (!this.isNew())
      {
        //Revert date back to original if not NEW.
        this.form.controls["StartDate"].patchValue(this.activityData.StartDate, { emitEvent: false });
      }
      else
      {
        //Ensure StartDate is not less than today for NEW activities.
        this.form.controls["StartDate"].patchValue(this.commonService.today, { emitEvent: false });
      }
    }

    if (this.currentActivity()?.StartDate > this.currentActivity()?.EndDate)
    {
      //Ensure StartDate is not greater than EndDate.
      this.form.controls["EndDate"].patchValue(this.currentActivity().StartDate, { emitEvent: false });
    }

    if (this.currentActivity()?.Recurrence?.RecurrenceType == this.RecurrenceTypeNone)
    {
      //Reset after save
      this.resetRecurrenceSummary();

      this.currentActivity().Recurrence.PatternStartDate = this.currentActivity().StartDate;
      this.currentActivity().Recurrence.FirstOccurrenceDate = this.currentActivity().StartDate;
    }
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.commonService.today > this.currentActivity().EndDate)
    {
      if (!this.isNew())
      {
        //Revert date back to original if not NEW.
        this.form.controls["EndDate"].patchValue(this.activityData.EndDate, { emitEvent: false });
      }
      else
      {
        //Ensure EndDate is not less than today for NEW activities.
        this.form.controls["EndDate"].patchValue(this.commonService.today, { emitEvent: false });
      }
    }

    if (this.currentActivity()?.StartDate > this.currentActivity()?.EndDate)
    {
      //Ensure StartDate is not greater than EndDate.
      this.form.controls["EndDate"].patchValue(this.currentActivity().StartDate, { emitEvent: false });
    }

    if (this.currentActivity()?.Recurrence?.RecurrenceType == this.RecurrenceTypeNone)
    {
      //Reset after save
      this.resetRecurrenceSummary();

      this.currentActivity().Recurrence.PatternEndDate = this.currentActivity().EndDate;
      this.currentActivity().Recurrence.LastOccurrenceDate = this.currentActivity().EndDate;
    }
  }

  recurrence(): void
  {
    if (!this.dialogIsOpen)
    {
      try
      {
        this.dialogIsOpen = true;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "50rem";
        dialogConfig.hasBackdrop = true;

        let recurrence = this.currentActivity().Recurrence;

        if (!recurrence)
        {
          recurrence = new Recurrence();
          recurrence.PatternStartDate = this.currentActivity().StartDate;
          recurrence.PatternEndDate = this.currentActivity().EndDate;
          recurrence.RecurrenceType = this.RecurrenceTypeNone;
          recurrence.Id = 0;
        }

        recurrence.MaximumMonths = this.configurationService.cbSettings().maxRecurrenceMonths;

        dialogConfig.data = recurrence;

        const dialogRef = this.dialog.open(RecurrenceComponent, dialogConfig);

        this.recurrenceClosedSubscription = dialogRef.afterClosed().subscribe((result: Recurrence) =>
        {
          this.dialogIsOpen = false;

          if (result)
          {
            this.form.controls["Recurrence"].patchValue(result, { emitEvent: false });

            if (this.currentActivity()?.Recurrence?.RecurrenceType == this.RecurrenceTypeNone)
            {
              this.currentActivity().Recurrence.PatternStartDate = this.currentActivity().StartDate;
              this.currentActivity().Recurrence.PatternEndDate = this.currentActivity().EndDate;
              this.currentActivity().Recurrence.FirstOccurrenceDate = this.currentActivity().StartDate;
              this.currentActivity().Recurrence.LastOccurrenceDate = this.currentActivity().EndDate;
            }
            else
            {
              //Set start date to new date that was chosen in recurrence              
              this.form.controls["StartDate"].patchValue(this.currentActivity().Recurrence.PatternStartDate, { emitEvent: false });

              this.currentActivity().Recurrence.FirstOccurrenceDate = this.currentActivity().Recurrence.PatternStartDate;

              //Set end date to new date that was chosen in recurrence              
              this.form.controls["EndDate"].patchValue(this.currentActivity().Recurrence.PatternEndDate, { emitEvent: false });

              this.currentActivity().Recurrence.LastOccurrenceDate = this.currentActivity().Recurrence.PatternEndDate;
            }

            //Reset after save
            this.resetRecurrenceSummary();

            if (result.RecurrenceType == this.RecurrenceTypeNone)
            {
              this.form.get('StartDate').enable({ emitEvent: false });
              this.form.get('EndDate').enable({ emitEvent: false });
            }
            else
            {
              this.form.get('StartDate').disable({ emitEvent: false });
              this.form.get('EndDate').disable({ emitEvent: false });
            }

            this.setIsFrrEligible();

            this.cdr.detectChanges();

            this.form.markAsDirty();
          }
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
  }

  resetRecurrenceSummary()
  {
    //Reset after save
    this._recurrenceSummary = "";
    this._recurrenceTypeName = ""
  }

  onCancelClick(): void
  {
    this.saveInProgress = false;

    if (!this.delegateCancelButtonToParent)
    {
      this.dialogRef.close();
    }
    else
    {
      this.cancelButtonClicked.emit();
    }
  }

  delete(event: any)
  {
    this.saveInProgress = true;

    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    const activity: Activity = this.currentActivity();

    if (activity)
    {
      let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
      confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to delete this activity for ${activity.ClientName == null ? '' : activity.ClientName} ${activity.Product == null ? '' : activity.Product} (${activity.Id})?`;
      confirmDialogRef.componentInstance.confirmTitle = "Delete Activity?";

      this.dialogCloseSubscription = confirmDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) =>
      {
        this.dialogCloseSubscription.unsubscribe();

        if (result)
        {
          activity.CampaignId = this.activityData.CampaignId;

          if (!activity.ProductCategories)
          {
            activity.ProductCategories = [];
          }

          if (activity.IsBlocker == null)
          {
            activity.IsBlocker = false;
          }

          this.deleteActivity.mutate(activity, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.setDisableActivityStateForPermissions(true)

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Deleted", data.Message);
                  }

                  this.commonService.activityChanged.next(this.activityData.Id);

                  this.dialogRef.close(data);
                }
                else
                {
                  if (data.Message)
                  {
                    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                    confirmDialogRef.componentInstance.confirmMessage = data.Message;
                    confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                    confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                    confirmDialogRef.componentInstance.showCancelButton = false;
                    confirmDialogRef.componentInstance.confirmText = "OK";
                  }

                  let errMsg = "Unable to save record.";

                  if (data.ErrorMessage)
                  {
                    errMsg = data.ErrorMessage;
                  }

                  this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                }
              }
            },
            onError: (error: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.setDisableActivityStateForPermissions(true);
              this.loggerService.error(error.message + "  Unable to delete activity " + activity.ClientName + " - " + activity.Product + ".");
            }
          })
        }
        else
        {
          this.saveInProgress = false;
        }

        confirmDialogRef = null;
      });
    }
  }

  resetDropdeadDate()
  {
    if (this.canResetDropDead)
    {
      let dropdead: Date = this.commonService.today;
      dropdead.setDate(dropdead.getDate() + this.daysUntilDropDead);

      dropdead = this.commonService.ensureDateIsNotOnWeekend(dropdead);

      this.form.controls["DropDead"].patchValue(dropdead);

      this.form.markAsDirty();
    }
  }



  setUpStatuses()
  {
    this._statuses = this.calendarsService.statuses;

    let daysOfActivity = 0;

    if (this.currentActivity() && this.currentActivity()?.Recurrence)
    {
      daysOfActivity = differenceInCalendarDays(this.currentActivity().Recurrence.LastOccurrenceDate, this.currentActivity().Recurrence.PatternStartDate);
    }

    if (daysOfActivity < this.configurationService.cbSettings().minDaysForFirstRefusal || !this.canHaveFirstRightOfRefusal || !this.currentActivity()?.CreatedAsFRR)
    {
      this._statuses = this._statuses.filter(s => s.Value != "IsGuaranteed");
    }
  }

  setIsFrrEligible()
  {
    let durationOfActivityInDays = differenceInCalendarDays(this.currentActivity().EndDate, this.currentActivity().StartDate);

    if (this.currentActivity()?.Recurrence && this.currentActivity()?.Recurrence?.RecurrenceType != this.RecurrenceTypeNone)
    {
      durationOfActivityInDays = differenceInCalendarDays(this.currentActivity().Recurrence.PatternEndDate, this.currentActivity().Recurrence.PatternStartDate);
    }

    this._isFrrEligible = this.configurationService.cbSettings().minDaysForFirstRefusal <= durationOfActivityInDays;

  }

  get isFrrEligible(): boolean
  {
    let durationOfActivityInDays = differenceInCalendarDays(this.currentActivity().EndDate, this.currentActivity().StartDate);

    if (this.currentActivity()?.Recurrence && this.currentActivity()?.Recurrence?.RecurrenceType != this.RecurrenceTypeNone)
    {
      durationOfActivityInDays = differenceInCalendarDays(this.currentActivity().Recurrence.PatternEndDate, this.currentActivity().Recurrence.PatternStartDate);
    }

    return this._isFrrEligible;
  }

  get canHaveFirstRightOfRefusal(): boolean
  {
    return this.currentPropertyType() && this.currentPropertyType().CanHaveFirstRightOfRefusal;
  }

  get CanAppearInWhatsOnCalendar(): boolean
  {
    return this.currentPropertyType() && this.currentPropertyType().CanAppearInWhatsOnCalendar;
  }

  get requireClientExec(): boolean
  {
    return this._requireClientExec;
  }

  get requireClientSupport(): boolean
  {
    return this._requireClientSupport;
  }

  get requireBriefManager(): boolean
  {
    return this._requireBriefManager;
  }

  get requireProgrammer(): boolean
  {
    return this._requireProgrammer;
  }

  get statuses(): ActivityStatus[]
  {
    return this._statuses;
  }


  setCurrentProperty(propertyId: number)
  {
    if (propertyId && this.calendarsService.properties() && this.calendarsService.properties().length > 0)
    {
      if (this.calendarsService.properties().some(c => c.Id == propertyId))
      {
        this.currentProperty.set(this.calendarsService.properties().filter(c => c.Id == propertyId)[0]);
      }

      if (this.currentProperty())
      {
        this.setCurrentCategory(this.currentProperty().CategoryId);
        this.setCurrentPropertyType(this.currentProperty().PropertyType?.Id);
        this.setPropertyStations();

        if (this.currentProperty().ProductCategories && this.currentProperty().ProductCategories.length > 0)
        {
          this.currentProperty().ProductCategories.map((pc: ProductCategory) =>
          {
            const index: number = this.activityService.productCategories.findIndex((prodCat: ProductCategory) => pc.Id == prodCat.Id);

            if (index > -1)
            {
              this.activityService.productCategories[index].HasWarning = true;
            }
          });
        }
      }
    }
  }

  setCurrentCategory(categoryId: number)
  {
    if (categoryId && this.calendarsService.categories() && this.calendarsService.categories().length > 0)
    {
      if (this.calendarsService.categories().some(c => c.Id == categoryId))
      {
        this.currentCategory.set(this.calendarsService.categories().filter(c => c.Id == categoryId)[0]);
      }
    }
  }

  setCurrentPropertyType(propertyTypeId: number)
  {
    if (propertyTypeId && this.calendarsService.propertyTypes() && this.calendarsService.propertyTypes().length > 0)
    {
      if (this.calendarsService.propertyTypes().some(c => c.Id == propertyTypeId))
      {
        this.currentPropertyType.set(this.calendarsService.propertyTypes().filter(c => c.Id == propertyTypeId)[0]);

        if (this.currentPropertyType())
        {
          this._airtimeCreditsText = this.currentPropertyType().AirtimeCreditsText;
          this.isRadioBroadcastPropertyType.set(this.currentPropertyType().IsBroadcast);
        }
      }
    }
  }



  setActivityVisibility(activity?: Activity)
  {
    if (!activity)
    {
      activity = this.currentActivity();
    }

    if (activity && this.currentProperty() && this.currentProperty()?.PropertyType)
    {
      switch (this.currentProperty().PropertyType.PropertyTypeName)
      {
        case "Sponsorship":

          break;
        case "Promotion":

          break;
        case "Feature":

          break;
        case "Prize Provider":

          break;
        case "Digital":

          break;
        case "Street":

          break;
        case "Client Promotion":

          break;
        case "Partnership":

          break;
        case "OTP":

          break;
        case "PageBackground":

          break;
        case "Digital Client Promotion":

          break;
        case "Contest Page":

          break;
      }
    }
  }

  setActivityLabelText(activity?: Activity)
  {
    this._rateLabelText = `Rate ${this.commonService.getSymbolForCurrency()}`;
    this._mechanicLabelText = "Mechanic";
    this._additionInfoLabelText = "Context/Extra Info";
    this._majorPrizeLabelText = "Major Prizing";
    this._majorPrizeValueLabelText = `Major Prizing Value (ea) ${this.commonService.getSymbolForCurrency()}`;
    this._majorPrizeQtyLabelText = "Major Prizing Qty (ttl)";
    this._majorPrizeFulfillmentLabelText = "Major Prizing Fulfilment";
    this._minorPrizeLabelText = "Minor Prizing";
    this._minorPrizeValueLabelText = `Minor Prizing Value (ea) ${this.commonService.getSymbolForCurrency()}`;
    this._minorPrizeQtyLabelText = "Minor Prizing Qty (ttl)";
    this._minorPrizeFulfillmentLabelText = "Minor Prizing Fulfilment";
    this._mechanicTypeLabelText = "Digital";
    this._activityNameLabelText = "Promotion Name";
    this._nationalProjectManagerLabelText = "Campaign Delivery Manager";
    this._revenueLabelText = `NT Revenue ${this.commonService.getSymbolForCurrency()}`;
    this._productionLabelText = `Production Budget ${this.commonService.getSymbolForCurrency()}`;
    this._multiMediaRecoveryLabelText = `Digital Revenue ${this.commonService.getSymbolForCurrency()}`;
    this._airtimeBreakdownLabelText = "Airtime Detail";
    this._preRecordedLabelText = "Pre-Recorded";
    this._mechanicTypeDescLabelText = "Digital Description";
    this._parkingInstructionsLabelText = "Parking Instructions";
    this._broadcastingLiveLabelText = "Live Broadcast";
    this._signageLabelText = "Signage";
    this._creditLineBoxLabelText = "Credit Line";
    this._creditLineLabelText = "Credit Line";
    this._creditLineNotesLabelText = "Credit Line Notes";
    this._firstDayAirCheckBoxLabelText = "First Day Air Check";
    this._finalPcrAirCheckLabelText = "Air Checks";

    this._hasVideoLabelText = "Video";
    this._hasAudioLabelText = "Audio";
    this._hasPhotosLabelText = "Photos";
    this._hasEntryFormLabelText = "Entry form";
    this._exclusiveLabelText = "Exclusive";

    if (!activity)
    {
      activity = this.currentActivity();
    }

    if (activity && this.currentProperty() && this.currentProperty()?.PropertyType)
    {
      switch (this.currentProperty().PropertyType.PropertyTypeName)
      {
        case "Sponsorship":
          break;
        case "Promotion":
          break;
        case "Feature":
          this._additionInfoLabelText = "Feature Details";
          this._minorPrizeLabelText = "Prizing";
          this._minorPrizeValueLabelText = `Prizing Value (ea) ${this.commonService.getSymbolForCurrency()}`;
          this._minorPrizeQtyLabelText = "Prizing Qty (ttl)";
          this._minorPrizeFulfillmentLabelText = "Prizing Fulfilment";
          this._activityNameLabelText = "Feature Name";
          break;
        case "Prize Provider":
          this._minorPrizeLabelText = "Prizing";
          this._minorPrizeValueLabelText = `Prizing Value (ea) ${this.commonService.getSymbolForCurrency()}`;
          this._minorPrizeQtyLabelText = "Prizing Qty (ttl)";
          this._minorPrizeFulfillmentLabelText = "Prizing Fulfilment";
          this._mechanicTypeLabelText = "Mechanic Type";
          break;
        case "Digital":
          this._additionInfoLabelText = "Notes";
          this._nationalProjectManagerLabelText = "Inline Delivery Mgr";
          this._revenueLabelText = `Advertising Revenue ${this.commonService.getSymbolForCurrency()}`;
          this._productionLabelText = `Production Recovery ${this.commonService.getSymbolForCurrency()}`;
          this._multiMediaRecoveryLabelText = `Development Revenue ${this.commonService.getSymbolForCurrency()}`;
          this._airtimeBreakdownLabelText = "Is creative in house or by the client?";
          this._signageLabelText = "Was it sold on page impressions and how many?";
          break;
        case "Street":
          this._mechanicLabelText = "What's happening on site";
          this._majorPrizeLabelText = "Client Prizing Provided";
          this._majorPrizeValueLabelText = `Value of Prizing (ttl) ${this.commonService.getSymbolForCurrency()}`;
          this._majorPrizeQtyLabelText = "Amount of Prizing (ea)";
          this._majorPrizeFulfillmentLabelText = "Prizing Fulfillment Details";
          this._minorPrizeLabelText = "Special Staff Request";
          this._minorPrizeValueLabelText = `Prizing Value req (ttl) ${this.commonService.getSymbolForCurrency()}`;
          this._minorPrizeQtyLabelText = "No of Street Staff";
          this._minorPrizeFulfillmentLabelText = "Any Custom Detail";
          this._activityNameLabelText = "Activity Name";
          this._nationalProjectManagerLabelText = "Campaign Delivery Manager";
          this._airtimeBreakdownLabelText = "Programming Airtime Detail";
          this._mechanicTypeDescLabelText = "Digital Brief";
          this._broadcastingLiveLabelText = "Notify Engineering";
          this._signageLabelText = "Signage and Equipment";
          this._creditLineNotesLabelText = "Credit Line/Cross Notes";
          break;
        case "Client Promotion":
          this._mechanicTypeLabelText = "Mechanic Type";
          this._nationalProjectManagerLabelText = "Campaign Delivery Manager";
          this._airtimeBreakdownLabelText = "Programming Airtime Detail";
          this._preRecordedLabelText = "Programming Airtime";
          this._mechanicTypeDescLabelText = "Mechanic Description";
          break;
        case "Partnership":
          this._mechanicLabelText = "Detailed Promotional Support";
          this._minorPrizeLabelText = "Initiative / Event Name";
          this._activityNameLabelText = "Partnership Name";
          this._nationalProjectManagerLabelText = "Campaign Delivery Manager";
          this._broadcastingLiveLabelText = "Age Restrictions";
          this._signageLabelText = "Event Date(s)";
          this._mechanicTypeDescLabelText = "Initiative / Campaign Summary";
          break;
        case "OTP":
          break;
        case "PageBackground":
          break;
        case "Digital Client Promotion":
          break;
        case "Contest Page":
          break;
        case "Podcast":
          this._revenueLabelText = `Impressions Revenue ${this.commonService.getSymbolForCurrency()}`;
          this._multiMediaRecoveryLabelText = `Talent Fee ${this.commonService.getSymbolForCurrency()}`;
          this._creditLineBoxLabelText = "Scripts";
          this._creditLineLabelText = "Talent Requirements";
          this._creditLineNotesLabelText = "Notes";
          this._firstDayAirCheckBoxLabelText = "First Day Sample";
          this._finalPcrAirCheckLabelText = "";
          break;
      }
    }
  }

  resetValidatorsForControl(vc: ValidatedControl)
  {
    if (vc)
    {
      const control: AbstractControl = vc.Control;

      if (control)
      {
        control.clearValidators();
        control.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  resetActivityValidators()
  {
    if (this.activityService.validatedControls && this.activityService.validatedControls.length > 0)
    {
      this.activityService.validatedControls.map((vc: ValidatedControl) =>
      {
        this.resetValidatorsForControl(vc);
      });

      this.activityService.validatedControls = [];
    }
  }

  setActivityValidators(activity?: Activity, reset: boolean = true)
  {
    if (reset)
    {
      this.resetActivityValidators();
    }

    this.activityService.stationIdsWithInvalidValues = [];

    //this.setControlValidation(this.form, "ClientName", [CommonValidators.requiredTrim], "sales");
    this.setControlValidation(this.form, "ClientId", [Validators.required], "sales");
    //this.setControlValidation(this.form, "Product", [Validators.required], "sales");
    this.setControlValidation(this.form, "Property", [Validators.required], "sales");

    if (!this.currentActivity()?.CanConflictProductCategory)
    {
      this.setControlValidation(this.form, "ProductCategories", [Validators.required, Validators.minLength(1)], "sales");
    }

    this.setControlValidation(this.form, "Stations", [Validators.required, Validators.minLength(1)], "sales");
    this.setControlValidation(this.form, "StartDate", [Validators.required], "sales");
    this.setControlValidation(this.form, "EndDate", [Validators.required], "sales");
    this.setControlValidation(this.form, "CreationDate", [Validators.required], "sales");
    this.setControlValidation(this.form, "DropDead", [Validators.required], "sales");

    if (!activity)
    {
      activity = this.currentActivity();
    }

    if (activity && this.currentProperty() && this.currentProperty()?.PropertyType)
    {
      if (activity.CreditLineDueDate && activity.CreditLineDueDate <= this.commonService.today)
      {
        this.setControlValidation(this.form, "CreditLine", [Validators.required], "sales");
      }

      switch (this.currentProperty().PropertyType.PropertyTypeName)
      {
        case "Sponsorship":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;
              break;
            case "IsUnConfirmedSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              this._requireClientSupport = true;

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");
              break;
            case "IsSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              this._requireClientSupport = true;

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");
              break;
          }
          break;
        case "Promotion":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              break;
            case "IsUnConfirmedSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              //this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MinorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeHowFulFilled", [Validators.required], "imp");

              this.setControlValidation(this.form, "MajorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MajorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MajorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              this.setControlValidation(this.form, "MajorPrizeHowFulFilled", [Validators.required], "imp");

              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");
              break;
            case "IsSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              //this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MinorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeHowFulFilled", [Validators.required], "imp");

              this.setControlValidation(this.form, "MajorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MajorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MajorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              this.setControlValidation(this.form, "MajorPrizeHowFulFilled", [Validators.required], "imp");

              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");
              break;
          }
          break;
        case "Feature":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              break;
            case "IsUnConfirmedSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");
              break;
            case "IsSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");
              break;
          }
          break;
        case "Prize Provider":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");
              break;
            case "IsUnConfirmedSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MinorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              this.setControlValidation(this.form, "MinorPrizeHowFulFilled", [Validators.required], "imp");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");
              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");
              break;
            case "IsSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MinorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              this.setControlValidation(this.form, "MinorPrizeHowFulFilled", [Validators.required], "imp");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");
              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");
              break;
          }
          break;
        case "Digital":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              break;
            case "IsUnConfirmedSold":
              break;
            case "IsSold":
              break;
          }
          break;
        case "Street":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              break;
            case "IsUnConfirmedSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");

              if (!this.formSSI.get('OnAirTime').value)
              {
                this.setControlValidation(this.formSSI, "StartTime", [Validators.required], "ssi");
                this.setControlValidation(this.formSSI, "EndTime", [Validators.required], "ssi");
              }
              else
              {
                this.setControlValidation(this.formSSI, "OnAirTime", [Validators.required], "ssi");
              }

              //this.setControlValidation(this.formSSI, "StartTime", [RequiredIfAnyOfTheseFieldsAreEmptyValidator(["OnAirTime"])], "ssi");
              //this.setControlValidation(this.formSSI, "EndTime", [RequiredIfAnyOfTheseFieldsAreEmptyValidator(["OnAirTime"])], "ssi");
              //this.setControlValidation(this.formSSI, "OnAirTime", [RequiredIfAnyOfTheseFieldsAreEmptyValidator(["StartTime", "EndTime"])], "ssi");

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "OnsiteContactName", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "OnsiteContactTel", [Validators.required], "ssi");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");
              break;
            case "IsSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");

              if (!this.formSSI.get('OnAirTime').value)
              {
                this.setControlValidation(this.formSSI, "StartTime", [Validators.required], "ssi");
                this.setControlValidation(this.formSSI, "EndTime", [Validators.required], "ssi");
              }
              else
              {
                this.setControlValidation(this.formSSI, "OnAirTime", [Validators.required], "ssi");
              }

              //this.setControlValidation(this.formSSI, "StartTime", [TheseFieldsOrThoseFieldsRequiredValidator(["OnAirTime"], ["StartTime", "EndTime"])], "ssi");
              //this.setControlValidation(this.formSSI, "EndTime", [TheseFieldsOrThoseFieldsRequiredValidator(["OnAirTime"], ["StartTime", "EndTime"])], "ssi");
              //this.setControlValidation(this.formSSI, "OnAirTime", [TheseFieldsOrThoseFieldsRequiredValidator(["OnAirTime"], ["StartTime", "EndTime"])], "ssi");

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "OnsiteContactName", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "OnsiteContactTel", [Validators.required], "ssi");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");
              break;
          }
          break;
        case "Client Promotion":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MechanicType", [Validators.required], "imp");

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              break;
            case "IsUnConfirmedSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MechanicType", [Validators.required], "imp");

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              //this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MinorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeHowFulFilled", [Validators.required], "imp");

              this.setControlValidation(this.form, "MajorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MajorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MajorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              this.setControlValidation(this.form, "MajorPrizeHowFulFilled", [Validators.required], "imp");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");

              if (activity.PreRecorded)
              {
                this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");
              }
              break;
            case "IsSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MechanicType", [Validators.required], "imp");

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              //this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MinorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MinorPrizeHowFulFilled", [Validators.required], "imp");

              this.setControlValidation(this.form, "MajorPrize", [Validators.required], "imp");
              //this.setControlValidation(this.form, "MajorPrizeValue", [Validators.required, Validators.min(1)], "imp");
              //this.setControlValidation(this.form, "MajorPrizeQuantity", [Validators.required, Validators.min(1)], "imp");
              this.setControlValidation(this.form, "MajorPrizeHowFulFilled", [Validators.required], "imp");

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.formSSI, "Revenue", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Production", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");

              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");

              if (activity.PreRecorded)
              {
                this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");
              }
              break;
          }
          break;
        case "Partnership":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");  //EventName in Partnership
              break;
            case "IsUnConfirmedSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");  //EventName in Partnership

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "TicketingOutlet", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "LocationAddress", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "SignageRequired", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");
              break;
            case "IsSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              //this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              //this._requireClientSupport = true;

              this.setControlValidation(this.form, "MinorPrize", [Validators.required], "imp");  //EventName in Partnership

              //this.setControlValidation(this.form, "FinalPCRDate", [Validators.required], "imp");
              //this.setControlValidation(this.form, "FinalPCRAirCheck", [Validators.required], "imp");

              //this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              //this._requireProgrammer = true;

              this.setControlValidation(this.formSSI, "ActivityName", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "AirtimeBreakdown", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "TicketingOutlet", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "LocationAddress", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "Mechanic", [Validators.required], "ssi");
              this.setControlValidation(this.formSSI, "SignageRequired", [Validators.required], "ssi");

              this.setControlValidation(this.form, "CreditLineDueDate", [Validators.required], "sales");
              break;
          }
          break;
        case "Podcast":
          switch (this.currentActivityStatus.Value)
          {
            case "Pending":
            case "OnHold":
            case "IsGuaranteed":
            default:
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              this._requireClientSupport = true;

              this.setControlValidation(this.form, "BriefManagerUserId", [Validators.required], "sales");
              this._requireBriefManager = true;

              this.setControlValidation(this.form, "Scripts", [Validators.required], "sales");
              break;
            case "IsUnConfirmedSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              this._requireClientSupport = true;

              this.setControlValidation(this.form, "BriefManagerUserId", [Validators.required], "sales");
              this._requireBriefManager = true;

              this.setControlValidation(this.form, "Scripts", [Validators.required, Validators.min(1)], "sales");

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              this.setControlValidation(this.form, "Product", [Validators.required], "sales");

              //if (this.currentProperty()?.Pre)
              //{
              //  this.setControlValidation(this.form, "Pre", [Validators.required, Validators.min(1)], "sales");
              //}
              //if (this.currentProperty()?.Mid)
              //{
              //  this.setControlValidation(this.form, "Mid", [Validators.required, Validators.min(1)], "sales");
              //}
              //if (this.currentProperty()?.Post)
              //{
              //  this.setControlValidation(this.form, "Post", [Validators.required, Validators.min(1)], "sales");
              //}
              //this.setControlValidation(this.form, "FinalPCRAirVideos", [Validators.required, Validators.min(1)], "sales");
              //this.setControlValidation(this.form, "CreativeSets", [Validators.required, Validators.min(1)], "sales");
              //this.setControlValidation(this.form, "CreditLine", [Validators.required], "sales");

              this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              this._requireProgrammer = true;

              //this.setControlValidation(this.formSSI, "Revenue", [Validators.required, Validators.min(1)], "ssi");
              //this.setControlValidation(this.formSSI, "Production", [Validators.required, Validators.min(1)], "ssi");
              //this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required, Validators.min(1)], "ssi");
              //this.setControlValidation(this.formSSI, "Rate", [Validators.required, Validators.min(1)], "ssi");
              break;
            case "IsSold":
              this.setControlValidation(this.form, "ClientExecUserId", [Validators.required], "sales");
              this._requireClientExec = true;

              this.setControlValidation(this.form, "ClientSupportUserId", [Validators.required], "sales");
              this._requireClientSupport = true;

              this.setControlValidation(this.form, "BriefManagerUserId", [Validators.required], "sales");
              this._requireBriefManager = true;

              this.setControlValidation(this.form, "Scripts", [Validators.required, Validators.min(1)], "sales");

              this.setControlValidation(this.form, "BookingId", [Validators.required], "sales");

              this.setControlValidation(this.form, "Product", [Validators.required], "sales");

              //if (this.currentProperty()?.Pre)
              //{
              //  this.setControlValidation(this.form, "Pre", [Validators.required, Validators.min(1)], "sales");
              //}
              //if (this.currentProperty()?.Mid)
              //{
              //  this.setControlValidation(this.form, "Mid", [Validators.required, Validators.min(1)], "sales");
              //}
              //if (this.currentProperty()?.Post)
              //{
              //  this.setControlValidation(this.form, "Post", [Validators.required, Validators.min(1)], "sales");
              //}
              //this.setControlValidation(this.form, "FinalPCRAirVideos", [Validators.required, Validators.min(1)], "sales");
              //this.setControlValidation(this.form, "CreativeSets", [Validators.required, Validators.min(1)], "sales");
              // this.setControlValidation(this.form, "CreditLine", [Validators.required], "sales");

              this.setControlValidation(this.form, "ProgrammerUserId", [Validators.required], "imp");
              this._requireProgrammer = true;

              //this.setControlValidation(this.formSSI, "Revenue", [Validators.required, Validators.min(1)], "ssi");
              //this.setControlValidation(this.formSSI, "Production", [Validators.required, Validators.min(1)], "ssi");
              //this.setControlValidation(this.formSSI, "MultimediaRecovery", [Validators.required, Validators.min(1)], "ssi");
              //this.setControlValidation(this.formSSI, "Rate", [Validators.required, Validators.min(1)], "ssi");
              break;
          }
          break;
      }
    }
  }

  setControlValidation(form: FormGroup, controlName: string, newValidators: ValidatorFn | ValidatorFn[] | null, tabName: string)
  {
    const control: AbstractControl = form.controls[controlName];

    if (control && !this.activityService.validatedControls.some((c: ValidatedControl) => c.ControlName == controlName && c.TabName == tabName))
    {
      control.setValidators(newValidators);
      control.updateValueAndValidity({ emitEvent: false });
      control.markAsTouched();

      this.activityService.validatedControls.push({ Control: control, ControlName: controlName, TabName: tabName });
    }
  }

  get salesTabValid(): boolean
  {
    let isvalid = true;

    for (let i = 0; i < this.activityService.validatedControls.length; i++)
    {
      const vc: ValidatedControl = this.activityService.validatedControls[i];

      if (vc && vc.TabName == "sales")
      {
        const control: AbstractControl = vc.Control;

        if (control && control.invalid)
        {
          isvalid = false;
          break;
        }
      }
    }

    return isvalid;
  }

  get implementationTabValid(): boolean
  {
    let isvalid = true;

    for (let i = 0; i < this.activityService.validatedControls.length; i++)
    {
      const vc: ValidatedControl = this.activityService.validatedControls[i];

      if (vc && vc.TabName == "imp")
      {
        const control: AbstractControl = vc.Control;

        if (control && control.invalid)
        {
          isvalid = false;
          break;
        }
      }
    }

    return isvalid;
  }

  get stationSpecificInfoTabValid(): boolean
  {
    let isvalid = true;

    for (let i = 0; i < this.activityService.validatedControls.length; i++)
    {
      const vc: ValidatedControl = this.activityService.validatedControls[i];

      if (vc && vc.TabName == "ssi")
      {
        const control: AbstractControl = vc.Control;

        if (control)
        {
          if (this.currentActivity() && this.currentActivity()?.ActivityStationInfos && this.currentActivity()?.ActivityStationInfos.length > 0)
          {
            const originalValue: any = control.value;

            for (let s = 0; s < this.currentActivity().ActivityStationInfos.length; s++)
            {
              const asi: ActivityStationInfo = this.currentActivity().ActivityStationInfos[s];

              //Don't validate the current ones for the current station as they will be validated via FormSSI.
              if (asi && asi.StationId != this.currentStationId)
              {
                //Patch values with values from other stations to see if valid
                control.patchValue(asi[vc.ControlName as keyof typeof asi], { emitEvent: false });

                if (control.invalid && !this.activityService.stationIdsWithInvalidValues.some((id: number) => id == asi.StationId))
                {
                  this.activityService.stationIdsWithInvalidValues.push(asi.StationId);
                }

              }
              else 
              {
                if (!this.formSSI.valid)
                {
                  if (!this.activityService.stationIdsWithInvalidValues.some((id: number) => id == this.currentStationId))
                  {
                    this.activityService.stationIdsWithInvalidValues.push(this.currentStationId);
                  }
                }
                else
                {
                  this.activityService.stationIdsWithInvalidValues = this.activityService.stationIdsWithInvalidValues.filter((id: number) => id != this.currentStationId)
                }
              }
            }

            //Return to original value to test control on form
            control.patchValue(originalValue, { emitEvent: false });
          }

          if (control.invalid)
          {
            isvalid = false;
            break;
          }
        }
      }
    }

    return isvalid;
  }

  stationControlsAllValid(stationId: number): boolean
  {
    return !this.activityService.stationIdsWithInvalidValues
      || this.activityService.stationIdsWithInvalidValues.length == 0
      || !this.activityService.stationIdsWithInvalidValues.includes(stationId);
  }

  setStatusValues(activity: Activity, activityStatusValue: string)
  {
    activity.IsGuaranteed = false;
    activity.IsSold = false;
    activity.IsUnConfirmedSold = false;
    activity.OnHold = false;

    switch (activityStatusValue)
    {
      case "Pending":
        break;
      case "OnHold":
        activity.OnHold = true;
        break;
      case "IsUnConfirmedSold":
        activity.IsUnConfirmedSold = true;
        break;
      case "IsGuaranteed":
        activity.IsGuaranteed = true;
        break;
      case "IsSold":
        activity.IsSold = true;
        break;
    }
  }


  async onSave(): Promise<void>
  {
    try
    {

      this.saveInProgress = true;
      this.cdr.detectChanges();

      this.activityData = this.form.getRawValue();
      const activity: Activity = this.activityData;

      if (activity)
      {
        this.unSubscribeToFormSsiValueChanges();

        //update values for current station before save.
        this.updateFormSSIValuesToFormActivityStationInfos(activity.ActivityStationInfos);

        //remove station info records for stations that are not selected in the activity
        activity.ActivityStationInfos = activity.ActivityStationInfos.filter(asi => activity.Stations.some(s => s == asi.StationId));

        //manually set disabled fields as they don't get set through binding
        activity.Property = this.form.get('Property').value;
        activity.CreationDate = this.form.get('CreationDate').value;
        activity.DropDead = this.form.get('DropDead').value;
        activity.EndDate = this.form.get('EndDate').value;
        activity.OnHoldDate = this.form.get('OnHoldDate').value;

        //Set IsCopy and IsPlusClick to false when saving.
        activity.IsCopy = false;
        activity.IsPlusClick = false;

        //manually set non-form fields as they don't get set through binding
        activity.IsForCampaign = this.activityData.IsForCampaign;
        activity.CampaignId = this.activityData.CampaignId && this.activityData.CampaignId > 0 ? this.activityData.CampaignId : null;
        activity.CreatedExternally = this.activityData.CreatedExternally;

        if (activity.IsBlocker == null)
        {
          activity.IsBlocker = false;
        }

        this.setStatusValues(activity, this.currentActivityStatus.Value);

        if (!activity.IsSold)
        {
          activity.SoldDate = null;

          //In case user makes another save without closing dialog which would reset form values
          //So we need to update the form as control not on form and is changed.
          this.form.controls["SoldDate"].patchValue(null, { emitEvent: false });
        }

        if (activity.EndDate < activity.StartDate)
        {
          activity.EndDate = activity.StartDate;
        }

        //create new recurrence object with default values if no recurrence
        if (!activity.Recurrence)
        {
          activity.Recurrence = new Recurrence();
          activity.Recurrence.DaysOfWeek = 0;
          activity.Recurrence.RecurrenceType = this.RecurrenceTypeNone;
          activity.Recurrence.Occurrences = 0;
          activity.Recurrence.Instance = 1;
          activity.Recurrence.Interval = 1;
          activity.Recurrence.NoEndDate = false;
          activity.Recurrence.EndAfter = false;
          activity.Recurrence.EndBy = true;
          activity.Recurrence.EveryWeekDay = false;

          activity.Recurrence.PatternStartDate = activity.StartDate;
          activity.Recurrence.PatternEndDate = activity.EndDate;
          activity.Recurrence.FirstOccurrenceDate = activity.StartDate;
          activity.Recurrence.LastOccurrenceDate = activity.EndDate;
          activity.Recurrence.DayOfMonth = 0;
          activity.Recurrence.MonthOfYear = 0;//month is zero indexed
          activity.Recurrence.MaximumMonths = this.configurationService.cbSettings().maxRecurrenceMonths;
          activity.Recurrence.OnSpecificDayOfMonth = true;
        }

        if (activity.Recurrence.PatternStartDate < activity.StartDate)
        {
          activity.Recurrence.PatternStartDate = activity.StartDate;
        }

        if (activity.Recurrence.PatternEndDate < activity.Recurrence.PatternStartDate)
        {
          activity.Recurrence.PatternEndDate = activity.Recurrence.PatternStartDate;
        }

        //must convert to Timezone neutral dates format AFTER all date object methods are called.
        activity.Recurrence.PatternStartDate = this.commonService.formatDateForService(activity.Recurrence.PatternStartDate);
        activity.Recurrence.PatternEndDate = this.commonService.formatDateForService(activity.Recurrence.PatternEndDate);
        activity.Recurrence.FirstOccurrenceDate = this.commonService.formatDateForService(activity.Recurrence.FirstOccurrenceDate);
        activity.Recurrence.LastOccurrenceDate = this.commonService.formatDateForService(activity.Recurrence.LastOccurrenceDate);
        activity.DateDueToAdOps = this.commonService.formatDateForService(activity.DateDueToAdOps);
        activity.HostSocialPostedDate = this.commonService.formatDateForService(activity.HostSocialPostedDate);
        activity.EndDate = this.commonService.formatDateForService(activity.EndDate);
        activity.StartDate = this.commonService.formatDateForService(activity.StartDate);
        activity.CreationDate = this.commonService.formatDateForService(activity.CreationDate);
        activity.CreditLineDueDate = this.commonService.formatDateForService(activity.CreditLineDueDate);
        activity.DropDead = this.commonService.formatDateForService(this.commonService.ensureDateIsNotOnWeekend(activity.DropDead));
        activity.FinalPCRDate = this.commonService.formatDateForService(activity.FinalPCRDate);
        activity.FirstDayAirCheckDate = this.commonService.formatDateForService(activity.FirstDayAirCheckDate);
        activity.LastUpdated = this.commonService.formatDateForService(activity.LastUpdated);
        activity.OnHoldDate = this.commonService.formatDateForService(this.commonService.ensureDateIsNotOnWeekend(activity.OnHoldDate));
        activity.ScreenShotsDate = this.commonService.formatDateForService(activity.ScreenShotsDate);

        if (activity.SoldDate)
        {
          //must convert to Timezone neutral dates format AFTER all date object methods are called.
          activity.SoldDate = this.commonService.formatDateForService(activity.SoldDate);
        }

        activity.ActivityStationInfos.map((asi: ActivityStationInfo) =>
        {
          //must convert to Timezone neutral dates format AFTER all date object methods are called.
          asi.EndTime = this.commonService.formatDateForService(asi.EndTime);
          asi.OnAirTime = this.commonService.formatDateForService(asi.OnAirTime);
          asi.StartTime = this.commonService.formatDateForService(asi.StartTime);
        });


        //Return activity to PENDING if disabled.
        if (activity.Disabled)
        {
          activity.OnHold = false;
          activity.OnHoldDate = null;
          activity.IsGuaranteed = false;
          activity.IsUnConfirmedSold = false;
          activity.IsSold = false;
          activity.SoldDate = null;
        }

        if (!activity.ProductCategories && activity.CanConflictProductCategory)
        {
          activity.ProductCategories = [];
        }

        if (!this.isNew() || activity.Id > 0)
        {
          //Disable AFTER values are set in object or they don't come across
          this.disableFormSalesControls(this.saveInProgress);
          this.disableFormImplemenationSalesControls(this.saveInProgress);
          this.disableFormSSIControls(this.saveInProgress);

          this.updateActivity.mutate(activity, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.setDisableActivityStateForPermissions(true);

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (!data.ActivityJson)
                  {
                    const actInfo: ActivityInfo = new ActivityInfo();
                    actInfo.Id = activity.Id;

                    if (activity.ClientName)
                    {
                      actInfo.ClientName = activity.ClientName;
                    }
                    else
                    {
                      const client = this.getClientFromClientId(activity.ClientId);

                      if (client)
                      {
                        actInfo.ClientName = client.Name;
                      }
                    }

                    actInfo.Product = activity.Product;

                    data.ActivityJson = JSON.stringify(actInfo);
                  }

                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Saved", data.Message);
                  }

                  if (this.closeAfterSave)
                  {
                    this.dialogRef.close(data);
                  }
                  else
                  {
                    this.activitySaved.emit(data);

                    this.form.markAsPristine();
                    this.formSSI.markAsPristine();
                    this.form.markAsUntouched();
                    this.formSSI.markAsUntouched();
                  }

                  this.commonService.activityChanged.next(this.activityData.Id);
                }

                else
                {
                  if (data.Message)
                  {
                    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                    confirmDialogRef.componentInstance.confirmMessage = data.Message;
                    confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                    confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                    confirmDialogRef.componentInstance.showCancelButton = false;
                    confirmDialogRef.componentInstance.confirmText = "OK";

                    if (data.CssClassName)
                    {
                      confirmDialogRef.addPanelClass(data.CssClassName);
                    }
                  }

                  else
                  {
                    let errMsg = "Unable to save record.";

                    if (data.ErrorMessage)
                    {
                      errMsg = data.ErrorMessage;
                    }

                    this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                  }
                }
              }

              this.subscribeToFormSsiValueChanges();
            },
            onError: (error: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.subscribeToFormSsiValueChanges();
              this.setDisableActivityStateForPermissions(true);
              this.loggerService.error(error.message + "  Unable to create activity " + activity.ClientName + " - " + activity.Product + ".");
            }
          })
        }
        else
        {
          this.createActivity.mutate(activity, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.setDisableActivityStateForPermissions(true);

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Saved", data.Message);
                  }

                  if (this.closeAfterSave)
                  {
                    this.dialogRef.close(data);
                  }

                  else
                  {
                    this.activitySaved.emit(data);

                    //replaced current activity object with serialised version from the server with all the newly created Ids
                    this.activityData = JSON.parse(data.ActivityJson);
                    this.setupActivity(this.activityData);

                    this.form.markAsPristine();
                    this.formSSI.markAsPristine();
                    this.form.markAsUntouched();
                    this.formSSI.markAsUntouched();
                  }

                  this.commonService.activityChanged.next(this.activityData.Id);
                }

                else
                {
                  if (data.Message)
                  {
                    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                    confirmDialogRef.componentInstance.confirmMessage = data.Message;
                    confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                    confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                    confirmDialogRef.componentInstance.showCancelButton = false;
                    confirmDialogRef.componentInstance.confirmText = "OK";

                    if (data.CssClassName)
                    {
                      confirmDialogRef.addPanelClass(data.CssClassName);
                    }
                  }

                  else
                  {
                    let errMsg = "Unable to save record.";

                    if (data.ErrorMessage)
                    {
                      errMsg = data.ErrorMessage;
                    }

                    this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                  }
                }
              }

              this.subscribeToFormSsiValueChanges();
            },
            onError: (error: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.subscribeToFormSsiValueChanges();
              this.setDisableActivityStateForPermissions(true);
              this.loggerService.error(error.message + "  Unable to create activity " + activity.ClientName + " - " + activity.Product + ".");
            }
          })
        }
      }
      else
      {
        if (this.closeAfterSave)
        {
          this.dialogRef.close(null);
        }
      }
    }
    catch (ex)
    {
      this.saveInProgress = false;
      this.setDisableActivityStateForPermissions(true);
    }
  }

  public handlePropertiesFilter(query: string): void
  {
    this.properties = this.calendarsService.properties();

    const predicate = (item: any) => item.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    this.properties = this.properties.filter(predicate);
  }

  propertyChange(event: any)
  {
    const property: Property = event;

    if (property)
    {
      let activityStationInfos = null;
      let stations = null;
      let propStations: Station[] = null;

      if (property?.StationLevels && property?.StationLevels.length > 0)
      {
        propStations = this.calendarsService.stations().filter((station: Station) => property.StationLevels?.some(s => station.Id == s.StationId && s.MaxLevel > this.currentActivity().Level));

        if (propStations && propStations.length > 0)
        {
          //remove station info records for stations in the activity that are not in the property
          stations = this.currentActivity().Stations.filter((s: any) => propStations.some(ps => ps.Id == s));
          activityStationInfos = this.currentActivity().ActivityStationInfos.filter((asi: any) => propStations.some(ps => ps.Id == asi.StationId));
        }
      }

      if (propStations && propStations.length > 0 && (!stations || stations.length < 1))
      {
        stations.push(propStations[0].Id);
      }

      this.form.controls["Stations"].patchValue(stations);
      this.form.controls["ActivityStationInfos"].patchValue(activityStationInfos, { emitEvent: false });

      this.activityService.productCategories = [];
      this.setCurrentProperty(property.Id);

      this.setStationsSelection(stations);
      this.setActivityLabelText();
      this.setActivityValidators();
      this.setActivityVisibility();
      this.setActivityHeader();

      this.cdr.detectChanges();
    }
  }

  public handleClientsFilter(query: string): void
  {
    this.clients = this.commonDataService.clients();

    const predicate = (item: any) => item.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    this.clients = this.clients.filter(predicate);
  }

  clientChange(value: any): void
  {
    if (value && value > 0)
    {
      const client = this.getClientFromClientId(value);

      if (client)
      {
        this.currentActivity().ClientName = client.Name;

        this.setClientContactInformation(client);
      }

      this.setActivityHeader();
    }

    this.form.markAsDirty();
  }

  clientContactEmailClick(e: any)
  {
    if (this.currentClientContactEmail)
    {
      window.location.href = "mailto:" + this.currentClientContactEmail;
    }
  }

  stationContactEmailClick(e: any)
  {
    if (this.currentStationContactEmail)
    {
      window.location.href = "mailto:" + this.currentStationContactEmail;
    }
  }



  productChange(event: any)
  {
    this.setActivityHeader();
  }

  inAndOutChange(event: MatCheckboxChange)
  {
    if (!event.checked)
    {
      this.form.get('MechanicDescription').disable({ emitEvent: false });
    }
    else
    {
      this.form.get('MechanicDescription').enable({ emitEvent: false });
    }
  }

  updateFormSSIValuesToFormActivityStationInfos(asis: ActivityStationInfo[] = [])
  {
    if (this.formSSI.dirty)
    {
      //Get previous ActivityStationInfo filled with any changed values
      const previousAsi: ActivityStationInfo = this.formSSI.value;

      if (previousAsi)
      {
        if (this.currentProjectManager)
        {
          //Update project manager manually as this control is bound to a variable not form control.
          previousAsi.ProjectManagerId = this.currentProjectManager.Id;
        }

        if (this.currentAdOps)
        {
          //Update ad ops manually as this control is bound to a variable not form control.
          previousAsi.AdOpsId = this.currentAdOps.Id;
        }

        if (!asis || asis.length == 0)
        {
          asis = this.form.get("ActivityStationInfos").value;
        }

        const index = asis.findIndex(a => a.StationId == previousAsi.StationId);

        if (index > -1 && asis.length > index)
        {
          //update values of ActivityStationInfo in array with values from form.
          asis[index] = previousAsi;
        }
      }
    }
  }

  setSelectedStationsDetails(stationId: number)
  {
    const station: Station = this.activityStations.filter((station: Station) => station.Id == stationId)[0];

    if (station)
    {
      this.currentStationContact = station.Contact;
      this.currentStationContactEmail = station.ContactEmail;
      this.currentStationContactPhoneNo = station.ContactPhoneNo;
    }

    const asis: ActivityStationInfo[] = this.form.get("ActivityStationInfos").value;

    this.updateFormSSIValuesToFormActivityStationInfos(asis);

    if (asis && asis.some((f: any) => f.StationId == stationId))
    {
      const asi: ActivityStationInfo = asis.filter((f: any) => f.StationId == stationId)[0];

      if (asi)
      {
        this.currentProjectManager = null;
        this.currentAdOps = null;

        if (this.projectManagersForStation && this.projectManagersForStation.length > 0)
        {
          this.currentProjectManager = this.projectManagersForStation.filter((pm: ContactTypeUser) => pm.Id == asi.ProjectManagerId)[0];
        }

        if (this.adOpsForStation && this.adOpsForStation.length > 0)
        {
          this.currentAdOps = this.adOpsForStation.filter((ao: ContactTypeUser) => ao.Id == asi.AdOpsId)[0];
        }

        this.formSSI.patchValue(asi);
        this.setActivityValidators();

        this.cdr.detectChanges();
      }
    }
  }

  stationChange(event: any)
  {
    const stationId: number = event;

    this.currentStationId = stationId;

    this.setSelectedStationsDetails(this.currentStationId);
  }

  handleActivityStationsChange(event: any)
  {
    this.form.markAsDirty();

    const stationIds: number[] = event;

    this.setStationsSelection(stationIds);
  }

  async setStationsSelection(stationIds: number[])
  {
    //var that = this;

    if (stationIds && stationIds.length > 0)
    {
      let asis: ActivityStationInfo[] = this.form.get("ActivityStationInfos").value;

      if (asis && asis.length > 0)
      {
        //remove any station specific info for any stations that are not selected in the activity's station
        asis = asis.filter((s: ActivityStationInfo) =>
        {
          return stationIds.some((stationId: number) => stationId == s.StationId);
        });
      }

      let property: Property = null;

      if (this.currentProperty() && this.currentProperty().Id > 0)
      {
        if (this.calendarsService.properties() && this.calendarsService.properties().length > 0)
        {
          property = this.calendarsService.properties().filter(p => p.Id == this.currentProperty().Id)[0];
        }

        if (!property)
        {
          property = await this.calendarsService.getProperty(this.currentProperty().Id);
        }
      }

      stationIds.map((stationId: number) =>
      {
        //add new blank station specific info object for station if one doesn't exist.
        if (!asis || !asis.some((ssi: ActivityStationInfo) => stationId == ssi.StationId))
        {
          if (!asis)
          {
            asis = [];
          }

          const asi: ActivityStationInfo = new ActivityStationInfo();
          asi.ActivityId = this.activityData.Id;

          if (property && property.StationSpecificInfo && property.StationSpecificInfo.length > 0 && property.StationSpecificInfo.some(p => p.StationId == stationId))
          {
            const psi: PropertyStationInfo = property.StationSpecificInfo.filter(p => p.StationId == stationId)[0];

            //Set activity station info defaults from property station info values
            if (psi.StationId > 0 && psi)
            {
              asi.ProjectManagerId = psi.ProjectManagerId;
              asi.AdOpsId = psi.AdOpsId;
              asi.StationId = psi.StationId;
              asi.OnAirTime = psi.OnAirTime;
              asi.IsRateCpm = psi.IsRateCpm;
              asi.Rate = psi.Rate;

              asi.ActivityCreditInfos = [];

              psi.PropertyCreditInfos.map(pci =>
              {
                const aci: ActivityCreditInfo = new ActivityCreditInfo();

                aci.ActivityId = this.activityData.Id;
                aci.DayPartId = pci.DayPartId;
                aci.DayPartLabel = pci.DayPartLabel;
                aci.Disabled = pci.Disabled;
                aci.Fri = pci.Fri;
                aci.Mon = pci.Mon;
                aci.Sat = pci.Sat;
                aci.StationId = psi.StationId;
                aci.Sun = pci.Sun;
                aci.Thu = pci.Thu;
                aci.Tue = pci.Tue;
                aci.Wed = pci.Wed;

                asi.ActivityCreditInfos.push(aci);
              });
            }
          }

          asis.push(asi);
        }
      });

      this.form.get("ActivityStationInfos").patchValue(asis, { emitEvent: false });

      this.setActivityStations();

      //set currentStationId to first station if currentStationId is not set or it is not in activityStations
      if (this.currentStationId == null || this.currentStationId < 1 || (this.activityStations && this.activityStations.length > 0 && !this.activityStations.some(s => s.Id == this.currentStationId)))
      {
        this.currentStationId = this.activityStations[0].Id;

        this.setSelectedStationsDetails(this.currentStationId);
      }
    }
  }

  canClientCategoryConflictChange(event: any)
  {
    this.setActivityValidators();

    this.cdr.detectChanges();
  }

  handleProductCategoryChange(event: any)
  {
    this.form.markAsDirty();
  }

  minorPrizeChanged(event: any)
  {
    this.setActivityValidators();

    this.cdr.detectChanges();
  }

  majorPrizeChanged(event: any)
  {
    this.setActivityValidators();

    this.cdr.detectChanges();
  }

  hasPreRecordedChanged(event: any)
  {
    this.setActivityValidators();

    this.cdr.detectChanges();
  }

  onAirTimeChange(event: any)
  {
    this.setActivityValidators();

    this.cdr.detectChanges();
  }

  onStartTimeChange(event: any)
  {
    this.setActivityValidators();

    this.cdr.detectChanges();
  }

  onEndTimeChange(event: any)
  {
    this.setActivityValidators();

    this.cdr.detectChanges();
  }

  heldDateChange(event: Date)
  {
    if (this.currentActivityStatus.Value == "Pending" || this.currentActivityStatus.Value == "IsSold")
    {
      this.form.controls["OnHoldDate"].patchValue(undefined, { emitEvent: false });
    }
    else
    {
      this.form.controls["OnHoldDate"].patchValue(this.commonService.ensureDateIsNotOnWeekend(event), { emitEvent: false });
    }
  }

  duplicateCurrentStationInfo()
  {
    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to duplicate the current ${this.platformNameLabel} specific information across all ${this.platformNameLabel}s?`;
    confirmDialogRef.componentInstance.confirmTitle = `Duplicate ${this.platformNameLabel} specific information?`;

    this.dialogCloseSubscription = confirmDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) =>
    {
      this.dialogCloseSubscription.unsubscribe();

      if (result)
      {
        //Get current ActivityStationInfo filled with any changed values
        const currentAsi: ActivityStationInfo = this.formSSI.value;

        if (currentAsi)
        {
          const asis = this.form.get("ActivityStationInfos").value;

          if (asis && asis.length > 0)
          {
            asis.map((asi: ActivityStationInfo) =>
            {
              if (asi != null)
              {
                asi.ActivityName = currentAsi.ActivityName;
                asi.CreditLineKeyNo = currentAsi.CreditLineKeyNo;
                asi.AdditionalInfo = currentAsi.AdditionalInfo;
                asi.AirtimeBreakdown = currentAsi.AirtimeBreakdown;
                asi.BroadcastingLive = currentAsi.BroadcastingLive;
                asi.EndTime = currentAsi.EndTime;
                asi.HasOTP = currentAsi.HasOTP;
                asi.IsFreeOfCharge = currentAsi.IsFreeOfCharge;
                asi.HasPageBackground = currentAsi.HasPageBackground;
                asi.LocationAddress = currentAsi.LocationAddress;
                asi.Mechanic = currentAsi.Mechanic;
                asi.IsRateCpm = currentAsi.IsRateCpm;
                asi.Rate = currentAsi.Rate;
                asi.MultimediaRecovery = currentAsi.MultimediaRecovery;
                asi.NoOfPageBackgrounds = currentAsi.NoOfPageBackgrounds;
                asi.OnAirTime = currentAsi.OnAirTime;
                asi.OnsiteContactName = currentAsi.OnsiteContactName;
                asi.OnsiteContactTel = currentAsi.OnsiteContactTel;
                asi.ParkingInstructions = currentAsi.ParkingInstructions;
                asi.Production = currentAsi.Production;
                asi.Revenue = currentAsi.Revenue;
                asi.SignageRequired = currentAsi.SignageRequired;
                asi.StartTime = currentAsi.StartTime;
                asi.TicketingAllocation = currentAsi.TicketingAllocation;
                asi.TicketingOutlet = currentAsi.TicketingOutlet;
                asi.WhereisUrl = currentAsi.WhereisUrl;

                asi.ActivityCreditInfos = JSON.parse(JSON.stringify(currentAsi.ActivityCreditInfos));
              }
            });

            this.form.get("ActivityStationInfos").patchValue(asis, { emitEvent: false });

            this.formSSI.markAsDirty();
            this.form.markAsDirty();

            this.setActivityValidators();

            this.cdr.detectChanges();
          }
        }
      }
    });
  }

  handleActivityStatusChange(event: ActivityStatus)
  {
    //check if spot is sold in range but if there is and this spot is sold then it is ok to change as long as meets other criteria
    const canChange = (!this.spotSoldInRange || (this.currentActivityStatus && this.currentActivityStatus.Value == "IsSold")) && (event.Value == "Pending"
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityToHoldStatus) && event.Value == "OnHold")
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityToConfirmedStatus) && event.Value == "IsSold")
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityToSFDStatus) && event.Value == "IsUnConfirmedSold")
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityToGuaranteedStatus) && event.Value == "IsGuaranteed"));

    this.form.get('OnHoldDate').disable({ emitEvent: false });

    if (canChange)
    {
      this.currentActivityStatus = event;

      this.setUpActivityStatus(this.currentActivityStatus.Value);

      this.form.controls["OnHoldDate"].patchValue(undefined, { emitEvent: false });

      if (this.currentActivityStatus.Value == "OnHold" || this.currentActivityStatus.Value == "IsUnConfirmedSold" || this.currentActivityStatus.Value == "IsGuaranteed")
      {
        let onholddate: Date = this.commonService.today;

        switch (this.currentActivityStatus.Value)
        {
          case "OnHold":
            if (this.isRadioBroadcastPropertyType())
            {
              onholddate.setDate(onholddate.getDate() + this.configurationService.cbSettings().onHoldDuration);
            }
            else
            {
              onholddate.setDate(onholddate.getDate() + this.configurationService.cbSettings().podcastOnHoldDuration);
            }

            if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeOnHoldHeldDate))
            {
              this.form.get('OnHoldDate').enable({ emitEvent: false });
            }
            break;
          case "IsUnConfirmedSold":
            onholddate.setDate(onholddate.getDate() + this.configurationService.cbSettings().onUnConfirmedSoldDuration);

            if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeSFDHeldDate))
            {
              this.form.get('OnHoldDate').enable({ emitEvent: false });
            }
            break;
          case "IsGuaranteed":
            onholddate = this.currentActivity().StartDate;

            //FRR expires when the number of days set in the variable before it starts is reached
            onholddate.setDate(onholddate.getDate() - this.configurationService.cbSettings().daysBeforeStartDateFirstRefusalExpires);

            if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeIsGuaranteedHeldDate))
            {
              this.form.get('OnHoldDate').enable({ emitEvent: false });
            }
            break;
        }

        onholddate = this.commonService.ensureDateIsNotOnWeekend(onholddate);

        this.form.controls["OnHoldDate"].patchValue(onholddate, { emitEvent: false });
      }

      //Update these controls manually because there are not controls attached to them
      this.form.controls["IsGuaranteed"].patchValue(this.currentActivityStatus.Value == "IsGuaranteed", { emitEvent: false });
      this.form.controls["IsUnConfirmedSold"].patchValue(this.currentActivityStatus.Value == "IsUnConfirmedSold", { emitEvent: false });
      this.form.controls["IsSold"].patchValue(this.currentActivityStatus.Value == "IsSold", { emitEvent: false });
      this.form.controls["OnHold"].patchValue(this.currentActivityStatus.Value == "OnHold", { emitEvent: false });

      this.form.markAsDirty();

      this.activityData = this.form.getRawValue();

      const status: ActivityStatusChangeArgs = new ActivityStatusChangeArgs();
      status.Id = this.currentActivityId;
      status.Status = this.currentActivityStatus;

      this.statusChange.emit(status);

      this.setActivityHeader();
    }
    else
    {
      //Change it back to original if no permission to change it to this status.
      this.form.controls["ActivityStatus"].patchValue(this.currentActivityStatus, { emitEvent: false });

      if ((this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeOnHoldHeldDate) && this.currentActivityStatus && this.currentActivityStatus.Value == "OnHold")
        || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeSFDHeldDate) && this.currentActivityStatus && this.currentActivityStatus.Value == "IsUnConfirmedSold")
        || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeIsGuaranteedHeldDate) && this.currentActivityStatus && this.currentActivityStatus.Value == "IsGuaranteed"))
      {
        this.form.get('OnHoldDate').enable({ emitEvent: false });
      }
    }
  }

  raiseCopyEvent()
  {
    const ac: ActivityCopy = new ActivityCopy();
    ac.Id = this.activityData.Id;
    ac.isFrr = false;
    ac.Activity = JSON.parse(JSON.stringify(this.currentActivity()));

    this.copyActivity.emit(ac);
  }

  raiseFrrCopyEvent()
  {
    const ac: ActivityCopy = new ActivityCopy();
    ac.Id = this.activityData.Id;
    ac.isFrr = true;
    ac.Activity = JSON.parse(JSON.stringify(this.currentActivity()));

    this.copyActivity.emit(ac);
  }

  get isDigitalMechanicalType(): boolean
  {
    return (!this.currentActivity()?.MechanicType || this.currentActivity()?.MechanicType.MechanicTypeName.toLowerCase() == "digital");
  }

  get isInStoreOrOtherMechanicalType(): boolean
  {
    return (!this.currentActivity()?.MechanicType || this.currentActivity()?.MechanicType.MechanicTypeName.toLowerCase() == "instore" || this.currentActivity()?.MechanicType.MechanicTypeName.toLowerCase() == "other");
  }


  get canChangeStatus(): boolean
  {
    //check if spot is sold in range but if there is and this spot is sold then it is ok to change as long as meets other criteria
    const canChange = (!this.spotSoldInRange || (this.currentActivityStatus && this.currentActivityStatus.Value == "IsSold")) && (this.currentActivityStatus.Value == "Pending"
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityFromHoldStatus) && this.currentActivityStatus.Value == "OnHold")
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityFromConfirmedStatus) && this.currentActivityStatus.Value == "IsSold")
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityFromSFDStatus) && this.currentActivityStatus.Value == "IsUnConfirmedSold")
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityFromGuaranteedStatus) && this.currentActivityStatus.Value == "IsGuaranteed"));

    return canChange;
  }

  get canDeleteActivity(): boolean
  {
    let canDelete = false;

    canDelete = (this.activityStatus == "Pending" && this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteActivity) && this.isAuthorised());

    return canDelete;
  }

  get canAddActivity(): boolean
  {
    let canAdd = false;

    if ((this.authorisationService.hasPermission(this.authorisationService.Permissions.AddBroadcastActivity) && this.isRadioBroadcastPropertyType())
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddNonBroadcastActivity) && !this.isRadioBroadcastPropertyType()))
    {
      canAdd = true;
    }

    return canAdd;
  }

  get canPrintConfirmedForm(): boolean
  {
    let canPrint = false;

    canPrint = this.currentActivityId > 0 && this.activityStatus == "IsSold";

    return canPrint;
  }

  get canResetDropDead(): boolean
  {
    let canReset = false;

    canReset = this.authorisationService.hasPermission(this.authorisationService.Permissions.ResetDropdeadDate) && this.isAuthorised();

    return canReset;
  }

  get notAuthorisedMsg(): string
  {
    return `Not authorised!`;
  }

  get recurrenceTypeName(): string
  {
    if (!this._recurrenceTypeName)
    {
      this._recurrenceTypeName = "None";

      if (this.currentActivity() && this.currentActivity()?.Recurrence)
      {
        switch (this.currentActivity().Recurrence.RecurrenceType)
        {
          case this.RecurrenceTypeDaily:
            this._recurrenceTypeName = "Daily";
            break;
          case this.RecurrenceTypeMonthly:
            this._recurrenceTypeName = "Monthly";
            break;
          case this.RecurrenceTypeNone:
            this._recurrenceTypeName = "None";
            break;
          case this.RecurrenceTypeWeekly:
            this._recurrenceTypeName = "Weekly";
            break;
          case this.RecurrenceTypeYearly:
            this._recurrenceTypeName = "Yearly";
            break;
          default:
            this._recurrenceTypeName = "None";
            break;
        }
      }
    }

    return this._recurrenceTypeName;
  }


  get recurrenceSummary(): string
  {
    if (!this._recurrenceSummary)
    {
      this._recurrenceSummary = "None";

      if (this.currentActivity() && this.currentActivity()?.Recurrence)
      {
        if (this.currentActivity()?.Recurrence?.RecurrenceType == this.RecurrenceTypeNone)
        {
          this._recurrenceSummary = `${this.recurrenceTypeName} | ${this.currentActivity().StartDate.toDateString()} - ${this.currentActivity().EndDate.toDateString()}`;
        }
        else
        {
          if (this.currentActivity()?.Recurrence?.NoEndDate)
          {
            this._recurrenceSummary = `${this.recurrenceTypeName} | ${this.currentActivity().Recurrence.PatternStartDate.toDateString()} - No End Date`;
          }
          else if (this.currentActivity()?.Recurrence?.EndAfter)
          {
            this._recurrenceSummary = `${this.recurrenceTypeName} | ${this.currentActivity().Recurrence.PatternStartDate.toDateString()} - Ends after ${this.currentActivity().Recurrence.Occurrences} occurrences`;
          }
          else
          {
            this._recurrenceSummary = `${this.recurrenceTypeName} | ${this.currentActivity().Recurrence.PatternStartDate.toDateString()} - ${this.currentActivity().Recurrence.PatternEndDate.toDateString()}`;
          }
        }
      }
    }

    return this._recurrenceSummary;
  }

  getFieldErrorMessage(fieldName: string)
  {
    let errmsg = "";

    switch (fieldName.toLowerCase().trim())
    {
      default:
        errmsg = `${fieldName} is a required field.`;
        break
    }

    return errmsg;
  }

  get additionInfoLabelText(): string
  {
    return this._additionInfoLabelText;
  }
  get majorPrizeLabelText(): string
  {
    return this._majorPrizeLabelText;
  }
  get majorPrizeValueLabelText(): string
  {
    return this._majorPrizeValueLabelText;
  }
  get majorPrizeQtyLabelText(): string
  {
    return this._majorPrizeQtyLabelText;
  }
  get majorPrizeFulfillmentLabelText(): string
  {
    return this._majorPrizeFulfillmentLabelText;
  }
  get minorPrizeLabelText(): string
  {
    return this._minorPrizeLabelText;
  }
  get minorPrizeValueLabelText(): string
  {
    return this._minorPrizeValueLabelText;
  }
  get minorPrizeQtyLabelText(): string
  {
    return this._minorPrizeQtyLabelText;
  }
  get minorPrizeFulfillmentLabelText(): string
  {
    return this._minorPrizeFulfillmentLabelText;
  }
  get activityNameLabelText(): string
  {
    return this._activityNameLabelText;
  }
  get rateLabelText(): string
  {
    return this._rateLabelText;
  }
  get revenueLabelText(): string
  {
    return this._revenueLabelText;
  }
  get multiMediaRecoveryLabelText(): string
  {
    return this._multiMediaRecoveryLabelText;
  }
  get mechanicLabelText(): string
  {
    return this._mechanicLabelText;
  }
  get airtimeBreakdownLabelText(): string
  {
    return this._airtimeBreakdownLabelText;
  }
  get signageLabelText(): string
  {
    return this._signageLabelText;
  }
  get hasVideoLabelText(): string
  {
    return this._hasVideoLabelText;
  }
  get hasAudioLabelText(): string
  {
    return this._hasAudioLabelText;
  }
  get hasPhotosLabelText(): string
  {
    return this._hasPhotosLabelText;
  }
  get hasEntryFormLabelText(): string
  {
    return this._hasEntryFormLabelText;
  }
  get exclusiveLabelText(): string
  {
    return this._exclusiveLabelText;
  }
  get finalPcrAirCheckLabelText(): string
  {
    return this._finalPcrAirCheckLabelText;
  }
  get firstDayAirCheckBoxLabelText(): string
  {
    return this._firstDayAirCheckBoxLabelText;
  }
  get creditLineBoxLabelText(): string
  {
    return this._creditLineBoxLabelText;
  }
  get creditLineLabelText(): string
  {
    return this._creditLineLabelText;
  }
  get creditLineNotesLabelText(): string
  {
    return this._creditLineNotesLabelText;
  }
  get productionLabelText(): string
  {
    return this._productionLabelText;
  }
  get mechanicTypeLabelText(): string
  {
    return this._mechanicTypeLabelText;
  }
  get mechanicTypeDescLabelText(): string
  {
    return this._mechanicTypeDescLabelText;
  }
  get preRecordedLabelText(): string
  {
    return this._preRecordedLabelText;
  }
  get parkingInstructionsLabelText(): string
  {
    return this._parkingInstructionsLabelText;
  }
  get broadcastingLiveLabelText(): string
  {
    return this._broadcastingLiveLabelText;
  }
  get nationalProjectManagerLabelText(): string
  {
    return this._nationalProjectManagerLabelText;
  }

  get showSsiTab(): boolean
  {
    let show = true;

    show = this.currentPropertyType()
      && this.currentPropertyType()?.IsOnline == false;

    return show;
  }

  get disableRecurrenceButton(): boolean
  {
    const disable = this.activityData && this.activityData.CreatedExternally;

    return disable;
  }



  trackByStations(index: number, station: Station)
  {
    return station["Id"];
  }

  trackByProperties(index: number, property: Property)
  {
    return property["Id"];
  }






}

