import { Component, Injector, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatPaginatorModule } from "@angular/material/paginator";
import { Subscription } from 'rxjs';
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService, Parameter } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { SaveResult } from "../../../shared/models/result.model";
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { LoggerService } from '../../../shared/services/logger.service';
import { CalendarsService } from "../../calendars/calendars.service";
import { CategoryComponent } from "../../calendars/category/category.component";
import { SearchCategory } from "../search.models";
import { SearchService } from '../search.service';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { QueryClient } from "@tanstack/angular-query-experimental";

@Component({
  selector: 'search-categories',
  templateUrl: './search-categories.component.html',
  styleUrls: ['./search-categories.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [SearchService, MatTableCrudService],
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatPaginatorModule]
})
export class SearchCategoriesComponent extends MatTableCrudComponent<SearchCategory> implements OnInit, OnDestroy
{
  public CategoryNameFilter: any;

  private _isOnline = false;
  private itemDeletedSubscription: Subscription;

  private queryClient = inject(QueryClient);

  constructor(protected injector: Injector,
    private calendarsService: CalendarsService,
    private configurationService: ConfigurationService,
    private searchService: SearchService,
    private commonService: CommonService,
    private loggerService: LoggerService)
  {
    super(injector,
      CategoryComponent,
      CategoryComponent,
      CategoryComponent,
      "CreateCategory",
      "DeleteCategory",
      "GetSearchCategories",
      "UpdateCategory",
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl);

    const params: Parameter[] = [{ name: "isOnline", value: false }];
    this.getParameters = params;

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'CategoryName'];
    this.filteredValues = [
      { Field: "CategoryName", Value: "", Path: "", DataType: FilterDataType.String }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "90%";
    this.dialogConfig.height = "97vh";

    //Set to false so add is only available on the calendars where it can derive Property, Stations, etc.
    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddCategory);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditCategory);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteCategory);
  }

  @Input() get isOnline(): boolean
  {
    return this._isOnline;
  }
  set isOnline(val: boolean)
  {
    this._isOnline = val;

    const params: Parameter[] = [{ name: "isOnline", value: this._isOnline }];
    this.getParameters = params;

    this.refresh();
  }

  ngOnInit(): void
  {
    super.ngOnInit();

    this.itemDeletedSubscription = this.itemDeleted.subscribe((result: any) =>
    {
      if (result && result > 0)
      {
        this.calendarsService.resetHttpCache();
        this.calendarsService.resetCalendarData();
        this.calendarsService.dataResetFromCategorySave = true;
      }
    });

  }

  deleteItem(event: any, category: SearchCategory)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this Category (${category.CategoryName})?`;
    this.deleteHeaderText = "Delete Category?";

    super.deleteItem(event, category);

    this.queryClient.invalidateQueries({
      type: "all", exact: false, predicate: (query: any) =>
      {
        return query.queryKey[0] == "Categories";
      }
    });

    this.queryClient.invalidateQueries({
      type: "all", exact: false, predicate: (query: any) =>
      {
        return query.queryKey[0] == "Calendar";
      }
    });
  }



  addNew(event: any)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (!this.dialogIsOpen)
    {
      try
      {
        this.dialogIsOpen = true;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = 0;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "24rem";
        dialogConfig.hasBackdrop = true;

        const dialogRef = this.dialog.open(CategoryComponent, dialogConfig);
        const dialogRefComponentInstance: any = dialogRef.componentInstance;
        dialogRefComponentInstance['mtCrudService' as keyof typeof dialogRefComponentInstance] = this.mtCrudService;

        this.addCloseSubscription = dialogRef.afterClosed().subscribe(result =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            // After dialog is closed we're doing frontend updates
            // For add we're just pushing a new row inside DataService
            this.mtCrudService.dataChange.value.push(this.mtCrudService.getDialogData());

            this.refreshTable();
            this.refresh();

            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();
            this.calendarsService.dataResetFromCategorySave = true;
          }
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
  }

  editItem(event: any, category: SearchCategory)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (category != null)
    {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "24rem";
      dialogConfig.hasBackdrop = true;

      let dialogRef: any = null;

      try
      {
        dialogConfig.data = category.Id;
        dialogRef = this.dialog.open(CategoryComponent, dialogConfig);

        this.editCloseSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            this.refreshTable();
            this.refresh();

            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();
            this.calendarsService.dataResetFromCategorySave = true;
          }
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
    else
    {
      this.dialogIsOpen = false;
    }
  }

  ngOnDestroy()
  {
    super.ngOnDestroy();

    if (this.addCloseSubscription)
    {
      this.addCloseSubscription.unsubscribe();
    }

    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }

    if (this.itemDeletedSubscription)
    {
      this.itemDeletedSubscription.unsubscribe();
    }
  }








}


