import { Component, ViewEncapsulation, inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from "rxjs";
import { KendoGridEditFormComponent } from '../../shared/components/kendo-grid-edit-form.component';
import { AuthorisationService } from "../../shared/services/authorisation.service";
import { CommonDataService } from "../../shared/services/common-data.service";
import { CommonService } from '../../shared/services/common.service';
import { AdministrationService } from "../administration/administration.service";
import { Campaign } from "./campaigns.models";
import { ButtonModule } from "@progress/kendo-angular-buttons";
import { CampaignEditorComponent } from "./campaign-editor/campaign-editor.component";
import { DialogModule } from "@progress/kendo-angular-dialog";
import { QueryClient } from "@tanstack/angular-query-experimental";
import { ActivityInfo } from "../calendars/calendars.models";




@Component({
  selector: 'campaign-edit-form',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaigns.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [DialogModule, CampaignEditorComponent, ButtonModule]
})

export class CampaignEditComponent extends KendoGridEditFormComponent<Campaign>
{
  private editedRowIndex: number;

  public virtual: any = { itemHeight: 25 };

  private queryClient = inject(QueryClient);

  public constructor(
    public administrationService: AdministrationService,
    private authorisationService: AuthorisationService,
    public commonDataService: CommonDataService,
    public commonService: CommonService)
  {
    super();

    this.editForm = new FormGroup({
      'Id': new FormControl(0),
      'Name': new FormControl(null, Validators.maxLength(100)),
      'Client': new FormControl(null, Validators.required),
      'Product': new FormControl(null, Validators.maxLength(100)),
      'BookingId': new FormControl(null, Validators.maxLength(50)),
      'ActivityName': new FormControl(null, Validators.maxLength(100)),
      'ClientExecUserId': new FormControl(null),
      'ClientSupportUserId': new FormControl(null),
      'BriefManagerUserId': new FormControl(null),
      'NatProjectManagerUserId': new FormControl(null),
      'ProjectManagerId': new FormControl(null),
      'UpdateActivities': new FormControl(false),
      'Disabled': new FormControl(false),
      'SortOrder': new FormControl(0, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])),
      'Activities': new FormControl(null)
    });


  }

  override ngOnInit()
  {




    super.ngOnInit();
  }





  override onCancel(event: any)
  {

    super.onCancel(event);

  }

  override onSave(event: any)
  {
    if (this.editForm.value.UpdateActivities)
    {
      let activities: ActivityInfo[] = this.editForm.value.Activities;

      if (activities && activities.length > 0)
      {
        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            if (query.queryKey[0] == "Activity" && (query.queryKey[1] == null || activities.some(activity => activity.Id == query.queryKey[1]) || query.queryKey[1] == 0))
            {
              return true;
            }

            return false;
          }
        });
      }
    }

    super.onSave(event);
  }



  private closeEditor(grid: any, rowIndex = this.editedRowIndex)
  {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;

  }










  ngOnDestroy()
  {

  }



}
