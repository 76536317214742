import { Component, Injector, inject } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { StationType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';
import { StationTypeEditComponent } from "./station-type-edit.component";
import { MatPaginatorModule } from "@angular/material/paginator";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { QueryClient } from "@tanstack/angular-query-experimental";
import { Subscription } from "rxjs";


@Component({
    templateUrl: './station-types.component.html',
    styleUrls: ['./station-types.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatPaginatorModule]
})
export class StationTypesComponent extends MatTableCrudComponent<StationType>
{
  public StationTypeNameFilter: any;

  private queryClient = inject(QueryClient);

  private onDataFetchedSubscription: Subscription;


  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      StationTypeEditComponent,
      StationTypeEditComponent,
      StationTypeEditComponent,
      "CreateStationType",
      "DeleteStationType",
      "GetStationTypes",
      "UpdateStationType",
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'StationTypeName'];
    this.filteredValues = [{ Field: "StationTypeName", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "24rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStationTypes);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStationTypes);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStationTypes);
  }

  ngOnInit(): void
  {
    super.ngOnInit();

    this.onDataFetchedSubscription = this.mtCrudService.OnDataFetched.subscribe((result: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "StationGroups";
        }
      });
    });
  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, stationtype: StationType)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this platform group (${stationtype.StationTypeName})?`;
    this.deleteHeaderText = "Delete Platform Group?";

    super.deleteItem(event, stationtype);
  }



  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.onDataFetchedSubscription)
    {
      this.onDataFetchedSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }











}

