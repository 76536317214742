import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { NgClass } from "@angular/common";
import { Component, Inject, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTabsModule } from "@angular/material/tabs";
import { QueryClient } from "@tanstack/angular-query-experimental";
import { addBusinessDays, addDays, differenceInCalendarDays, endOfWeek, isAfter } from "date-fns";
import { ActivitiesInfo } from '../../../shared/models/common.models';
import { SaveResult } from '../../../shared/models/result.model';
import { AuthorisationService } from '../../../shared/services/authorisation.service';
import { CommonService } from "../../../shared/services/common.service";
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { LoggerService } from "../../../shared/services/logger.service";
import { Campaign } from "../../campaigns/campaigns.models";
import { ActivityComponent } from "../activity/activity.component";
import { Activity, ActivityCopy, ActivityTab, Recurrence, Station } from '../calendars.models';
import { CalendarsService } from '../calendars.service';
import { ActivitiesService } from './activities.service';

@Component({
  selector: "activities",
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  providers: [ActivitiesService],
  standalone: true,
  imports: [FormsModule, CdkDrag, MatIconModule, CdkDragHandle, MatTabsModule, NgClass, MatCardModule, ActivityComponent, MatButtonModule, MatProgressSpinnerModule]
})
export class ActivitiesComponent
{
  private _spotSold = false;
  private _canAddActivity = false;

  private _activitySaveResults: SaveResult[] = [];

  private queryClient = inject(QueryClient);

  public daysUntilDropDead: number;


  constructor(
    private commonService: CommonService,
    private loggerService: LoggerService,
    public authorisationService: AuthorisationService,
    private dialogRef: MatDialogRef<ActivitiesComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ActivitiesInfo,
    private configurationService: ConfigurationService,
    private calendarsService: CalendarsService,
    public activitiesService: ActivitiesService
  )
  {

  }

  ngOnInit()
  {
    this.activitiesService.activitiesInfo = this.data;
    this.activitiesService.currentUserFullname = `${this.authorisationService.currentuser.FirstName} ${this.authorisationService.currentuser.Surname}`;

    this.activitiesService.calendarActivity.set(this.data.activity);

    if (this.activitiesService.calendarActivity())
    {
      this.activitiesService.activityId.set(this.activitiesService.calendarActivity().Id);
    }

    this.activitiesService.stationIds.set(this.data.stationIds);

    const campaign: Campaign = this.data.campaign;

    if (!campaign && this.data.id != null && this.data.id > 0)
    {
      this.activitiesService.activityId.set(this.data.id);
    }
    else
    {
      if (this.data?.activity && this.data?.stationIds)
      {
        this.activitiesService.activitiesParameters.set({
          startDate: this.commonService.dateToWebApiString(this.data.activity.FirstDateInCalendarRange),
          endDate: this.commonService.dateToWebApiString(this.data.activity.LastDateInCalendarRange),
          stationIds: this.data.stationIds,
          propertyId: this.data.activity.PropertyId,
          level: this.data.activity.Level
        });
      }
      else
      {
        let act: Activity = new Activity();

        if (campaign)
        {
          act.IsForCampaign = true;
          act.CampaignId = campaign.Id;
          act.BookingId = campaign.BookingId;
          act.ClientId = campaign.Client?.Id;
          act.ClientExecUserId = campaign.ClientExecUserId;
          act.ClientSupportUserId = campaign.ClientSupportUserId;
          act.BriefManagerUserId = campaign.BriefManagerUserId;
          act.NatProjectManagerUserId = campaign.NatProjectManagerUserId;
          act.Product = campaign.Product;
        }

        this.activitiesService.activities.push(act);
      }
    }
  }


  public async addTab()
  {
    if (this.canAddActivity)
    {
      const id: number = this.activitiesService.tabs()[0].content.Id;

      const ac: ActivityCopy = new ActivityCopy();
      ac.Id = id;
      ac.isFrr = false;
      ac.isPlusClick = true;

      this.copyActivity(ac);
    }
  }



  get activitySaveResults(): SaveResult[]
  {
    return this._activitySaveResults;
  }
  set activitySaveResults(value: SaveResult[])
  {
    this._activitySaveResults = value;
  }


  get spotSold(): boolean
  {
    if (this.activitiesService.tabs() && this.activitiesService.tabs().length > 0)
    {
      const currentTab: ActivityTab = this.activitiesService.tabs()[this.activitiesService.tabSelectedIndex()];

      if (currentTab && (currentTab.isCopy || currentTab.content.IsCopy))
      {
        this._spotSold = false;  //Maybe need to check if start date/ end date are on dates that have sold activities.
      }
      else
      {
        this._spotSold = this.activitiesService.tabs().some((t: ActivityTab) => t.content.Status == 'IsSold');
      }
    }

    return this._spotSold;
  }

  get canAddActivity(): boolean
  {
    let isBroadcast = true;

    const currentTab: ActivityTab = this.activitiesService.tabs()[this.activitiesService.tabSelectedIndex()];

    if (currentTab)
    {
      const activity: Activity = JSON.parse(JSON.stringify(currentTab.content));

      if (activity && activity.Property && activity.Property.PropertyType)
      {
        isBroadcast = activity.Property.PropertyType.IsBroadcast;
      }
    }

    if ((this.authorisationService.hasPermission(this.authorisationService.Permissions.AddBroadcastActivity) && isBroadcast)
      || (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddNonBroadcastActivity) && !isBroadcast))
    {
      this._canAddActivity = true;
    }

    return this._canAddActivity;
  }


  activityHeaderTextChange(event: any)
  {
    const tab: ActivityTab = this.activitiesService.tabs().find(t => t.content.Id == event.Id);

    if (tab)
    {
      tab.header.headerText = event.HeaderText;
    }
  }

  statusChange(event: any)
  {
    const tab: ActivityTab = this.activitiesService.tabs().find(t => t.content.Id == event.Id);

    if (tab)
    {
      tab.header.headerClass = this.activitiesService.getClassForActivityStatus(event.Status.Value);

      const headerPrefix: string = tab.content.ActivityHeader.substring(tab.content.ActivityHeader.indexOf("-"));
      tab.content.ActivityHeader = tab.content.ActivityHeader.replace(headerPrefix, "- " + event.Status.Name);

      tab.header.headerText = tab.content.ActivityHeader;

      switch (event.Status.Value)
      {
        case "OnHold":
          tab.content.OnHold = true
          tab.content.IsSold = false;
          tab.content.IsGuaranteed = false;
          tab.content.IsUnConfirmedSold = false;
          break;
        case "IsUnConfirmedSold":
          tab.content.OnHold = false
          tab.content.IsSold = false;
          tab.content.IsGuaranteed = false;
          tab.content.IsUnConfirmedSold = true;
          break;
        case "IsGuaranteed":
          tab.content.OnHold = false
          tab.content.IsSold = false;
          tab.content.IsGuaranteed = true;
          tab.content.IsUnConfirmedSold = false;
          break;
        case "IsSold":
          tab.content.OnHold = false
          tab.content.IsSold = true;
          tab.content.IsGuaranteed = false;
          tab.content.IsUnConfirmedSold = false;
          break;
      }

      tab.content.Status = event.Status.Value;
    }
  }

 
  activitySaved(event: any)
  {
    this.activitySaveResults.push(event);
  }


  copyActivity(event: ActivityCopy)
  {
    if (this.canAddActivity)
    {
      let activity: Activity = event.Activity;

      if (!activity)
      {
        const tab: ActivityTab = JSON.parse(JSON.stringify(this.activitiesService.tabs().find(t => t.content.Id == event.Id)));

        if (tab)
        {
          activity = JSON.parse(JSON.stringify(tab.content));
        }
      }

      if (activity)
      {
        let id: number = this.activitiesService.tabs().filter(t => t.content.Id < 1).length;

        if (id > 0)
        {
          id = -id;
        }

        this.activitiesService.activityId.set(id);

        let dateForCopiedActivity = this.commonService.today;

        if (activity.EndDate < activity.StartDate)
        {
          activity.EndDate = activity.StartDate;
        }

        activity.StartDate = this.commonService.parseJsonDate(activity.StartDate);
        activity.EndDate = this.commonService.parseJsonDate(activity.EndDate);

        //create new recurrence object with default values if no recurrence
        if (!activity.Recurrence)
        {
          activity.Recurrence = new Recurrence();
          activity.Recurrence.DaysOfWeek = 0;
          activity.Recurrence.RecurrenceType = this.activitiesService.RecurrenceTypeNone;
          activity.Recurrence.Occurrences = 0;
          activity.Recurrence.Instance = 1;
          activity.Recurrence.Interval = 1;
          activity.Recurrence.NoEndDate = false;
          activity.Recurrence.EndAfter = false;
          activity.Recurrence.EndBy = true;
          activity.Recurrence.EveryWeekDay = false;

          activity.Recurrence.PatternStartDate = activity.StartDate;
          activity.Recurrence.PatternEndDate = activity.EndDate;
          activity.Recurrence.FirstOccurrenceDate = activity.StartDate;
          activity.Recurrence.LastOccurrenceDate = activity.EndDate;
          activity.Recurrence.DayOfMonth = 0;
          activity.Recurrence.MonthOfYear = 0;//month is zero indexed
          activity.Recurrence.MaximumMonths = this.configurationService.cbSettings().maxRecurrenceMonths;
          activity.Recurrence.OnSpecificDayOfMonth = true;
        }

        //reset recurrence id for copy
        activity.Recurrence.Id = 0;

        if (activity.Recurrence.PatternStartDate < activity.StartDate)
        {
          activity.Recurrence.PatternStartDate = activity.StartDate;
        }

        if (activity.Recurrence.PatternEndDate < activity.Recurrence.PatternStartDate)
        {
          activity.Recurrence.PatternEndDate = activity.Recurrence.PatternStartDate;
        }

        if (event.isFrr)
        {
          let durationOfActivityInDays = 0;

          if (activity.Recurrence.RecurrenceType != this.activitiesService.RecurrenceTypeDaily && activity.Recurrence.RecurrenceType != this.activitiesService.RecurrenceTypeWeekly)
          {
            durationOfActivityInDays = differenceInCalendarDays(activity.EndDate, activity.StartDate);
          }

          if (activity.Recurrence.RecurrenceType == this.activitiesService.RecurrenceTypeNone)
          {
            //Start New activity the day after original ends
            activity.StartDate = addDays(activity.EndDate, 1);
          }
          else
          {
            //Start New activity the day after original ends
            activity.StartDate = addDays(activity.Recurrence.PatternEndDate, 1);
          }

          //Make it the same duration as original
          activity.EndDate = addDays(activity.StartDate, durationOfActivityInDays);

          const durationOfRecurrencPatternInDays = differenceInCalendarDays(activity.Recurrence.PatternEndDate, activity.Recurrence.PatternStartDate);

          //Start New recurrence pattern the day after original ends
          activity.Recurrence.PatternStartDate = addDays(activity.Recurrence.PatternEndDate, 1);

          //Make it the same duration as original
          activity.Recurrence.PatternEndDate = addDays(activity.Recurrence.PatternStartDate, durationOfRecurrencPatternInDays);

          activity.Recurrence.FirstOccurrenceDate = activity.Recurrence.PatternStartDate;
          activity.Recurrence.LastOccurrenceDate = activity.Recurrence.PatternEndDate;

          const onholddate: Date = this.commonService.today;

          //FRR expires when the number of days set in the variable before it starts is reached
          onholddate.setDate(onholddate.getDate() - this.configurationService.cbSettings().daysBeforeStartDateFirstRefusalExpires);

          activity.OnHoldDate = onholddate;
        }
        else
        {
          //check if start date is less than today
          if (!isAfter(dateForCopiedActivity, activity.StartDate))
          {
            dateForCopiedActivity = activity.StartDate;
          }

          activity.StartDate = dateForCopiedActivity;
          activity.EndDate = dateForCopiedActivity;

          activity.OnHoldDate = null;
        }

        this.daysUntilDropDead = this.configurationService.cbSettings().daysUntilDropDead;

        activity.Id = id;
        activity.IsCopy = true;
        activity.ActivityHeader = `New ${activity?.Property?.PropertyName} Activity - ${event.isFrr ? "First Right Refusal" : "Pending"}`;
        activity.IsGuaranteed = event.isFrr;
        activity.IsSold = false;
        activity.IsUnConfirmedSold = false;
        activity.OnHold = false;
        activity.CreationDate = this.commonService.today;
        activity.Disabled = false;
        activity.DropDead = this.commonService.today;
        activity.DropDead.setDate(activity.CreationDate.getDate() + this.daysUntilDropDead);
        activity.DropDead = this.commonService.ensureDateIsNotOnWeekend(activity.DropDead);
        activity.Status = event.isFrr ? "IsGuaranteed" : "Pending";

        if (event.isPlusClick)
        {
          //Only select station of clicked station on calendar
          activity.Stations = activity.Stations.filter(s => s == this.calendarsService.stationIdOfClickedActivity);

          activity.BriefManagerUserId = null;
          activity.CanConflictProductCategory = false;
          activity.ClientSupportUserId = null;
          activity.CreatedAsFRR = false;
          activity.CreditLine = "";
          activity.CreditLineDueDate = null;
          activity.Product = "";
          activity.ProductCategories = [];
          activity.Scripts = null;
          activity.CreativeSets = null;
          activity.CreditLineNotes = "";
          activity.CreditLineTypeId = 0;
          activity.CreditLineRotationTypeId = 0;
          activity.MinorPrize = "";
          activity.MinorPrizeHowFulFilled = "";
          activity.MinorPrizeValue = 0;
          activity.MinorPrizeQuantity = 0;
          activity.MajorPrize = "";
          activity.MajorPrizeHowFulFilled = "";
          activity.MajorPrizeValue = 0;
          activity.MajorPrizeQuantity = 0;
          activity.ClientExecUserId = null;
          activity.NatProjectManagerUserId = null;
          activity.ProgrammerUserId = null;
          activity.WebsiteUrl = "";
          activity.IsCopy = true;
          activity.IsPlusClick = true;
          activity.MechanicType = null;
          activity.MechanicDescription = "";
          activity.ShowInWhatsOnCalendar = false;
          activity.HasVideo = false;
          activity.HasAudio = false;
          activity.HasPhotos = false;
          activity.HasEntryForm = false;
          activity.LogoUrl = "";
          activity.ImageUrl = "";
          activity.Newsletter = 0;
          activity.DailyUpdate = "";
          activity.SoldFormUrl = "";
          activity.FirstDayAirCheckDate = null;
          activity.FirstDayAirCheckNo = 0;
          activity.ScreenShotsDate = null;
          activity.ScreenShotsNo = 0;
          activity.FinalPCRDate = null;
          activity.FinalPCRAirCheck = 0;
          activity.FinalPCRAirPhotos = 0;
          activity.FinalPCRAirVideos = 0;
          activity.CrossBookedWithActivity = false;
          activity.PreRecorded = false;
          activity.HostSocialPostedDate = null;
          activity.Exclusive = false;
          activity.PCRSpecialRequest = "";
          activity.PhotoBrief = "";
          activity.VideoBrief = "";
          activity.IsConfidential = false;
          activity.isOnline = false;
        }

        if (activity.ActivityExtensions && activity.ActivityExtensions.length > 0)
        {
          activity.ActivityExtensions.map(ae =>
          {
            ae.ActivityId = 0;
            ae.Id = 0;
          });
        }

        if (activity.ActivityStationInfos && activity.ActivityStationInfos.length > 0)
        {
          activity.ActivityStationInfos.map(asi =>
          {
            if (asi)
            {
              asi.Id = 0;

              asi.ActivityId = id;

              if (event.isPlusClick)
              {
                asi.ProjectManagerId = null;
                asi.AdOpsId = null;
                asi.ActivityName = "";
                asi.CreditLineKeyNo = "";
                asi.AirtimeBreakdown = "";
                asi.Mechanic = "";
                asi.AdditionalInfo = "";
                asi.AdditionalInfoUrl = "";
                asi.ApprovedScriptUrl = "";
                asi.MultimediaRecovery = 0;
                asi.Revenue = 0;
                asi.IsRateCpm = false;
                asi.Rate = 0;
                asi.Production = 0;
                asi.StartTime = null;
                asi.EndTime = null;
                asi.OnAirTime = null;
                asi.SignageRequired = "";
                asi.LocationAddress = "";
                asi.WhereisUrl = "";
                asi.OnsiteContactName = "";
                asi.OnsiteContactTel = "";
                asi.ParkingInstructions = "";
                asi.BroadcastingLive = false;
                asi.TicketingOutlet = "";
                asi.TicketingAllocation = "";
                asi.HasOTP = false;
                asi.HasPageBackground = false;
                asi.NoOfPageBackgrounds = 0;
                asi.Disabled = false;
              }

              if (asi.ActivityCreditInfos && asi.ActivityCreditInfos.length > 0)
              {
                asi.ActivityCreditInfos.map(aci =>
                {
                  aci.ActivityId = id;
                  aci.Id = 0;
                });
              }
            }
          });
        }

        if (!activity?.Property?.PropertyType?.IsBroadcast)
        {
          let station: Station = null;

          if (activity?.Property?.StationLevels?.length > 0)
          {
            station = this.calendarsService.stations().filter((station: Station) => station.Id == activity?.Property?.StationLevels[0].StationId)[0];
          }
          else if (activity.Stations.length > 0)
          {
            station = this.calendarsService.stations().filter((station: Station) => station.Id == activity?.Stations[0])[0];

          }

          if (station)
          {
            this.daysUntilDropDead = station.DaysUntilDropDead;

            activity.DropDead = this.commonService.today;
            activity.DropDead.setDate(activity.CreationDate.getDate() + this.daysUntilDropDead);
            activity.DropDead = this.commonService.ensureDateIsNotOnWeekend(activity.DropDead);
          }

          activity.IsConfidential = true;

          activity.EndDate = endOfWeek(activity.StartDate, { weekStartsOn: this.configurationService.cbSettings().startOfWeek });

          activity.DateDueToAdOps = addBusinessDays(activity.StartDate, this.configurationService.cbSettings().defaultDaysFromStartForDueToAdOps);
        }

        this.activitiesService.addActivity(activity);
        this.activitiesService.setTabSelectedIndex();
      }
    }
  }




  cancelButtonClicked(event: any)
  {
    let result: any = null;

    if (this.activitySaveResults != null && this.activitySaveResults.length > 0 && this.activitySaveResults.some(r => r.IsSuccessful))
    {
      result = this.activitySaveResults.filter(r => r.IsSuccessful)[0];
    }

    this.dialogRef.close(result);
  }



  ngOnDestroy()
  {
    this.activitiesService.activitiesParameters.set(null);
    this.activitiesService.activitiesInfo = null;
    this.activitiesService.calendarActivity.set(null);
    this.activitiesService.activityId.set(0);
    this.activitiesService.stationIds.set([]);
    this.activitiesService.activities = [];

    this.queryClient.removeQueries({
      type: "all", exact: false, predicate: (query: any) =>
      {
        if (query.queryKey[0] == "Activities" && query.queryKey[1] == null)
        {
          return true;
        }

        if (query.queryKey[0] == "Activity" && (query.queryKey[1] == null || query.queryKey[1] < 1))
        {
          return true;
        }

        return false;
      }
    });
  }




}


