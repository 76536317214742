import { registerLocaleData } from '@angular/common';
import localeAU from '@angular/common/locales/en-AU';
import localeGB from '@angular/common/locales/en-GB';
import localeUS from '@angular/common/locales/es-US';
import { Inject, Injectable, LOCALE_ID, OnDestroy, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, Event as NavigationEvent, NavigationStart, Route, Router } from '@angular/router';
import { IntlService } from '@progress/kendo-angular-intl';
import { injectQuery, QueryClient } from '@tanstack/angular-query-experimental';
import { Subscription, lastValueFrom } from 'rxjs';
import { DashboardData } from '../../shared/components/dashboard/dashboard.models';
import { DashboardService } from '../../shared/components/dashboard/dashboard.service';
import { GroupType, User } from '../../shared/models/common.models';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AuthorisationService } from '../../shared/services/authorisation.service';
import { CommonDataService } from '../../shared/services/common-data.service';
import { CommonService } from '../../shared/services/common.service';
import { CbSettings, ConfigurationService } from '../../shared/services/configuration.service';
import { CustomKendoIntlService } from '../../shared/services/custom-kendo-intl.service';
import { ResolveDataService } from '../../shared/services/resolve-data.service';
import { AdministrationService } from '../administration/administration.service';
import { CalendarsService } from '../calendars/calendars.service';
import { DiaryService } from '../diary/diary.service';
import { AppConfig } from './app.config';
import { ClientRoute, ClientRoutes, RouteCategory, appRoutes } from './app.routing';

export const CURRENT_LOCALE = 'current-locale';
export const CURRENT_LOCALE_NAME = 'current-locale-name';

@Injectable({ providedIn: 'root' })

export class AppService implements OnDestroy
{
  public routes: ClientRoutes;
  public routeCategories: RouteCategory[] = [];

  private userPermissionsChangedSubscription: Subscription;
  private routerEventsSubscription: Subscription;

  private queryClient = inject(QueryClient);

  public resolveDataService = inject(ResolveDataService);

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    private router: Router,
    private commonService: CommonService,
    private commonDataService: CommonDataService,
    private administrationService: AdministrationService,
    private authenticationService: AuthenticationService,
    private authorisationService: AuthorisationService,
    private appConfig: AppConfig,
    private configurationService: ConfigurationService,
    private calendarsService: CalendarsService,
    private dashboardService: DashboardService,
    private diaryService: DiaryService,
    public intlService: IntlService,
    private titleService: Title
  )
  {
    this.userPermissionsChangedSubscription = this.authenticationService.userPermissionsChange.subscribe(() =>
    {
      this.setUpRoutes();
    });

    if (this.commonService.isLoggedIn())
    {
      this.setUpRoutes();
    }
  }

  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.routerEventsSubscription)
    {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.userPermissionsChangedSubscription)
    {
      this.userPermissionsChangedSubscription.unsubscribe();
    }
  }

  public loadApplication(): Promise<any>
  {
    return this.appConfig.load()
      .then((res: CbSettings) =>        
      {
        this.configurationService.cbSettings.set(res);

        if (res)
        {
          //culture from app.settings
          let culture: string = res.systemLocale;

          if (!culture)
          {
            //get culture from client locale if app.setting locale is empty
            if (navigator)
            {
              if (navigator.language)
              {
                culture = navigator.language;
              }
              else if (navigator.languages && navigator.languages.length > 0)
              {
                culture = navigator.languages[0];
              }
            }
          }

          switch (culture)
          {
            case 'en-AU':
              registerLocaleData(localeAU);
              localStorage.setItem(CURRENT_LOCALE, JSON.stringify(localeAU));
              break;
            case 'en-GB':
              registerLocaleData(localeGB);
              localStorage.setItem(CURRENT_LOCALE, JSON.stringify(localeGB));
              break;
            case 'en-US':
              registerLocaleData(localeUS);
              localStorage.setItem(CURRENT_LOCALE, JSON.stringify(localeUS));
              break;
          }

          localStorage.setItem(CURRENT_LOCALE_NAME, culture);

          this.localeId = culture;

          //set locale for Kendo controls
          (<CustomKendoIntlService>this.intlService).localeId = culture;
        }

        this.authenticationService.resetAuthentication();

        this.authenticationService.setupUserManager();
      })
      .catch((err: any) =>
      {
        // this.loggerService.error(err);
      });
  }



  subscribeToHubEvents(currentUserId: number, homeStationId: number)
  {
    if (currentUserId && homeStationId)
    {
      this.commonService.appSignalRService.hubConnection.on('ReceiveCalendarHtml', (data: any) =>
      {
        this.calendarsService.resetHttpCache();

        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "Calendar";
          }
        });
      });

      this.commonService.appSignalRService.hubConnection.on('ReceiveDashboardData', (data: DashboardData) =>
      {
        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "DashboardData";
          }
        });
      });

      this.commonService.appSignalRService.hubConnection.on('ReceiveRefreshDashboardDataMessage', (data: any) =>
      {
        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "DashboardData";
          }
        });
      });
    }
  }


  unsubscribeFromHubEvents()
  {
    if (this.commonService && this.commonService.appSignalRService && this.commonService.appSignalRService.hubConnection)
    {
      //if (this.configurationService.cbSettings().subscribeToCalendarHubEvents)
      //{
      this.commonService.appSignalRService.hubConnection.off('ReceiveCalendarHtml');
      //}

      //if (this.configurationService.cbSettings().subscribeToDashboardHubEvents)
      //{
      this.commonService.appSignalRService.hubConnection.off('ReceiveDashboardData');
      this.commonService.appSignalRService.hubConnection.off('ReceiveRefreshDashboardDataMessage');
      //}
    }
  }

  setPropertyTypeIdsFromStorage(): boolean
  {
    const selectedPropertyTypeIds = JSON.parse(localStorage.getItem("selectedPropertyTypeIds"));

    if (selectedPropertyTypeIds && Array.isArray(selectedPropertyTypeIds) && selectedPropertyTypeIds.length > 0)
    {
      this.calendarsService.selectedPropertyTypeIds.set(selectedPropertyTypeIds);

      return true;
    }

    return false;
  }

  setDashboardTeamViewFromStorage()
  {
    let teamView = JSON.parse(localStorage.getItem("teamView"));

    if (!teamView)
    {
      teamView = false;
    }

    this.dashboardService.teamView.set(teamView);
  }

  setCategoryIdsFromStorage(): boolean
  {
    const selectedCategoryIds = JSON.parse(localStorage.getItem("selectedCategoryIds"));

    if (selectedCategoryIds && Array.isArray(selectedCategoryIds) && selectedCategoryIds.length > 0)
    {
      this.calendarsService.selectedCategoryIds = selectedCategoryIds;

      return true;
    }

    return false;
  }


  setYearFromStorage(): boolean
  {
    const selectedYear = JSON.parse(localStorage.getItem("selectedYear"));

    if (selectedYear)
    {
      this.calendarsService.selectedYear = selectedYear;

      return true;
    }

    return false;
  }


  setCalendarFilterFromStorage(): boolean
  {
    let hasParametersSaved = false;

    if (this.setPropertyTypeIdsFromStorage())
    {
      hasParametersSaved = true;
    }

    if (this.setCategoryIdsFromStorage())
    {
      hasParametersSaved = true;
    }

    if (this.setYearFromStorage())
    {
      hasParametersSaved = true;
    }


    return hasParametersSaved;
  }

  setCalendarDefaults(currentUser: User)
  {
    if (currentUser != null && currentUser.Id != null && currentUser.Id > 0)
    {
      this.commonService.appSignalRService.run('AddToGroupAsync', "home", GroupType.Navigation);

      if (!this.calendarsService.calendarInformation())
      {
        this.calendarsService.weeks();
        this.calendarsService.stationGroups();
        this.calendarsService.stations();
        this.calendarsService.properties();
        this.calendarsService.categories();
        this.calendarsService.productCategories();
        this.calendarsService.mechanicTypes();
        this.calendarsService.propertyTypes();

        this.commonDataService.adOps();
        this.commonDataService.briefManagers();
        this.commonDataService.clients();
        this.commonDataService.clientExecutives();
        this.commonDataService.clientSupport();
        this.commonDataService.dayparts();
        this.commonDataService.nationalProjectManagers();
        this.commonDataService.programmers();
        this.commonDataService.projectManagers();

        this.diaryService.eventCategories();
        this.administrationService.platformTypes();

        this.setYearFromStorage();
        this.setPropertyTypeIdsFromStorage();
        this.setCategoryIdsFromStorage();
        this.setDashboardTeamViewFromStorage();

        //Prefetch calendar data before GetClients which takes a long time
        if (this.commonService.isLoggedIn()
          && this.configurationService.cbSettings().calendarServiceUrl.length > 0
          && !!this.calendarsService.calendarParameters()?.stationIds
          && this.calendarsService.calendarParameters().stationIds.length > 0)
        {
          this.queryClient.prefetchQuery({
            queryKey: ["Calendar", this.calendarsService.calendarParameters()],
            queryFn: () => this.calendarsService.getEventDaysAndCalendarHtml(this.calendarsService.calendarParameters()),
            //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
          });
        }
      }
    }
    else
    {
      this.router.navigate(['/unauthorised']);
    }
  }

  private setUpRoutes()
  {
    this.routeCategories = [];

    this.routes = JSON.parse(JSON.stringify(appRoutes));

    if (this.routes && this.routes.length > 0)
    {
      const routesToRemove: ClientRoute[] = [];

      //loop to get unauthorised routes
      for (const route of this.routes)
      {
        let hasPermissionForRoute = false;

        switch (route.path.replace('/', ''))
        {
          case 'addcampaign':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddCampaigns);
            break;
          case 'editcampaign':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditCampaigns);
            break;
          case 'campaigns':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteCampaigns)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.AddCampaigns)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditCampaigns)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCampaigns);
            break;
          case 'calendar':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewStationCalendar);
            break;
          case 'diary':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewWhatsOnEvent)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewWhatsOnEvent)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.AddWhatsOnEvent)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditWhatsOnEvent)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteWhatsOnEvent);
            break;
          case 'search':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.Search);
            break;
          case 'toolscopyactivityinfo':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanCopyActivityInformation);
            break;
          case 'toolscopycreditlines':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanCopyCreditLines);
            break;
          case 'toolscopypropertyinfo':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanCopyPropertyInformation);
            break;
          case 'toolsreplacecontacts':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanReplaceActivityContacts);
            break;
          case 'audit':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.Audit);
            break;
          case 'reports':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivitiesSummaryReport)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewRevenueReport)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewDigitalReport)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCreditLineReport)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCreditReport);
            break;
          case 'contacttypes':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddContactTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteContactTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditContactTypes);
            break;
          case 'controlpanel':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanUseControlPanel);
            break;
          case 'eventcategories':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddEventCategories)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteEventCategories)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditEventCategories);
            break;
          case 'extensions':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddExtensions)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteExtensions)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditExtensions);
            break;
          case 'clients':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddClients)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteClients)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditClients);
            break;
          case 'departments':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddDepartments)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteDepartments)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDepartments);
            break;
          case 'dayparts':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddDayParts)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteDayParts)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDayParts);
            break;
          case 'jobs':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddJobs)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteJobs)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditJobs);
            break;
          case 'mechanictypes':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddMechanicTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteMechanicTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditMechanicTypes);
            break;
          case 'onlinedependencytypes':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddDigitalDependencyTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteDigitalDependencyTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDigitalDependencyTypes);
            break;
          case 'productcategories':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProductCategories)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteProductCategories)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProductCategories);
            break;
          case 'propertytypes':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddPropertyTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeletePropertyTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditPropertyTypes);
            break;
          case 'roles':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddRoles)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteRoles)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditRoles);
            break;
          case 'platforms':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStations)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStations)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStations);
            break;
          case 'platformgroups':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStationTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStationTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStationTypes);
            break;
          case 'notifygroupmembers':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStationNotificationGroups)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStationNotificationGroups)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStationNotificationGroups);
            break;
          case 'notifygroups':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddNotificationGroups)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteNotificationGroups)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditNotificationGroups);
            break;
          case 'users':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteUsers)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditUsers);
            break;
          case 'currentUsers':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCurrentUsers);
            break;
          case 'login':
            hasPermissionForRoute = true;
            break;
          case 'register':
            hasPermissionForRoute = true;
            break;
          case 'home':
            hasPermissionForRoute = true;
            break;
          case 'signin-callback':
            hasPermissionForRoute = true;
            break;
          case 'signout-callback':
            hasPermissionForRoute = true;
            break;
          case 'unauthorised':
            hasPermissionForRoute = true;
            break;
          default:
            hasPermissionForRoute = false;
            break;
        }

        if (!hasPermissionForRoute)
        {
          // role not authorised
          routesToRemove.push(route);
        }
      }

      for (const route of routesToRemove)
      {
        //remove unauthorised routes.
        this.routes = this.routes.filter((r: Route) => r.path != route.path);
      }

      //loop to get categories after the unauthorised routes have been removed so categories with no authorised routes are not added
      for (const route of this.routes)
      {
        const rc: RouteCategory = new RouteCategory();
        rc.CategoryName = route.category;
        rc.Path = route.path;
        rc.Text = route.text;
        rc.RoutesInCategory = this.routes.filter(r => r.category == route.category).length;

        if (!this.routeCategories.some(r => r.CategoryName == route.category) && route.visible)
        {
          this.routeCategories.push(rc);
        }
      }

      if (this.routes != null && !this.routerEventsSubscription)
      {
        this.routerEventsSubscription = this.router.events.subscribe((event: NavigationEvent) =>
        {
          if (event instanceof NavigationStart)
          {
            this.commonService.isNavigating = true;
            this.commonService.browserRefresh = !this.router.navigated || (this.commonService.previouspage != null && this.commonService.currentpage != null && this.commonService.previouspage == this.commonService.currentpage);
            this.commonService.previouspage = this.commonService.currentpage;
          }

          if (event instanceof NavigationEnd)
          {
            this.commonService.isNavigating = false;

            if (event && event.urlAfterRedirects)
            {
              this.commonService.currentpage = event.urlAfterRedirects;

              const clientRoute: any = this.routes.filter((route) =>
              {
                return route.path === this.commonService.currentpage.replace('/', '');
              })[0];

              if (clientRoute)
              {
                this.commonService.showPageHeader = clientRoute.showPageHeader;

                this.commonService.currentpagecategory = clientRoute.category;

                this.commonService.currentpagetitle = clientRoute.text;

                let title: string = "";

                if (this.commonService.currentpagetitle)
                {
                  title = ` - ${this.commonService.currentpagetitle}`;
                }

                this.titleService.setTitle(`Campaign Boss${title}`);
              }
            }
          }

          if (event instanceof NavigationCancel)
          {
            this.commonService.isNavigating = false;

            this.router.navigate(['/']);
          }

          if (event instanceof NavigationError)
          {
            this.commonService.isNavigating = false;
          }
        });
      }
    }



















  }





































}
