import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { Component, Injector, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { fileExcelIcon } from "@progress/kendo-svg-icons";
import { injectMutation, QueryClient } from '@tanstack/angular-query-experimental';
import { Subscription, debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';
import { CheckBoxFilterComponent } from '../../../shared/components/kendo-checkbox-filter.component';
import { KendoGridComponent } from '../../../shared/components/kendo-grid.component';
import { NumericTextBoxFilterComponent } from '../../../shared/components/kendo-numerictextbox-filter.component';
import { TextBoxFilterComponent } from '../../../shared/components/kendo-textbox-filter.component';
import { PropertiesInfo } from '../../../shared/models/common.models';
import { SaveResult } from '../../../shared/models/result.model';
import { AuthorisationService } from '../../../shared/services/authorisation.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { KendoGridService } from '../../../shared/services/kendo-grid.service';
import { AdministrationService } from '../../administration/administration.service';
import { Station } from '../../calendars/calendars.models';
import { CalendarsService } from '../../calendars/calendars.service';
import { PropertyComponent } from '../../calendars/property/property.component';
import { SearchProperty } from '../search.models';
import { ButtonFilterComponent } from '../../../shared/components/kendo-button-filter.component';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'search-properties',
  templateUrl: './search-properties.component.html',
  styleUrls: ['./search-properties.component.scss'],
  providers: [KendoGridService],
  standalone: true,
  imports: [MatPaginatorModule, FormsModule, GridModule, NgClass, ExcelModule, SVGIconModule, ButtonFilterComponent, NumericTextBoxFilterComponent, TextBoxFilterComponent, CheckBoxFilterComponent, MatButtonModule, MatIconModule, AsyncPipe, DatePipe]
})
export class SearchPropertiesComponent extends KendoGridComponent<SearchProperty> implements OnDestroy, OnInit
{
  public deleteConfirmationMessage = "Are you sure you want to delete this record ?";
  public deleteHeaderText = "Delete Record ?";

  private onSavedSubscription: Subscription;
  private onDataFetchedSubscription: Subscription;

  private queryClient = inject(QueryClient);
  public configurationService = inject(ConfigurationService);

  public dialog: MatDialog;
  public dialogConfig = new MatDialogConfig();
  public dialogIsOpen = false;

  private addCloseSubscription: Subscription;
  protected editCloseSubscription: Subscription;
  protected deleteCloseSubscription: Subscription;

  private _isOnline = false;
  private _stations: Station[] = [];
  private _startDate: Date;
  private _endDate: Date;

  public icons = {
    excel: fileExcelIcon
  };

  @Input() public gridIsChild: boolean = false;

  @Input() get isOnline(): boolean
  {
    return this._isOnline;
  }
  set isOnline(val: boolean)
  {
    this._isOnline = val;
  }

  @Input() get stations(): Station[]
  {
    return this._stations;
  }
  set stations(value: Station[])
  {
    this._stations = value;
  }

  @Input() get startDate(): Date
  {
    return this._startDate;
  }
  set startDate(value: Date)
  {
    this._startDate = value;
  }

  @Input() get endDate(): Date
  {
    return this._endDate;
  }
  set endDate(value: Date)
  {
    this._endDate = value;
  }

  public deleteProperty = injectMutation(() => ({
    mutationFn: (property: any) =>
    {
      return this.calendarsService.deleteProperty(property);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Property" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0);
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Properties";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });
    }
  }))

  @ViewChild('adminForm', { static: true }) currentForm: NgForm

  constructor(public kendoGridService: KendoGridService,
    private calendarsService: CalendarsService,
    public commonService: CommonService,
    public commonDataService: CommonDataService,
    private confirmDialog: MatDialog,
    protected inj: Injector,
    public administrationService: AdministrationService,
    private authorisationService: AuthorisationService)
  {
    super(kendoGridService, "CreateProperty", "DeleteProperty", "api/Calendar/GetSearchPropertiesServer", "UpdateProperty", authorisationService?.currentuser.ChangedByUserId, "GetSearchProperties");

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProperty);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProperty);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteProperty);
    this.CanExport = true;

    this.dialog = inj.get(MatDialog);

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "90%";
    this.dialogConfig.height = "97vh";
  }

  override ngOnInit(): void
  {
    this.form = this.currentForm;

    //Need these for server paging
    this.view = this.kendoGridService;
    this.kendoGridService.serverPaging = true;
    this.gridState.take = 10;

    //let params: Parameter[] = [{ name: "locationGroupId", value: this.selectedLocationGroup.Id }, { name: "exposureTypeId", value: 0 }];
    //this.serviceParameters = params;

    this.onSavedSubscription = this.OnSaved.subscribe((data: any) =>
    {
      if (data.IsSuccessful)
      {
        this.calendarsService.resetHttpCache();
        this.calendarsService.resetCalendarData();
        this.calendarsService.dataResetFromPropertySave = true;

        if (data.Message)
        {
          this.commonService.notifySuccess("Success", data.Message);
        }
      }
      else
      {
        let errMsg = "Unable to save record.";

        if (data.ErrorMessage)
        {
          errMsg = data.ErrorMessage;
        }

        this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
      }
    });

    this.onDataFetchedSubscription = this.OnDataFetched.subscribe((data: SearchProperty[]) =>
    {
      if (data && data.length > 0)
      {
        //must change date values to proper date for grid filtering to work.
        data.map(e =>
        {
          if (e.StartDate)
          {
            e.StartDate = new Date(e.StartDate);
          }

          if (e.EndDate)
          {
            e.EndDate = new Date(e.EndDate);
          }
        });
      }
    });

    //call after subscriptions
    super.ngOnInit();
  }


  propertyHover(event: any, id: number)
  {
    fromEvent(event.target, event.type)
      .pipe(
        debounceTime(this.configurationService.cbSettings().cacheDebounceTime),
        distinctUntilChanged()
      ).subscribe(() =>
      {
        if (event)
        {
          if (event.stopPropagation)
          {
            // stop event bubbling up
            event.stopPropagation();
          }

          //prevents browser from performing the default action for this element
          event.preventDefault();
        }

        this.queryClient.prefetchQuery({
          queryKey: ["Property", id],
          queryFn: () => this.calendarsService.getProperty(id),
          //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
        });
      });
  }

  //creation of property is done in the property component
  addNew(event: any)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProperty))
    {
      if (!this.dialogIsOpen)
      {
        try
        {
          this.dialogIsOpen = true;

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "52rem";
          dialogConfig.height = "98vh";
          dialogConfig.hasBackdrop = true;

          const propertiesInfo: PropertiesInfo = new PropertiesInfo();
          propertiesInfo.id = 0;
          propertiesInfo.isOnline = false;

          dialogConfig.data = propertiesInfo;

          const dialogRef = this.dialog.open(PropertyComponent, dialogConfig);

          this.addCloseSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
          {
            this.dialogIsOpen = false;

            if (result && result.IsSuccessful)
            {
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();

              this.calendarsService.dataResetFromPropertySave = true;
            }
          });
        }
        catch (e)
        {
          this.dialogIsOpen = false;
        }
      }
    }
  }

  //update of property is done in the property component
  editItem(property: SearchProperty)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProperty) || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewProperty))
    {
      if (!this.dialogIsOpen)
      {
        this.dialogIsOpen = true;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "52rem";
        dialogConfig.height = "98vh";
        dialogConfig.hasBackdrop = true;

        let dialogRef: any = null;

        const propInfo: PropertiesInfo = new PropertiesInfo();
        propInfo.id = property.Id;

        dialogConfig.data = propInfo;

        dialogRef = this.dialog.open(PropertyComponent, dialogConfig);

        this.editCloseSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            this.kendoGridService.read(this.gridState);

            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();
            this.calendarsService.dataResetFromPropertySave = true;
          }
        });
      }
    }
  }

  //deletion of property is done here!!
  deleteItem(event: any, searchProperty: SearchProperty)
  {
    this.saveInProgress = false;

    this.deleteConfirmationMessage = `Are you sure you want to delete this Property (${searchProperty.PropertyName})?`;
    this.deleteHeaderText = "Delete Property?";

    let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
    confirmDialogRef.componentInstance.confirmMessage = this.deleteConfirmationMessage;
    confirmDialogRef.componentInstance.confirmTitle = this.deleteHeaderText;

    this.deleteCloseSubscription = confirmDialogRef.afterClosed().subscribe(result =>
    {
      //The intention to delete was confirmed
      if (result)
      {
        this.deleteProperty.mutate(searchProperty,
          {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Deleted", data.Message);
                  }

                  this.kendoGridService.read(this.kendoGridService.gridState);
                }
                else
                {
                  let errMsg = "Unable to delete record.";

                  if (data.ErrorMessage)
                  {
                    errMsg = data.ErrorMessage;
                  }

                  this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                }
              }
            },
            onError: (error, variables, context) =>
            {
              this.saveInProgress = false;
              this.commonService.notifyFailure("Error", "Unable to delete property " + searchProperty.PropertyName + ".", error.message, "");
            }
          });
      }
      else
      {
        this.saveInProgress = false;
      }
    });
  }

  override ngOnDestroy()
  {
    if (this.onSavedSubscription)
    {
      this.onSavedSubscription.unsubscribe();
    }

    if (this.onDataFetchedSubscription)
    {
      this.onDataFetchedSubscription.unsubscribe();
    }

    if (this.addCloseSubscription)
    {
      this.addCloseSubscription.unsubscribe();
    }

    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }

    if (this.deleteCloseSubscription)
    {
      this.deleteCloseSubscription.unsubscribe();
    }

    super.ngOnDestroy();

  }

  page(event: PageEvent)
  {
    this.gridState.take = event.pageSize;
    this.gridState.skip = (event.pageIndex * event.pageSize);
    this.kendoGridService.read(this.gridState);
  }







}
